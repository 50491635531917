/* eslint-disable */
import classNames from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
    intlShape,
} from "react-intl";
import PropTypes from "prop-types";
import bindAll from "lodash.bindall";
import bowser from "bowser";
import React from "react";
import { Button as AntdButton } from "antd";
import VM from "scratch-vm-dingdangcode";

import Box from "../box/box.jsx";
import Button from "../button/button.jsx";
// import CommunityButton from './community-button.jsx';
// import SaveButton from "./save-button.jsx";
// import ShareButton from './share-button.jsx';
import { ComingSoonTooltip } from "../coming-soon/coming-soon.jsx";
// import Divider from "../divider/divider.jsx";
import LanguageSelector from "../../containers/language-selector.jsx";
import WorksSelector from "../../containers/works-upload.jsx";
import WorksSelectorRight from "../../containers/works-upload-right.jsx";
import EditType from "../../containers/edit-type.jsx";
import Login from "../../containers/login.jsx";
import PublishDraw from "../../containers/publishDraw.jsx";
import DraftList from "../../containers/draft-list.jsx";
import PreShareDialog from "../../containers/pre-share-dialog.jsx";
import ShareDialog from "../../containers/share-dialog.jsx";
// import SaveStatus from './save-status.jsx';
import SBFileUploader from "../../containers/sb-file-uploader.jsx";
// import ProjectWatcher from '../../containers/project-watcher.jsx';
import MenuBarMenu from "./menu-bar-menu.jsx";
import { MenuItem, MenuSection } from "../menu/menu.jsx";
// import ProjectTitleInput from "./project-title-input.jsx";

// import AuthorInfo from './author-info.jsx';
// import AccountNav from '../../containers/account-nav.jsx';
// import LoginDropdown from './login-dropdown.jsx';
import SB3Downloader from "../../containers/sb3-downloader.jsx";
import DeletionRestorer from "../../containers/deletion-restorer.jsx";
import TurboMode from "../../containers/turbo-mode.jsx";
import MenuBarHOC from "../../containers/menu-bar-hoc.jsx";

import { openTipsLibrary } from "../../reducers/modals";
import { setPlayer } from "../../reducers/mode";
import {
    changeDraftTitle,
    setDraftId,
    setPreShareShow,
    setShareShow,
} from "../../reducers/login";
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy,
} from "../../reducers/project-state";

import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    watchHideMenu,
} from "../../reducers/menus";

import collectMetadata from "../../lib/collect-metadata";

import styles from "./menu-bar.css";

import queryString from "query-string";

// import helpIcon from "../../lib/assets/icon--tutorials.svg";
// import mystuffIcon from './icon--mystuff.png';
// import profileIcon from './icon--profile.png';
// import remixIcon from './icon--remix.svg';
import dropdownCaret from "./dropdown-caret.svg";
import languageIcon from "../language-selector/language-icon.svg";
import aboutIcon from "./icon--about.svg";

import scratchLogo from "./editor-logoNew.png";

import sharedMessages from "../../lib/shared-messages";

import { savePractise } from "../../api/apiServer/apiUser";
import cookies from "js-cookie";

// const shareId = cookies.get("shareId");
const ariaMessages = defineMessages({
    language: {
        id: "gui.menuBar.LanguageSelector",
        defaultMessage: "language selector",
        description: "accessibility text for the language selection menu",
    },
    tutorials: {
        id: "gui.menuBar.tutorialsLibrary",
        defaultMessage: "Tutorials",
        description: "accessibility text for the tutorials button",
    },
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = "bottom",
}) => {
    if (enable) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};

MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

const MenuItemTooltip = ({ id, isRtl, children, className }) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? "left" : "right"}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool,
};

const AboutButton = (props) => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleClickNew",
            "handleClickRemix",
            "handleClickSave",
            "handleClickSaveAsCopy",
            "handleClickSeeCommunity",
            "handleClickSubmitProject",
            "handleClickAfterRunCode",
            "handleClickShare",
            "handleKeyPress",
            "handleLanguageMouseUp",
            "handleRestoreOption",
            "getSaveToComputerHandler",
            "restoreOptionMessage",
            "saveResult",
            "tipsHandler",
            "moveStepsHandler",
            "boardTurnLeftHandler",
            "boardTurnRightHandler",
            "onQuestionAnswered",
        ]);
        this.state = {
            from: "",
            adapt: "",
        };
    }

    componentDidMount() {
        const query = queryString.parse(location.search);
        console.log(192, query);
        // 监听来源是否为练习或者课程。是否显示隐藏navbar
        this.props.onWatchHideMenu(
            query.from === "practise" || query.from === "course"
        );
        this.setState({
            from: query.from,
            adapt: query.adapt,
        });
        // if (shareId) {
        //     this.props.ShareShowEve();
        //     this.props.PreShareShowEve();
        // }
        // 监听评测后事件
        // this.props.vm.bindTips(this.tipsHandler);
        // 在这里监听挑战成功按钮
        // console.log("xxx: ", this.props.vm);
        // console.log("yyy: ", this.props.vm.boardMoveSteps);
        // console.log("ZZZ: ", this.props.vm.boardTurnLeft);
        // console.log("KKK: ", this.props.vm.boardTurnRight);
        // this.props.vm.boardMoveSteps(this.moveStepsHandler);
        // this.props.vm.boardTurnLeft(this.boardTurnLeftHandler);
        // this.props.vm.boardTurnRight(this.boardTurnRightHandler);
        this.props.vm.broadCastSuc(this.tipsHandler);
        document.addEventListener("keydown", this.handleKeyPress);
        this.props.draftTitleEve("");
        //清除草稿名称和草稿id的cookie
        cookies.remove("DRAFT_TITLE");
        cookies.remove("draftId");
        cookies.remove("shareId");
        cookies.remove("publishCode");
        cookies.remove("shareTitle");
        cookies.remove("shareDesc");
        sessionStorage.removeItem("DRAFT_TITLE");
        // const token = cookies.get("token");
        // console.log("token", token);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
    }

    // 移动积木块事件监听
    moveStepsHandler(_props) {
        console.log(
            "直行数据11",
            props,
            "event_name",
            _props.name,
            "steps:",
            _props.steps,
            "dx:",
            _props.dx,
            "dy:",
            _props.dy
        );
    }

    // 向左积木块事件监听
    boardTurnLeftHandler(_props) {
        console.log(
            "左转",
            "event_name",
            _props.name,
            "degrees:",
            _props.degrees,
            "dturn:",
            _props.dturn
        );
    }

    // 向右积木块事件监听
    boardTurnRightHandler(_props) {
        console.log(
            "右转",
            "event_name",
            _props.name,
            "degrees:",
            _props.degrees,
            "dturn:",
            _props.dturn
        );
    }

    // 监听评测后事件
    // https://creative.dingdangcode.cn?bookId=1&courseId=1&childId=123&unitId=2&id=8927&from=practise&token=d6117269d9ce474c8303d6aac6cff791
    tipsHandler(openOrClose, message) {
        const { from } = queryString.parse(location.search);
        // 模拟点击 SB3Downloader ddm_result评测结果
        window.ddm_result = 1;
        if (from === "practise") {
            // 练习环境 只保存评测结果 并弹窗告知运行成功继续学习
            this.saveResult(1);
            window.parent.postMessage(
                { act: "ddm-ojPrompt", msg: "show" },
                "*"
            );
        } else {
            document.getElementById("clickAfterRunCode").click();
        }
    }

    // 练习评测保存
    saveResult(res) {
        const { childId, courseId, sectionId, bookId, unitId, packageId } =
            queryString.parse(location.search);
        savePractise({
            bookId,
            childId,
            courseId,
            sectionId,
            unitId,
            packageId,
            result: res || null,
        }).then(({ data }) => {
            // 如果回调存在 则执行回调
            if (data.code === 0) console.log("保存成功");
        });
    }

    handleClickNew() {
        //新作品
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.
        const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
            this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        );
        this.props.onRequestCloseFile();
        if (readyToReplaceProject) {
            this.props.onClickNew(
                this.props.canSave && this.props.canCreateNew
            );
        }
        this.props.onRequestCloseFile();
        // this.props.setDraftIdEve(null);
        this.props.draftTitleEve("");
        sessionStorage.removeItem("draftId");
        sessionStorage.removeItem("sourceType");
        cookies.remove("DRAFT_TITLE"); //draftId
        sessionStorage.removeItem("DRAFT_TITLE");
        cookies.remove("draftId");
    }

    handleClickRemix() {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }

    handleClickSave(object) {
        // console.log("save", object);
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }

    handleClickSaveAsCopy() {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity(waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); // save before transitioning to project page
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }

    // 交作业
    handleClickSubmitProject(downloadProjectCallback) {
        // console.log("交作业:", downloadProjectCallback);
        downloadProjectCallback(1); // 1 提交作业
    }
    //作品保存
    onQuestionAnswered() {
        console.log(111);
    }
    // 运行代码后保存作业
    handleClickAfterRunCode(downloadProjectCallback) {
        // console.log("baocunyixia:", 111);
        downloadProjectCallback(2); // 运行代码后 并且成功 默认保存一下
    }

    handleClickShare(waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) {
                // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) {
                // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleRestoreOption(restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress(event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === "s") {
            this.props.onClickSave();
            event.preventDefault();
        }
    }

    getSaveToComputerHandler(downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(
                    this.props.vm,
                    this.props.projectTitle,
                    this.props.locale
                );
                this.props.onProjectTelemetryEvent("projectDidSave", metadata);
            }
        };
    }

    handleLanguageMouseUp(e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    restoreOptionMessage(deletedItem) {
        switch (deletedItem) {
            case "Sprite":
                return (
                    <FormattedMessage
                        defaultMessage="Restore Sprite"
                        description="Menu bar item for restoring the last deleted sprite."
                        id="gui.menuBar.restoreSprite"
                    />
                );
            case "Sound":
                return (
                    <FormattedMessage
                        defaultMessage="Restore Sound"
                        description="Menu bar item for restoring the last deleted sound."
                        id="gui.menuBar.restoreSound"
                    />
                );
            case "Costume":
                return (
                    <FormattedMessage
                        defaultMessage="Restore Costume"
                        description="Menu bar item for restoring the last deleted costume."
                        id="gui.menuBar.restoreCostume"
                    />
                );
            default: {
                return (
                    <FormattedMessage
                        defaultMessage="Restore"
                        description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                        id="gui.menuBar.restore"
                    />
                );
            }
        }
    }
    render() {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        // const remixButton = (
        //     <Button
        //         className={classNames(styles.menuBarButton, styles.remixButton)}
        //         iconClassName={styles.remixButtonIcon}
        //         iconSrc={remixIcon}
        //         onClick={this.handleClickRemix}
        //     >
        //         {remixMessage}
        //     </Button>
        // );
        // Show the About button only if we have a handler for it (like in the desktop app)

        // const aboutButton = this.props.onClickAbout ? (
        //     <AboutButton onClick={this.props.onClickAbout} />
        // ) : null;

        // 作业讲解button
        // const workExplainButton = (
        //     <Button
        //         className={classNames(styles.menuBarButton, styles.remixButton)}
        //         iconClassName={styles.workExplainButtonIcon}
        //         iconSrc={null}
        //         onClick={this.handleClickWorkExplain}
        //     >
        //         {remixMessage}
        //     </Button>
        // );
        // 判断身份 auth === 1 为录屏 auth === 2 为评测  auth 默认配置或自由创作
        const { auth } = queryString.parse(location.search);
        return (
            <>
                <Box
                    className={classNames(this.props.className, styles.menuBar)}
                    style={{ display: !this.props.hideMenu ? "block" : "none" }}
                >
                    <SB3Downloader>
                        {(className, downloadProjectCallback) => (
                            <button
                                id="clickAfterRunCode"
                                onClick={() =>
                                    this.handleClickAfterRunCode(
                                        downloadProjectCallback
                                    )
                                }
                                className={classNames(styles.scratchHide)}
                            ></button>
                        )}
                    </SB3Downloader>
                    <SBFileUploader
                        isInitial={true}
                        onUpdateProjectTitle={
                            PropTypes.func
                        } /** 初始化加载文件到项目 模拟Sb文件上传操作 **/
                    >
                        {(className, renderFileInput, loadProject) => (
                            <button
                                onClick={loadProject}
                                className={classNames(styles.scratchHide)}
                            ></button>
                        )}
                    </SBFileUploader>
                    <div className={styles.mainMenu}>
                        <div className={styles.fileGroup}>
                            <div className={classNames(styles.menuBarItem)}>
                                <img
                                    alt="Scratch"
                                    className={classNames(styles.scratchLogo, {
                                        [styles.clickable]:
                                            typeof this.props.onClickLogo !==
                                            "undefined",
                                    })}
                                    draggable={false}
                                    src={this.props.logo}
                                    onClick={this.props.onClickLogo}
                                />
                            </div>
                            {this.state.from === "eduCourseware" &&
                            this.state.adapt === "adapt" ? (
                                <EditType />
                            ) : this.state.from === "eduCourseware" &&
                              this.state.adapt !== "adapt" ? null : (
                                <EditType />
                            )}
                            {this.props.canChangeLanguage && (
                                <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable,
                                        styles.languageMenu
                                    )}
                                >
                                    <div>
                                        <img
                                            className={styles.languageIcon}
                                            src={languageIcon}
                                        />
                                        <img
                                            className={styles.languageCaret}
                                            src={dropdownCaret}
                                        />
                                    </div>
                                    <LanguageSelector
                                        label={this.props.intl.formatMessage(
                                            ariaMessages.language
                                        )}
                                    />
                                </div>
                            )}
                            {this.props.canManageFiles && (
                                <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable,
                                        {
                                            [styles.active]:
                                                this.props.fileMenuOpen,
                                        }
                                    )}
                                    onMouseUp={this.props.onClickFile}
                                >
                                    <FormattedMessage
                                        defaultMessage="File"
                                        description="Text for file dropdown menu"
                                        id="gui.menuBar.file"
                                    />
                                    <MenuBarMenu
                                        className={classNames(
                                            styles.menuBarMenu
                                        )}
                                        open={this.props.fileMenuOpen}
                                        place={
                                            this.props.isRtl ? "left" : "right"
                                        }
                                        onRequestClose={
                                            this.props.onRequestCloseFile
                                        }
                                    >
                                        {this.state.from === "eduCourseware" &&
                                        this.state.adapt === "adapt" ? (
                                            <MenuSection>
                                                {/* 新作品 */}
                                                <MenuItem
                                                    isRtl={this.props.isRtl}
                                                    onClick={
                                                        this.handleClickNew
                                                    }
                                                >
                                                    {newProjectMessage}
                                                </MenuItem>
                                            </MenuSection>
                                        ) : this.state.from ===
                                              "eduCourseware" &&
                                          this.state.adapt !==
                                              "adapt" ? null : (
                                            <MenuSection>
                                                {/* 新作品 */}
                                                <MenuItem
                                                    isRtl={this.props.isRtl}
                                                    onClick={
                                                        this.handleClickNew
                                                    }
                                                >
                                                    {newProjectMessage}
                                                </MenuItem>
                                            </MenuSection>
                                        )}
                                        {(this.props.canSave ||
                                            this.props.canCreateCopy ||
                                            this.props.canRemix) && (
                                            <MenuSection>
                                                {this.props.canSave && (
                                                    <MenuItem
                                                        onClick={this.handleClickSave(
                                                            this.props.canSave
                                                        )}
                                                    >
                                                        {saveNowMessage}
                                                    </MenuItem>
                                                )}
                                                {this.props.canCreateCopy && (
                                                    <MenuItem
                                                        onClick={
                                                            this
                                                                .handleClickSaveAsCopy
                                                        }
                                                    >
                                                        {createCopyMessage}
                                                    </MenuItem>
                                                )}
                                                {this.props.canRemix && (
                                                    <MenuItem
                                                        onClick={
                                                            this
                                                                .handleClickRemix
                                                        }
                                                    >
                                                        {remixMessage}
                                                    </MenuItem>
                                                )}
                                            </MenuSection>
                                        )}
                                        <MenuSection>
                                            {/* 从电脑中上传 */}
                                            {this.state.from ===
                                                "eduCourseware" &&
                                            this.state.adapt === "adapt" ? (
                                                <SBFileUploader
                                                    canSave={this.props.canSave}
                                                    userOwnsProject={
                                                        this.props
                                                            .userOwnsProject
                                                    }
                                                >
                                                    {(
                                                        className,
                                                        renderFileInput,
                                                        handleLoadProject
                                                    ) => (
                                                        <MenuItem
                                                            className={
                                                                className
                                                            }
                                                            onClick={
                                                                handleLoadProject
                                                            }
                                                        >
                                                            {this.props.intl.formatMessage(
                                                                sharedMessages.loadFromComputerTitle
                                                            )}
                                                            {renderFileInput()}
                                                        </MenuItem>
                                                    )}
                                                </SBFileUploader>
                                            ) : this.state.from ===
                                                  "eduCourseware" &&
                                              this.state.adapt !==
                                                  "adapt" ? null : (
                                                <SBFileUploader
                                                    canSave={this.props.canSave}
                                                    userOwnsProject={
                                                        this.props
                                                            .userOwnsProject
                                                    }
                                                >
                                                    {(
                                                        className,
                                                        renderFileInput,
                                                        handleLoadProject
                                                    ) => (
                                                        <MenuItem
                                                            className={
                                                                className
                                                            }
                                                            onClick={
                                                                handleLoadProject
                                                            }
                                                        >
                                                            {this.props.intl.formatMessage(
                                                                sharedMessages.loadFromComputerTitle
                                                            )}
                                                            {renderFileInput()}
                                                        </MenuItem>
                                                    )}
                                                </SBFileUploader>
                                            )}

                                            {/* 保存到电脑 */}
                                            <SB3Downloader>
                                                {(
                                                    className,
                                                    downloadProjectCallback
                                                ) => (
                                                    <MenuItem
                                                        className={className}
                                                        onClick={this.getSaveToComputerHandler(
                                                            downloadProjectCallback
                                                        )}
                                                    >
                                                        <FormattedMessage
                                                            defaultMessage="Save to your computer"
                                                            description="Menu bar item for downloading a project to your computer" // eslint-disable-line max-len
                                                            id="gui.menuBar.downloadToComputer"
                                                        />
                                                    </MenuItem>
                                                )}
                                            </SB3Downloader>
                                        </MenuSection>
                                    </MenuBarMenu>
                                </div>
                            )}
                            <div
                                className={classNames(
                                    styles.menuBarItem,
                                    styles.hoverable,
                                    {
                                        [styles.active]:
                                            this.props.editMenuOpen,
                                    }
                                )}
                                onMouseUp={this.props.onClickEdit}
                            >
                                <div className={classNames(styles.editMenu)}>
                                    <FormattedMessage
                                        defaultMessage="Edit"
                                        description="Text for edit dropdown menu"
                                        id="gui.menuBar.edit"
                                    />
                                </div>
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.editMenuOpen}
                                    place={this.props.isRtl ? "left" : "right"}
                                    onRequestClose={
                                        this.props.onRequestCloseEdit
                                    }
                                >
                                    <DeletionRestorer>
                                        {(
                                            handleRestore,
                                            { restorable, deletedItem }
                                        ) => (
                                            <MenuItem
                                                className={classNames({
                                                    [styles.disabled]:
                                                        !restorable,
                                                })}
                                                onClick={this.handleRestoreOption(
                                                    handleRestore
                                                )}
                                            >
                                                {this.restoreOptionMessage(
                                                    deletedItem
                                                )}
                                            </MenuItem>
                                        )}
                                    </DeletionRestorer>
                                    <MenuSection>
                                        <TurboMode>
                                            {(
                                                toggleTurboMode,
                                                { turboMode }
                                            ) => (
                                                <MenuItem
                                                    onClick={toggleTurboMode}
                                                >
                                                    {turboMode ? (
                                                        <FormattedMessage
                                                            defaultMessage="Turn off Turbo Mode"
                                                            description="Menu bar item for turning off turbo mode"
                                                            id="gui.menuBar.turboModeOff"
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            defaultMessage="Turn on Turbo Mode"
                                                            description="Menu bar item for turning on turbo mode"
                                                            id="gui.menuBar.turboModeOn"
                                                        />
                                                    )}
                                                </MenuItem>
                                            )}
                                        </TurboMode>
                                    </MenuSection>
                                </MenuBarMenu>
                            </div>
                            <div
                                className={classNames(styles.menuBarItem, {
                                    [styles.active]: this.props.editMenuOpen,
                                })}
                            >
                                <WorksSelector
                                    question={this.props.question}
                                    onQuestionAnswered={this.onQuestionAnswered}
                                    ib64={this.props.ib64}
                                ></WorksSelector>
                            </div>
                        </div>

                        <div className={styles.menuBarRightGroup}>
                            {auth && (
                                <SB3Downloader>
                                    {(className, downloadProjectCallback) => (
                                        <div className={styles.rightGroupBtn}>
                                            <AntdButton
                                                className={styles.promptBtn}
                                                onClick={() => {
                                                    this.handleClickSubmitProject(
                                                        downloadProjectCallback
                                                    );
                                                }}
                                            >
                                                交作业
                                            </AntdButton>
                                        </div>
                                    )}
                                </SB3Downloader>
                            )}
                            <WorksSelectorRight></WorksSelectorRight>
                        </div>
                    </div>
                </Box>
                <div>
                    <Login />
                </div>
                <div>
                    <PublishDraw />
                </div>
                <div>
                    <DraftList />
                </div>
                <div>
                    <PreShareDialog />
                </div>
                <div>
                    <ShareDialog />
                </div>
            </>
        );
    }
}

MenuBar.propTypes = {
    // accountMenuOpen: PropTypes.bool,
    // authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    // authorThumbnailUrl: PropTypes.string,
    // authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    editMenuOpen: PropTypes.bool,
    // enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    // isShowingProject: PropTypes.bool,
    // isUpdating: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    // loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    onClickAbout: PropTypes.func,
    // onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    // onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    // onLogOut: PropTypes.func,
    // onOpenRegistration: PropTypes.func,
    onHideMenu: PropTypes.func,
    onShowMenu: PropTypes.func,
    onWatchHideMenu: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    // onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    // onRequestCloseLanguage: PropTypes.func,
    // onRequestCloseLogin: PropTypes.func,
    // onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    // onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    // renderLogin: PropTypes.func,
    // sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    // showComingSoon: PropTypes.bool,
    userOwnsProject: PropTypes.bool,
    // username: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    // onQuestionAnswered: PropTypes.func,
    question: PropTypes.string,
    ShareShowEve: PropTypes.func,
    PreShareShowEve: PropTypes.func,
    setDraftIdEve: PropTypes.func,
    ib64: PropTypes.string,
    draftTitleEve: PropTypes.func,
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => {},
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user =
        state.session && state.session.session && state.session.session.user;
    return {
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        sessionExists:
            state.session && typeof state.session.session !== "undefined",
        username: user ? user.username : null,
        userOwnsProject:
            ownProps.authorUsername &&
            user &&
            ownProps.authorUsername === user.username,
        vm: state.scratchGui.vm,
        hideMenu: state.scratchGui.menus.isHideMenu,
    };
};

const mapDispatchToProps = (dispatch) => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickNew: (needSave) => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onShowMenu: () => dispatch(showMenuBar()),
    onHideMenu: () => dispatch(hideMenuBar()),
    onWatchHideMenu: (status) => dispatch(watchHideMenu(status)),
    ShareShowEve: () => dispatch(setShareShow(true)),
    PreShareShowEve: () => dispatch(setPreShareShow(false)),
    setDraftIdEve: (res) => dispatch(setDraftId(res)),
    draftTitleEve: (res) => dispatch(changeDraftTitle(res)),
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(mapStateToProps, mapDispatchToProps)
)(MenuBar);
