var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".custom-modal_custom-modal_2RgMS {\n    padding: 0;\n}\n.custom-modal_custom-modal_2RgMS .ant-modal-content {\n    background-color: transparent;\n    -webkit-box-shadow: none;\n            box-shadow: none;\n    height: 100%;\n    padding: 0;\n}\n.custom-modal_custom-modal_2RgMS .ant-modal-body {\n    padding: 0;\n    font-size: 16px;\n    height: 100%;\n}\n.custom-modal_custom-modal_2RgMS .custom-modal_custom-body_3zxFy {\n    padding: 50px;\n    font-size: 16px;\n    height: 100%;\n    background-image: url(" + escape(require("./image/small-modal.svg")) + ");\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: cover;\n    position: relative;\n}\n.custom-modal_custom-modal_2RgMS .ant-modal-close {\n    right: 20px;\n    top: 20px;\n}\n.custom-modal_custom-modal_2RgMS .ant-modal-close-x {\n    width: 56px;\n    height: 56px;\n    line-height: 1;\n}\n.custom-modal_custom-modal_2RgMS .custom-modal_custom-close-icon_I_jIC {\n    margin-top: 12px;\n    display: inline-block;\n    width: 32px;\n    height: 32px;\n    background: url(" + escape(require("./image/ic-pop-close@2x.png")) + ") no-repeat center center / cover;\n}\n", ""]);

// exports
exports.locals = {
	"custom-modal": "custom-modal_custom-modal_2RgMS",
	"customModal": "custom-modal_custom-modal_2RgMS",
	"custom-body": "custom-modal_custom-body_3zxFy",
	"customBody": "custom-modal_custom-body_3zxFy",
	"custom-close-icon": "custom-modal_custom-close-icon_I_jIC",
	"customCloseIcon": "custom-modal_custom-close-icon_I_jIC"
};