export default [
    {
        key: "新手任务",
        value: {
            eventsblock: "YDxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz5g",
        },
    },
    {
        key: "第一课",
        value: {
            motionblock:
                "YCR7CiAgICBpc1N0YWdlCiAgICAgID8gYAogICAgPGxhYmVsIHRleHQ9IiR7c3RhZ2VTZWxlY3RlZH0iPjwvbGFiZWw+CiAgICBgCiAgICAgIDogLy8g56e75YqoMTDmraUKICAgICAgICBgCiAgICA8YmxvY2sgdHlwZT0ibW90aW9uX21vdmVzdGVwcyI+CiAgICAgICAgPHZhbHVlIG5hbWU9IlNURVBTIj4KICAgICAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj44NTwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgPGJsb2NrIHR5cGU9Im1vdGlvbl9kZF9icm9hZGNhc3QiIGlkPSJtb3Rpb25fZGRfYnJvYWRjYXN0Ij48L2Jsb2NrPgogIGAKICB9YAog",
            controlblock:
                "YDxibG9jayB0eXBlPSJjb250cm9sX3dhaXQiPgogICAgPHZhbHVlIG5hbWU9IkRVUkFUSU9OIj4KICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX3Bvc2l0aXZlX251bWJlciI+CiAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTwvZmllbGQ+CiAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KICA8L2Jsb2NrPmA=",
            eventsblock: "YDxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz5g",
        },
    },
    {
        key: "第二课",
        value: {
            motionblock:
                "YCR7CiAgICBpc1N0YWdlCiAgICAgID8gYAogICAgPGxhYmVsIHRleHQ9IiR7c3RhZ2VTZWxlY3RlZH0iPjwvbGFiZWw+CiAgICBgCiAgICAgIDogLy8g56e75YqoMTDmraUKICAgICAgICBgCiAgICA8YmxvY2sgdHlwZT0ibW90aW9uX21vdmVzdGVwcyI+CiAgICAgICAgPHZhbHVlIG5hbWU9IlNURVBTIj4KICAgICAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgLy8g6Z2i5ZCROTDmlrnlkJEKICAgIDxibG9jayB0eXBlPSJtb3Rpb25fcG9pbnRpbmRpcmVjdGlvbiI+CiAgICAgICAgPHZhbHVlIG5hbWU9IkRJUkVDVElPTiI+CiAgICAgICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9hbmdsZSI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj45MDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgPGJsb2NrIHR5cGU9Im1vdGlvbl9kZF9icm9hZGNhc3QiIGlkPSJtb3Rpb25fZGRfYnJvYWRjYXN0Ij48L2Jsb2NrPgogIGAKICB9YAog",
            controlblock:
                "YDxibG9jayB0eXBlPSJjb250cm9sX3dhaXQiPgogICAgPHZhbHVlIG5hbWU9IkRVUkFUSU9OIj4KICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX3Bvc2l0aXZlX251bWJlciI+CiAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTwvZmllbGQ+CiAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KICA8L2Jsb2NrPmA=",
            eventsblock: "YDxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz5g",
        },
    },
    {
        key: "第三课",
        value: {
            motionblock:
                "YCR7CiAgICBpc1N0YWdlCiAgICAgID8gYAogICAgPGxhYmVsIHRleHQ9IiR7c3RhZ2VTZWxlY3RlZH0iPjwvbGFiZWw+CiAgICBgCiAgICAgIDogLy8g56e75YqoMTDmraUKICAgICAgICBgCiAgICA8YmxvY2sgdHlwZT0ibW90aW9uX21vdmVzdGVwcyI+CiAgICAgICAgPHZhbHVlIG5hbWU9IlNURVBTIj4KICAgICAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgLy8g6Z2i5ZCROTDmlrnlkJEKICAgIDxibG9jayB0eXBlPSJtb3Rpb25fcG9pbnRpbmRpcmVjdGlvbiI+CiAgICAgICAgPHZhbHVlIG5hbWU9IkRJUkVDVElPTiI+CiAgICAgICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9hbmdsZSI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj45MDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgPGJsb2NrIHR5cGU9Im1vdGlvbl9kZF9icm9hZGNhc3QiIGlkPSJtb3Rpb25fZGRfYnJvYWRjYXN0Ij48L2Jsb2NrPgogIGAKICB9YAog",
            looksblock:
                "YDxibG9jayB0eXBlPSJsb29rc19zZXRzaXpldG8iPgogICAgPHZhbHVlIG5hbWU9IlNJWkUiPgogICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9udW1iZXIiPgogICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDA8L2ZpZWxkPgogICAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KPC9ibG9jaz5gCiA=",
            controlblock:
                "YDxibG9jayB0eXBlPSJjb250cm9sX3dhaXQiPgogICAgPHZhbHVlIG5hbWU9IkRVUkFUSU9OIj4KICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX3Bvc2l0aXZlX251bWJlciI+CiAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTwvZmllbGQ+CiAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KICA8L2Jsb2NrPmA=",
            eventsblock: "YDxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz5g",
        },
    },
    {
        key: "第四课",
        value: {
            motionblock:
                "YCR7CiAgICBpc1N0YWdlCiAgICAgID8gYAogICAgPGxhYmVsIHRleHQ9IiR7c3RhZ2VTZWxlY3RlZH0iPjwvbGFiZWw+CiAgICBgCiAgICAgIDogLy8g56e75YqoMTDmraUKICAgICAgICBgCiAgICA8YmxvY2sgdHlwZT0ibW90aW9uX21vdmVzdGVwcyI+CiAgICAgICAgPHZhbHVlIG5hbWU9IlNURVBTIj4KICAgICAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgLy8g6Z2i5ZCROTDmlrnlkJEKICAgIDxibG9jayB0eXBlPSJtb3Rpb25fcG9pbnRpbmRpcmVjdGlvbiI+CiAgICAgICAgPHZhbHVlIG5hbWU9IkRJUkVDVElPTiI+CiAgICAgICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9hbmdsZSI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj45MDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgPGJsb2NrIHR5cGU9Im1vdGlvbl9kZF9icm9hZGNhc3QiIGlkPSJtb3Rpb25fZGRfYnJvYWRjYXN0Ij48L2Jsb2NrPgogIGAKICB9YAog",
            looksblock:
                "YCR7aXNTdGFnZSA/IGBgIDogYAovL+aNouaIkOmAoOWeizLpgKDlnosKPGJsb2NrIGlkPSIke3RhcmdldElkfV9zd2l0Y2hjb3N0dW1ldG8iIHR5cGU9Imxvb2tzX3N3aXRjaGNvc3R1bWV0byI+CiAgICA8dmFsdWUgbmFtZT0iQ09TVFVNRSI+CiAgICAgICAgPHNoYWRvdyB0eXBlPSJsb29rc19jb3N0dW1lIj4KICAgICAgICAgICAgPGZpZWxkIG5hbWU9IkNPU1RVTUUiPiR7Y29zdHVtZU5hbWV9PC9maWVsZD4KICAgICAgICA8L3NoYWRvdz4KICAgIDwvdmFsdWU+CjwvYmxvY2s+YH0KPGJsb2NrIHR5cGU9Imxvb2tzX3NldHNpemV0byI+CiAgICA8dmFsdWUgbmFtZT0iU0laRSI+CiAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgIDxmaWVsZCBuYW1lPSJOVU0iPjEwMDwvZmllbGQ+CiAgICAgICAgPC9zaGFkb3c+CiAgICA8L3ZhbHVlPgo8L2Jsb2NrPmAKIA==",
            controlblock:
                "YDxibG9jayB0eXBlPSJjb250cm9sX3dhaXQiPgogICAgPHZhbHVlIG5hbWU9IkRVUkFUSU9OIj4KICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX3Bvc2l0aXZlX251bWJlciI+CiAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTwvZmllbGQ+CiAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KICA8L2Jsb2NrPmA=",
            eventsblock: "YDxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz5g",
        },
    },
    {
        key: "第五课",
        value: {
            motionblock:
                "YCR7CiAgICBpc1N0YWdlCiAgICAgID8gYAogICAgPGxhYmVsIHRleHQ9IiR7c3RhZ2VTZWxlY3RlZH0iPjwvbGFiZWw+CiAgICBgCiAgICAgIDogLy8g56e75YqoMTDmraUKICAgICAgICBgCiAgICA8YmxvY2sgdHlwZT0ibW90aW9uX21vdmVzdGVwcyI+CiAgICAgICAgPHZhbHVlIG5hbWU9IlNURVBTIj4KICAgICAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgLy8g6Z2i5ZCROTDmlrnlkJEKICAgIDxibG9jayB0eXBlPSJtb3Rpb25fcG9pbnRpbmRpcmVjdGlvbiI+CiAgICAgICAgPHZhbHVlIG5hbWU9IkRJUkVDVElPTiI+CiAgICAgICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9hbmdsZSI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj45MDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgPGJsb2NrIHR5cGU9Im1vdGlvbl9kZF9icm9hZGNhc3QiIGlkPSJtb3Rpb25fZGRfYnJvYWRjYXN0Ij48L2Jsb2NrPgogIGAKICB9YAog",
            looksblock:
                "YDxibG9jayBpZD0iJHt0YXJnZXRJZH1fc3dpdGNoY29zdHVtZXRvIiB0eXBlPSJsb29rc19zd2l0Y2hjb3N0dW1ldG8iPgogICAgPHZhbHVlIG5hbWU9IkNPU1RVTUUiPgogICAgICAgIDxzaGFkb3cgdHlwZT0ibG9va3NfY29zdHVtZSI+CiAgICAgICAgICAgIDxmaWVsZCBuYW1lPSJDT1NUVU1FIj4ke2Nvc3R1bWVOYW1lfTwvZmllbGQ+CiAgICAgICAgPC9zaGFkb3c+CiAgICA8L3ZhbHVlPgo8L2Jsb2NrPgo8YmxvY2sgdHlwZT0ibG9va3Nfc2V0c2l6ZXRvIj4KICAgIDx2YWx1ZSBuYW1lPSJTSVpFIj4KICAgICAgICA8c2hhZG93IHR5cGU9Im1hdGhfbnVtYmVyIj4KICAgICAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTAwPC9maWVsZD4KICAgICAgICA8L3NoYWRvdz4KICAgIDwvdmFsdWU+CjwvYmxvY2s+CiR7aXNTdGFnZSA/ICcnIDogYAovL+aYvuekugo8YmxvY2sgdHlwZT0ibG9va3Nfc2hvdyIvPgovL+makOiXjwo8YmxvY2sgdHlwZT0ibG9va3NfaGlkZSIvPmB9YAog",
            controlblock:
                "YDxibG9jayB0eXBlPSJjb250cm9sX3dhaXQiPgogICAgPHZhbHVlIG5hbWU9IkRVUkFUSU9OIj4KICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX3Bvc2l0aXZlX251bWJlciI+CiAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTwvZmllbGQ+CiAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KICA8L2Jsb2NrPmA=",
            eventsblock: "YDxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz5g",
        },
    },
    {
        key: "第六课",
        value: {
            motionblock:
                "YCR7CiAgICBpc1N0YWdlCiAgICAgID8gYAogICAgPGxhYmVsIHRleHQ9IiR7c3RhZ2VTZWxlY3RlZH0iPjwvbGFiZWw+CiAgICBgCiAgICAgIDogLy8g56e75YqoMTDmraUKICAgICAgICBgCiAgICA8YmxvY2sgdHlwZT0ibW90aW9uX21vdmVzdGVwcyI+CiAgICAgICAgPHZhbHVlIG5hbWU9IlNURVBTIj4KICAgICAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgLy8g6Z2i5ZCROTDmlrnlkJEKICAgIDxibG9jayB0eXBlPSJtb3Rpb25fcG9pbnRpbmRpcmVjdGlvbiI+CiAgICAgICAgPHZhbHVlIG5hbWU9IkRJUkVDVElPTiI+CiAgICAgICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9hbmdsZSI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj45MDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgPGJsb2NrIHR5cGU9Im1vdGlvbl9kZF9icm9hZGNhc3QiIGlkPSJtb3Rpb25fZGRfYnJvYWRjYXN0Ij48L2Jsb2NrPgogIGAKICB9YAog",
            looksblock:
                "YDxibG9jayBpZD0iJHt0YXJnZXRJZH1fc3dpdGNoY29zdHVtZXRvIiB0eXBlPSJsb29rc19zd2l0Y2hjb3N0dW1ldG8iPgogICAgPHZhbHVlIG5hbWU9IkNPU1RVTUUiPgogICAgICAgIDxzaGFkb3cgdHlwZT0ibG9va3NfY29zdHVtZSI+CiAgICAgICAgICAgIDxmaWVsZCBuYW1lPSJDT1NUVU1FIj4ke2Nvc3R1bWVOYW1lfTwvZmllbGQ+CiAgICAgICAgPC9zaGFkb3c+CiAgICA8L3ZhbHVlPgo8L2Jsb2NrPgo8YmxvY2sgdHlwZT0ibG9va3Nfc2V0c2l6ZXRvIj4KICAgIDx2YWx1ZSBuYW1lPSJTSVpFIj4KICAgICAgICA8c2hhZG93IHR5cGU9Im1hdGhfbnVtYmVyIj4KICAgICAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTAwPC9maWVsZD4KICAgICAgICA8L3NoYWRvdz4KICAgIDwvdmFsdWU+CjwvYmxvY2s+CiR7aXNTdGFnZSA/ICcnIDogYAovL+aYvuekugo8YmxvY2sgdHlwZT0ibG9va3Nfc2hvdyIvPgovL+makOiXjwo8YmxvY2sgdHlwZT0ibG9va3NfaGlkZSIvPmB9YAog",
            soundblock:
                "YDxibG9jayBpZD0iJHt0YXJnZXRJZH1fc291bmRfcGxheSIgdHlwZT0ic291bmRfcGxheSI+CiAgICA8dmFsdWUgbmFtZT0iU09VTkRfTUVOVSI+CiAgICAgICAgPHNoYWRvdyB0eXBlPSJzb3VuZF9zb3VuZHNfbWVudSI+CiAgICAgICAgICAgIDxmaWVsZCBuYW1lPSJTT1VORF9NRU5VIj4ke3NvdW5kTmFtZX08L2ZpZWxkPgogICAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KPC9ibG9jaz5gCiA=",
            controlblock:
                "YDxibG9jayB0eXBlPSJjb250cm9sX3dhaXQiPgogICAgPHZhbHVlIG5hbWU9IkRVUkFUSU9OIj4KICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX3Bvc2l0aXZlX251bWJlciI+CiAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTwvZmllbGQ+CiAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KICA8L2Jsb2NrPmA=",
            eventsblock: "YDxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz5g",
        },
    },
    {
        key: "第六课",
        value: {
            motionblock:
                "YCR7CiAgICBpc1N0YWdlCiAgICAgID8gYAogICAgPGxhYmVsIHRleHQ9IiR7c3RhZ2VTZWxlY3RlZH0iPjwvbGFiZWw+CiAgICBgCiAgICAgIDogLy8g56e75YqoMTDmraUKICAgICAgICBgCiAgICA8YmxvY2sgdHlwZT0ibW90aW9uX21vdmVzdGVwcyI+CiAgICAgICAgPHZhbHVlIG5hbWU9IlNURVBTIj4KICAgICAgICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX251bWJlciI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgLy8g6Z2i5ZCROTDmlrnlkJEKICAgIDxibG9jayB0eXBlPSJtb3Rpb25fcG9pbnRpbmRpcmVjdGlvbiI+CiAgICAgICAgPHZhbHVlIG5hbWU9IkRJUkVDVElPTiI+CiAgICAgICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9hbmdsZSI+CiAgICAgICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj45MDwvZmllbGQ+CiAgICAgICAgICAgIDwvc2hhZG93PgogICAgICAgIDwvdmFsdWU+CiAgICA8L2Jsb2NrPgogICAgPGJsb2NrIHR5cGU9Im1vdGlvbl9kZF9icm9hZGNhc3QiIGlkPSJtb3Rpb25fZGRfYnJvYWRjYXN0Ij48L2Jsb2NrPgogIGAKICB9YAog",
            looksblock:
                "YDxibG9jayBpZD0iJHt0YXJnZXRJZH1fc3dpdGNoY29zdHVtZXRvIiB0eXBlPSJsb29rc19zd2l0Y2hjb3N0dW1ldG8iPgogICAgPHZhbHVlIG5hbWU9IkNPU1RVTUUiPgogICAgICAgIDxzaGFkb3cgdHlwZT0ibG9va3NfY29zdHVtZSI+CiAgICAgICAgICAgIDxmaWVsZCBuYW1lPSJDT1NUVU1FIj4ke2Nvc3R1bWVOYW1lfTwvZmllbGQ+CiAgICAgICAgPC9zaGFkb3c+CiAgICA8L3ZhbHVlPgo8L2Jsb2NrPgo8YmxvY2sgdHlwZT0ibG9va3Nfc2V0c2l6ZXRvIj4KICAgIDx2YWx1ZSBuYW1lPSJTSVpFIj4KICAgICAgICA8c2hhZG93IHR5cGU9Im1hdGhfbnVtYmVyIj4KICAgICAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTAwPC9maWVsZD4KICAgICAgICA8L3NoYWRvdz4KICAgIDwvdmFsdWU+CjwvYmxvY2s+CiR7aXNTdGFnZSA/ICcnIDogYAovL+aYvuekugo8YmxvY2sgdHlwZT0ibG9va3Nfc2hvdyIvPgovL+makOiXjwo8YmxvY2sgdHlwZT0ibG9va3NfaGlkZSIvPmB9YAog",
            soundblock:
                "YDxibG9jayBpZD0iJHt0YXJnZXRJZH1fc291bmRfcGxheSIgdHlwZT0ic291bmRfcGxheSI+CiAgICA8dmFsdWUgbmFtZT0iU09VTkRfTUVOVSI+CiAgICAgICAgPHNoYWRvdyB0eXBlPSJzb3VuZF9zb3VuZHNfbWVudSI+CiAgICAgICAgICAgIDxmaWVsZCBuYW1lPSJTT1VORF9NRU5VIj4ke3NvdW5kTmFtZX08L2ZpZWxkPgogICAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KPC9ibG9jaz5gCiA=",
            controlblock:
                "YDxibG9jayB0eXBlPSJjb250cm9sX3dhaXQiPgogICAgPHZhbHVlIG5hbWU9IkRVUkFUSU9OIj4KICAgICAgPHNoYWRvdyB0eXBlPSJtYXRoX3Bvc2l0aXZlX251bWJlciI+CiAgICAgICAgPGZpZWxkIG5hbWU9Ik5VTSI+MTwvZmllbGQ+CiAgICAgIDwvc2hhZG93PgogICAgPC92YWx1ZT4KICA8L2Jsb2NrPmA=",
            eventsblock:
                "YC8v5b2T6L+Q6KGM6KKr54K55Ye7CjxibG9jayB0eXBlPSJldmVudF93aGVuZmxhZ2NsaWNrZWQiLz4KLy/lvZPlk43luqblpKc+MTAKPGJsb2NrIHR5cGU9ImV2ZW50X3doZW5ncmVhdGVydGhhbiI+CiAgICA8dmFsdWUgbmFtZT0iVkFMVUUiPgogICAgICAgIDxzaGFkb3cgdHlwZT0ibWF0aF9udW1iZXIiPgogICAgICAgICAgICA8ZmllbGQgbmFtZT0iTlVNIj4xMDwvZmllbGQ+CiAgICAgICAgPC9zaGFkb3c+CiAgICA8L3ZhbHVlPgo8L2Jsb2NrPmAKIA==",
        },
    },
];
