/* eslint-disable */
import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import ReactModal from "react-modal";
import VM from "scratch-vm-dingdangcode";
// import jsbase64 from 'js-base64';
import { injectIntl, intlShape } from "react-intl";

import ErrorBoundaryHOC from "../lib/error-boundary-hoc.jsx";
import { getIsError, getIsShowingProject } from "../reducers/project-state";
import {
    activateTab,
    resetProject,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX,
} from "../reducers/editor-tab";

import {
    closeCostumeLibrary,
    closeBackdropLibrary,
    closeTelemetryModal,
    openExtensionLibrary,
} from "../reducers/modals";
import {
    setIsWorkLoadingEnd,
    setSpriteJson,
    setCostumesJson,
    setBackdropsJson,
    setSoundsJson,
} from "../reducers/login";
import cookies from "js-cookie";

import { queryEditorConfig } from "../api/apiServer/apiCommon";

// import { actionUpdateBlocks, updateToolbox } from "../reducers/toolbox";

import FontLoaderHOC from "../lib/font-loader-hoc.jsx";
import LocalizationHOC from "../lib/localization-hoc.jsx";
import ProjectFetcherHOC from "../lib/project-fetcher-hoc.jsx";
import TitledHOC from "../lib/titled-hoc.jsx";
import ProjectSaverHOC from "../lib/project-saver-hoc.jsx";
import QueryParserHOC from "../lib/query-parser-hoc.jsx";
import storage from "../lib/storage";
import vmListenerHOC from "../lib/vm-listener-hoc.jsx";
import vmManagerHOC from "../lib/vm-manager-hoc.jsx";
import cloudManagerHOC from "../lib/cloud-manager-hoc.jsx";

import GUIComponent from "../components/gui/gui.jsx";
import { setIsScratchDesktop } from "../lib/isScratchDesktop.js";
// import { save, submit } from "../api/apiServer/apiUser";
// import queryString from "query-string";
// import saveProjectToServer from "../lib/save-project-to-server.js";
// import ScratchBlocks from "scratch-blocks-dingdangcode";
// import makeToolboxXML from "../lib/make-toolbox-xml.js";
import CustomModal from "../components/custom-modal/index.jsx";
import CustomBtn from "../components/custom-button/index.jsx";
import bindAll from "lodash.bindall";
import { Spin } from "antd";

class GUI extends React.Component {
    constructor() {
        super();
        this.state = {
            saveModalVisible: false,
            isEnd: false,
        };
        bindAll(this, ["handleCancel", "handleReset", "loadSb3File"]);
    }
    componentDidMount() {
        console.log("加载一次222");
        console.log("卡到第一步5");
        setIsScratchDesktop(this.props.isScratchDesktop);
        this.props.onStorageInit(storage);
        this.props.onVmInit(this.props.vm);
        // cookies.set("scratchLoadEnd", "0");
        window.parent.postMessage({ act: "scratch-load", msg: "0" }, "*"); //'0'为未完成
        //监听message事件
        window.addEventListener(
            "message",
            this.receiveMessageFromIndex.bind(this),
            false
        );
        queryEditorConfig().then(({ data }) => {
            if (data.code === 0) {
                console.log("卡到第一步6");
                this.setState({
                    isEnd: true,
                });
                this.props.evesetSpriteJson(data.data.spriteJsonFileUrl);
                this.props.evesetCostumesJson(data.data.costumesJsonFileUrl);
                this.props.evesetBackdropsJson(data.data.backdropsJsonFileUrl);
                this.props.evesetSoundsJson(data.data.soundsJsonFileUrl);
            } else {
                message.error(data.msg);
            }
        });
        // 给父窗口发消息
        // window.parent.postMessage({ act: "ddm-onready", msg: "show" }, "*");
    }

    receiveMessageFromIndex(event) {
        console.log("卡到第一步7", event.data.act);
        let act = event.data.act;
        // console.log("监听消息message", event);
        if (act) {
            switch (act) {
                case "ddm-onload":
                    // console.log("来自父窗口消息-onload：", act);
                    console.log("卡到第一步8");
                    break;
                case "ddm-resetProject":
                    // console.log("来自父窗口消息-resetProject:", act);
                    console.log("卡到第一步9");
                    this._resetProject();
                    break;
                case "ddm-pushResetMsg":
                    // console.log("来自父窗口消息-resetProject:", act);
                    console.log("卡到第一步10");
                    if (event.data.msg === "yes") {
                        console.log("卡到第一步11");
                        this.handleReset();
                    } else {
                        // 取消重置
                    }
                    break;
            }
        }
    }

    componentDidUpdate(prevProps) {
        console.log("卡到第一步12", prevProps);
        if (
            this.props.projectId !== prevProps.projectId &&
            this.props.projectId !== null
        ) {
            this.props.onUpdateProjectId(this.props.projectId);
            console.log("卡到第一步1");
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            console.log("卡到第一步2");
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded

            this.props.onProjectLoaded();
            //加载项目回调  sb3文件加载完成
            if (
                window.scratchConfig &&
                "handleProjectLoaded" in window.scratchConfig
            ) {
                console.log("卡到第一步3");
                window.scratchConfig.handleProjectLoaded();
                this.setState({
                    isEnd: true,
                });
                // cookies.set("scratchLoadEnd", "1");
                window.parent.postMessage(
                    { act: "scratch-load", msg: "1" }, //'1'为sb3文件已加载完成
                    "*"
                );
            }
        }
        if (
            this.props.isResetProject !== prevProps.isResetProject &&
            this.props.isResetProject
        ) {
            console.log("卡到第一步4");
            this._resetProject();
        }
    }

    // 触发重置前提条件
    _resetProject() {
        this.handleReset();
        // 练习、作业、自由创作
        // this.props.onResetProject(false);
        // const query = queryString.parse(location.search);
        // if (query.from === "practise" || query.from === "homework") {
        //     window.parent.postMessage(
        //         { act: "ddm-pushResetMsg", msg: "needReset" },
        //         "*"
        //     );
        // } else {
        //     this.setState({
        //         saveModalVisible: true,
        //     });
        // }
    }

    // 重置操作
    handleReset() {
        if (window.answerCodeUrl) {
            this.loadSb3File(window.answerCodeUrl);
        } else {
            console.log(141);
            this.loadSb3File(
                "https://port-release-1255999742.file.myqcloud.com/static/scratch/empty.sb3?1"
            );
        }
    }

    handleCancel() {
        this.setState({
            saveModalVisible: false,
        });
    }

    loadSb3File(url) {
        fetch(url, {
            method: "GET",
        })
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onload = () =>
                    this.props.vm.loadProject(reader.result).then(() => {
                        // 重置重置状态
                        this.props.onResetProject(false);
                    });
                reader.readAsArrayBuffer(blob);
            })
            .catch((error) => {
                console.log(`远程加载文件错误！${error}`);
            });
        this.setState({
            saveModalVisible: false,
        });
    }

    render() {
        if (this.props.isError) {
            throw new Error(
                `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`
            );
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onVmInit,
            projectHost,
            projectId,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            isWorkLoadingEnd,
            ...componentProps
        } = this.props;
        const { saveModalVisible, isEnd } = this.state;
        return (
            <>
                <Spin
                    tip="作品载入中..."
                    spinning={!isEnd}
                    stylebody={{ height: "100px" }}
                >
                    <GUIComponent
                        loading={
                            fetchingProject || isLoading || loadingStateVisible
                        }
                        {...componentProps}
                    >
                        {children}
                    </GUIComponent>
                    <CustomModal
                        width="514px"
                        ratio={1.601}
                        visible={saveModalVisible}
                        onCancel={this.handleCancel}
                        stylebody={{
                            backgroundSize: "cover",
                            backgroundImage: `url(${require("../components/custom-modal/image/small-modal.svg")})`,
                        }}
                    >
                        <div style={{ textAlign: "center", paddingTop: 20 }}>
                            <div
                                style={{
                                    fontSize: 28,
                                    marginBottom: 20,
                                }}
                            >
                                确认要重置代码吗？
                            </div>
                            <div style={{ fontSize: 20, marginBottom: 20 }}>
                                点击确定后你将丢失已创建的内容哟~
                            </div>
                            <div>
                                <CustomBtn
                                    className="custom-btn"
                                    onClick={this.handleCancel}
                                >
                                    取 消
                                </CustomBtn>
                                <CustomBtn
                                    className="custom-btn"
                                    onClick={this.handleReset}
                                >
                                    确 定
                                </CustomBtn>
                            </div>
                        </div>
                    </CustomModal>
                </Spin>
            </>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    children: PropTypes.node,
    cloudHost: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fetchingProject: PropTypes.bool,
    intl: intlShape,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onVmInit: PropTypes.func,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    telemetryModalVisible: PropTypes.bool,
    // onActivateBlocks: PropTypes.func,
    // onUpdateToolbox: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
    // EvesetIsWorkLoadingEnd: PropTypes.func,
    evesetSpriteJson: PropTypes.func,
    evesetCostumesJson: PropTypes.func,
    evesetBackdropsJson: PropTypes.func,
    evesetSoundsJson: PropTypes.func,
};

GUI.defaultProps = {
    isScratchDesktop: false,
    onStorageInit: (storageInstance) =>
        storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => {},
    onUpdateProjectId: () => {},
    evesetSpriteJson: () => {},
    evesetCostumesJson: () => {},
    evesetBackdropsJson: () => {},
    evesetSoundsJson: () => {},
    onVmInit: (/* vm */) => {},
};

const mapStateToProps = (state) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible:
            state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible:
            state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible:
            state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
        targetIsStage:
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id ===
                state.scratchGui.targets.editingTarget,
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        isResetProject: state.scratchGui.editorTab.isResetProject,
        vm: state.scratchGui.vm,
        isWorkLoadingEnd: state.scratchGui.login.isWorkLoadingEnd,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: (tab) => dispatch(activateTab(tab)),
    onResetProject: (status) => dispatch(resetProject(status)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal()),
    // EvesetIsWorkLoadingEnd: (status) => dispatch(setIsWorkLoadingEnd(status)),
    // evesetSpriteJson: (status) => dispatch(setSpriteJson(status)),
    // evesetCostumesJson: (status) => dispatch(setCostumesJson(status)),
    // evesetBackdropsJson: (status) => dispatch(setBackdropsJson(status)),
    // onUpdateToolbox: (value) => dispatch(updateToolbox(value)),
    // onActivateBlocks: (value) => dispatch(actionUpdateBlocks(value)),
    evesetSpriteJson: (status) => dispatch(setSpriteJson(status)),
    evesetCostumesJson: (status) => dispatch(setCostumesJson(status)),
    evesetBackdropsJson: (status) => dispatch(setBackdropsJson(status)),
    evesetSoundsJson: (status) => dispatch(setSoundsJson(status)),
});

const ConnectedGUI = injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(GUI)
);

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC("Top Level App"),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    TitledHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
