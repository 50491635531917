import messages from "./tag-messages.js";
export default [
    { tag: "animals", intlLabel: messages.animals },
    { tag: "people", intlLabel: messages.people },
    { tag: "fantasy", intlLabel: messages.fantasy },
    { tag: "dance", intlLabel: messages.dance },
    { tag: "music", intlLabel: messages.music },
    { tag: "sports", intlLabel: messages.sports },
    { tag: "food", intlLabel: messages.food },
    { tag: "fashion", intlLabel: messages.fashion },
    { tag: "letters", intlLabel: messages.letters },
    { tag: "dingdangcode", intlLabel: messages.dingdangcode },
];
