import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
import { message } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import VM from "scratch-vm-dingdangcode";
import { injectIntl } from "react-intl";
import styles from "../components/works-upload/mobile-virtual-keyboard.css";
import cookies from "js-cookie";
import queryString from "query-string";
import $ from "jquery";
import icon_up from "../assets/img/icon_up.png";
import icon_down from "../assets/img/icon_down.png";
import icon_left from "../assets/img/icon_left.png";
import icon_right from "../assets/img/icon_right.png";

const token = cookies.get("token"); //SCRATCH_TOKEN
class MobileKeyboard extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "valueChange",
            "handleKeyClick",
            "regKeyEvent",
            "includesEve",
        ]);
        this.state = {
            leftArr: [],
            rightArr: [],
            spaceArr: [],
            numArr: [],
            englishArr: [],
            spaceArr: [],
        };
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log("更新后的数据1111？", nextProps, nextState);
    // }
    componentWillReceiveProps(nextProps) {
        let vm = this.props.vm;
        const { sb3 } = queryString.parse(location.search);
        console.log("更新后的数据55555？", this.props.vm, nextProps, sb3);
        // let _blocks =
        //     vm &&
        //     vm.editingTarget &&
        //     vm.editingTarget.blocks &&
        //     vm.editingTarget.blocks._blocks;

        let blockAll = JSON.parse(vm.toJSON(sb3)).targets;
        let blocksArr = [];
        [].push.apply(blocksArr, blockAll);
        blocksArr.shift();
        JSON.parse(vm.toJSON(sb3)).targets.shift();
        let bb = [];
        let cc = [];
        blocksArr.map((item) => {
            bb.push(item.blocks);
        });
        bb.map((item) => {
            for (let p in item) {
                if (item[p].fields.KEY_OPTION) {
                    cc.push(item[p].fields.KEY_OPTION[0]);
                }
            }
        });
        let _blocksArr1 = [];
        let _blocksArr = Array.from(new Set(cc));
        console.log(5555, blocksArr, bb, cc, _blocksArr1);
        console.log(
            77777,
            JSON.parse(vm.toJSON(sb3)),
            vm.getPlaygroundData(),
            vm.getPlaygroundData(sb3)
        );
        vm.on("playgroundData", (data) => {
            console.log(2222222, data);
            //    updateThreadExplorer(data.threads);
            //    updateBlockExplorer(data.blocks);
        });
        // window.scratch.getProjectCover((file) => {
        //     console.log(888, file);
        //     //上传截图文件
        // });
        // let _blocksArr2 = [
        //     { id: null, name: "KEY_OPTION", value: "up arrow" },
        //     { id: null, name: "KEY_OPTION", value: "down arrow" },
        //     { id: null, name: "KEY_OPTION", value: "left arrow" },
        //     { id: null, name: "KEY_OPTION", value: "right arrow" },
        //     { id: null, name: "KEY_OPTION", value: "space" },
        //     { id: null, name: "KEY_OPTION", value: "0" },
        //     { id: null, name: "KEY_OPTION", value: "1" },
        //     { id: null, name: "KEY_OPTION", value: "2" },
        //     { id: null, name: "KEY_OPTION", value: "3" },
        //     { id: null, name: "KEY_OPTION", value: "4" },
        //     { id: null, name: "KEY_OPTION", value: "5" },
        //     { id: null, name: "KEY_OPTION", value: "6" },
        //     { id: null, name: "KEY_OPTION", value: "7" },
        //     { id: null, name: "KEY_OPTION", value: "8" },
        //     { id: null, name: "KEY_OPTION", value: "9" },
        //     { id: null, name: "KEY_OPTION", value: "a" },
        //     { id: null, name: "KEY_OPTION", value: "b" },
        //     { id: null, name: "KEY_OPTION", value: "c" },
        //     { id: null, name: "KEY_OPTION", value: "d" },
        //     { id: null, name: "KEY_OPTION", value: "e" },
        //     { id: null, name: "KEY_OPTION", value: "f" },
        //     { id: null, name: "KEY_OPTION", value: "g" },
        //     { id: null, name: "KEY_OPTION", value: "h" },
        //     { id: null, name: "KEY_OPTION", value: "i" },
        //     { id: null, name: "KEY_OPTION", value: "j" },
        //     { id: null, name: "KEY_OPTION", value: "k" },
        //     { id: null, name: "KEY_OPTION", value: "l" },
        //     { id: null, name: "KEY_OPTION", value: "m" },
        //     { id: null, name: "KEY_OPTION", value: "n" },
        //     { id: null, name: "KEY_OPTION", value: "o" },
        //     { id: null, name: "KEY_OPTION", value: "p" },
        //     { id: null, name: "KEY_OPTION", value: "q" },
        //     { id: null, name: "KEY_OPTION", value: "r" },
        //     { id: null, name: "KEY_OPTION", value: "s" },
        //     { id: null, name: "KEY_OPTION", value: "t" },
        //     { id: null, name: "KEY_OPTION", value: "u" },
        //     { id: null, name: "KEY_OPTION", value: "v" },
        //     { id: null, name: "KEY_OPTION", value: "w" },
        //     { id: null, name: "KEY_OPTION", value: "x" },
        //     { id: null, name: "KEY_OPTION", value: "y" },
        //     { id: null, name: "KEY_OPTION", value: "z" },
        // ]; //先放全部积木块
        let leftArr = [...this.state.leftArr];
        let rightArr = [...this.state.rightArr];
        let spaceArr = [...this.state.spaceArr];
        let numArr = [...this.state.numArr];
        let englishArr = [];
        // for (let p in _blocks) {
        //     if (_blocks[p].fields.KEY_OPTION) {
        //         _blocksArr.push(_blocks[p].fields.KEY_OPTION);
        //     }
        // }
        leftArr = _blocksArr.filter(
            (item) =>
                item === "up arrow" ||
                item === "down arrow" ||
                item === "left arrow" ||
                item === "right arrow"
        );
        rightArr = _blocksArr.filter(
            (item) =>
                item === "a" || item === "w" || item === "s" || item === "d"
        );
        numArr = _blocksArr.filter(
            (item) =>
                item === "0" ||
                item === "1" ||
                item === "2" ||
                item === "3" ||
                item === "4" ||
                item === "5" ||
                item === "6" ||
                item === "7" ||
                item === "8" ||
                item === "9"
        );
        englishArr = _blocksArr.filter(
            (item) =>
                item === "b" ||
                item === "c" ||
                item === "e" ||
                item === "f" ||
                item === "g" ||
                item === "h" ||
                item === "i" ||
                item === "j" ||
                item === "k" ||
                item === "l" ||
                item === "m" ||
                item === "n" ||
                item === "o" ||
                item === "p" ||
                item === "q" ||
                item === "r" ||
                item === "x" ||
                item === "t" ||
                item === "u" ||
                item === "v" ||
                item === "y" ||
                item === "z"
        );
        spaceArr = _blocksArr.filter((item) => item === "space");
        this.setState({
            leftArr: leftArr,
        });
        this.setState({
            rightArr: rightArr,
        });
        this.setState({
            numArr: numArr,
        });
        englishArr.sort(function (a, b) {
            return a.localeCompare(b);
        });
        this.setState({
            englishArr: englishArr,
        });
        this.setState({
            spaceArr: spaceArr,
        });
        console.log("积木块数组", leftArr, rightArr, this.state.leftArr);
        console.log(
            333,
            this.state.leftArr.length > 0 &&
                this.includesEve(this.state.leftArr, "up arrow") > -1
        );
    }
    valueChange(e) {
        console.log(33, e.target.value, this.props);
    }
    regKeyEvent(selector, key, keyCode) {
        console.log("注册按键事件:" + selector, key, keyCode);
        const that = this;
        $(selector).on("touchstart", function (event) {
            that.props.vm.postIOData("keyboard", {
                keyCode: keyCode,
                key: key,
                isDown: true,
            });
            event.preventDefault();
        });
        $(selector).on("touchend", function (event) {
            that.props.vm.postIOData("keyboard", {
                keyCode: keyCode,
                key: key,
                isDown: false,
            });
            event.preventDefault();
        });
    }
    handleKeyClick(dom, direction, val) {
        this.regKeyEvent(dom, direction, val);
    }
    includesEve(arr, res) {
        let bb = arr.findIndex((item) => item === res);
        return bb;
    }
    render() {
        const { leftArr, rightArr, numArr, englishArr, spaceArr } = this.state;
        return (
            <div className={styles.keyWra} style={{ width: "100%" }}>
                {/* <div> */}
                {/* <div>X</div>className={styles.keyWra} */}
                <div
                    className={`${styles.keyContainer} ${
                        leftArr.length > 0 && rightArr.length > 0
                            ? styles.topConBet
                            : styles.topConCenter
                    }`}
                >
                    <div className={`${styles.leftContainer}`}>
                        <div className={`${styles.topCon}`}>
                            {leftArr.length > 0 &&
                                this.includesEve(leftArr, "up arrow") > -1 && (
                                    <div
                                        className={`${styles.topIcon} arrow-up`}
                                        style={{ lineHeight: "35px" }}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-up",
                                                "ArrowUp",
                                                273
                                            )
                                        }
                                        // onClick={() =>
                                        //     this.handleKeyClick(
                                        //         ".arrow-up",
                                        //         "ArrowUp",
                                        //         273
                                        //     )
                                        // }
                                    >
                                        <img src={icon_up} alt="" />
                                    </div>
                                )}
                        </div>
                        <div className={styles.middCon}>
                            {leftArr.length > 0 &&
                                this.includesEve(leftArr, "left arrow") >
                                    -1 && (
                                    <div
                                        className={`${styles.topIcon} ${styles.arrowLeft} arrow-left`}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-left",
                                                "ArrowLeft",
                                                276
                                            )
                                        }
                                        onClick={() =>
                                            this.handleKeyClick(
                                                ".arrow-left",
                                                "ArrowLeft",
                                                276
                                            )
                                        }
                                    >
                                        <img src={icon_left} alt="" />
                                    </div>
                                )}
                            {leftArr.length > 0 &&
                                this.includesEve(leftArr, "right arrow") >
                                    -1 && (
                                    <div
                                        className={`${styles.topIcon} ${styles.arrowRight} arrow-right`}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-right",
                                                "ArrowRight",
                                                275
                                            )
                                        }
                                        onClick={() =>
                                            this.handleKeyClick(
                                                ".arrow-right",
                                                "ArrowRight",
                                                275
                                            )
                                        }
                                    >
                                        <img src={icon_right} alt="" />
                                    </div>
                                )}
                        </div>
                        <div className={styles.topCon}>
                            {leftArr.length > 0 &&
                                this.includesEve(leftArr, "down arrow") >
                                    -1 && (
                                    <div
                                        className={`${styles.topIcon} arrow-down`}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-down",
                                                "ArrowDown",
                                                274
                                            )
                                        }
                                        onClick={() =>
                                            this.handleKeyClick(
                                                ".arrow-down",
                                                "ArrowDown",
                                                274
                                            )
                                        }
                                    >
                                        <img src={icon_down} alt="" />
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className={`${styles.leftContainer}`}>
                        <div className={`${styles.topCon}`}>
                            {rightArr.length > 0 &&
                                this.includesEve(rightArr, "w") > -1 && (
                                    <div
                                        className={`${styles.topIcon} arrow-up1`}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-up1",
                                                "W",
                                                119
                                            )
                                        }
                                        onClick={() =>
                                            this.handleKeyClick(
                                                ".arrow-up1",
                                                "W",
                                                119
                                            )
                                        }
                                    >
                                        W
                                    </div>
                                )}
                        </div>
                        <div className={styles.middCon}>
                            {rightArr.length > 0 &&
                                this.includesEve(rightArr, "a") > -1 && (
                                    <div
                                        className={`${styles.topIcon} ${styles.arrowLeft} arrow-left1`}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-left1",
                                                "A",
                                                97
                                            )
                                        }
                                        onClick={() =>
                                            this.handleKeyClick(
                                                ".arrow-left1",
                                                "A",
                                                97
                                            )
                                        }
                                    >
                                        A
                                    </div>
                                )}
                            {rightArr.length > 0 &&
                                this.includesEve(rightArr, "d") > -1 && (
                                    <div
                                        className={`${styles.topIcon} ${styles.arrowRight} arrow-right1`}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-right1",
                                                "D",
                                                100
                                            )
                                        }
                                        onClick={() =>
                                            this.handleKeyClick(
                                                ".arrow-right1",
                                                "D",
                                                100
                                            )
                                        }
                                    >
                                        D
                                    </div>
                                )}
                        </div>
                        <div className={styles.topCon}>
                            {rightArr.length > 0 &&
                                this.includesEve(rightArr, "s") > -1 && (
                                    <div
                                        className={`${styles.topIcon} arrow-down1`}
                                        onTouchStart={() =>
                                            this.handleKeyClick(
                                                ".arrow-down1",
                                                "S",
                                                115
                                            )
                                        }
                                        onClick={() =>
                                            this.handleKeyClick(
                                                ".arrow-down1",
                                                "S",
                                                115
                                            )
                                        }
                                    >
                                        S
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div
                    className={`${styles.keyBottom} ${
                        spaceArr.length > 0 && rightArr.length <= 0
                            ? styles.topConCenter
                            : styles.topConStart
                    }`}
                >
                    {spaceArr.length > 0 &&
                        this.includesEve(spaceArr, "space") > -1 && (
                            <div
                                className={`${styles.topIconSpace} arrow-space`}
                                onTouchStart={() =>
                                    this.handleKeyClick(".arrow-space", " ", 32)
                                }
                                onClick={() =>
                                    this.handleKeyClick(".arrow-space", " ", 32)
                                }
                            >
                                空格
                            </div>
                        )}
                    {numArr.length > 0 &&
                        numArr.map((item, index) => {
                            return (
                                <div
                                    //  "Digit" +
                                    className={`${styles.topIconNum} arrow-space${item}`}
                                    onTouchStart={() =>
                                        this.handleKeyClick(
                                            ".arrow-space" + item,
                                            item,
                                            item === 0
                                                ? 48
                                                : item === 1
                                                ? 49
                                                : item === 2
                                                ? 50
                                                : item === 3
                                                ? 51
                                                : item === 4
                                                ? 52
                                                : item === 5
                                                ? 53
                                                : item === 6
                                                ? 54
                                                : item === 7
                                                ? 55
                                                : item === 8
                                                ? 56
                                                : 57
                                        )
                                    }
                                    key={index}
                                    // style={{
                                    //     marginRight:
                                    //         spaceArr.length > 0 &&
                                    //         index == 2
                                    //             ? "0"
                                    //             : "17px",
                                    // }}
                                    onClick={() =>
                                        // "Digit" +
                                        this.handleKeyClick(
                                            ".arrow-space" + index,
                                            item,
                                            item === 0
                                                ? 48
                                                : item === 1
                                                ? 49
                                                : item === 2
                                                ? 50
                                                : item === 3
                                                ? 51
                                                : item === 4
                                                ? 52
                                                : item === 5
                                                ? 53
                                                : item === 6
                                                ? 54
                                                : item === 7
                                                ? 55
                                                : item === 8
                                                ? 56
                                                : 57
                                        )
                                    }
                                >
                                    {item}
                                </div>
                            );
                        })}
                    {englishArr.length > 0 &&
                        englishArr.map((item, index) => {
                            return (
                                <div
                                    className={`${styles.topIconNum} arrow-space${item}`}
                                    onTouchStart={() =>
                                        // "Key" +
                                        this.handleKeyClick(
                                            ".arrow-space" + item,
                                            item.toUpperCase(),
                                            item === "b"
                                                ? 98
                                                : item === "c"
                                                ? 99
                                                : item === "e"
                                                ? 101
                                                : item === "f"
                                                ? 102
                                                : item === "g"
                                                ? 103
                                                : item === "h"
                                                ? 104
                                                : item === "i"
                                                ? 105
                                                : item === "j"
                                                ? 106
                                                : item === "k"
                                                ? 107
                                                : item === "l"
                                                ? 108
                                                : item === "m"
                                                ? 109
                                                : item === "n"
                                                ? 110
                                                : item === "o"
                                                ? 111
                                                : item === "p"
                                                ? 112
                                                : item === "q"
                                                ? 113
                                                : item === "r"
                                                ? 114
                                                : item === "t"
                                                ? 116
                                                : item === "u"
                                                ? 117
                                                : item === "v"
                                                ? 118
                                                : item === "z"
                                                ? 122
                                                : item === "x"
                                                ? 120
                                                : 121
                                        )
                                    }
                                    key={index}
                                    // style={{
                                    //     marginRight:
                                    //         spaceArr.length > 0 &&
                                    //         index == 2
                                    //             ? "0"
                                    //             : "17px",
                                    // }}
                                    onClick={() =>
                                        // "Key" +
                                        this.handleKeyClick(
                                            ".arrow-space" + item,
                                            item.toUpperCase(),
                                            item === "b"
                                                ? 98
                                                : item === "c"
                                                ? 99
                                                : item === "e"
                                                ? 101
                                                : item === "f"
                                                ? 102
                                                : item === "g"
                                                ? 103
                                                : item === "h"
                                                ? 104
                                                : item === "i"
                                                ? 105
                                                : item === "j"
                                                ? 106
                                                : item === "k"
                                                ? 107
                                                : item === "l"
                                                ? 108
                                                : item === "m"
                                                ? 109
                                                : item === "n"
                                                ? 110
                                                : item === "o"
                                                ? 111
                                                : item === "p"
                                                ? 112
                                                : item === "q"
                                                ? 113
                                                : item === "r"
                                                ? 114
                                                : item === "t"
                                                ? 116
                                                : item === "u"
                                                ? 117
                                                : item === "v"
                                                ? 118
                                                : item === "z"
                                                ? 122
                                                : item === "x"
                                                ? 120
                                                : 121
                                        )
                                    }
                                >
                                    {item.toUpperCase()}
                                </div>
                            );
                        })}
                </div>
                {/* </div> */}
            </div>
        );
    }
}

MobileKeyboard.propTypes = {
    // answer: PropTypes.string,
    // className: PropTypes.string,
    // onChange: PropTypes.func.isRequired,
    // onClick: PropTypes.func.isRequired,
    // onKeyPress: PropTypes.func.isRequired,
    // question: PropTypes.string,

    onSeeCommunity1: PropTypes.func,
    drawShowEve: PropTypes.func,
    dispatchDraftTitle: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
    blocks: PropTypes.object,
};

// export default MobileKeyboard;

const mapStateToProps = (state, ownProps) => {
    return {
        hideMenu: state.scratchGui.login.login,
        draftTitle: state.scratchGui.login.draftTitle,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    drawShowEve: () => dispatch(setpublishDrawShow(true)),
    dispatchDraftTitle: (res) => dispatch(changeDraftTitle(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(MobileKeyboard);
