/* eslint-disable */
const UPDATE_TOOLBOX = 'scratch-gui/toolbox/UPDATE_TOOLBOX';
const UPDATE_BLOCKS = 'scratch-gui/toolbox/UPDATE_BLOCKS';
import makeToolboxXML from '../lib/make-toolbox-xml';

const initialState = {
    toolboxXML: makeToolboxXML({
        isInitialSetup: true,
        customblocks: {}
    }),
    customblocks: {}
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_TOOLBOX:
            return Object.assign({}, state, {
                toolboxXML: action.toolboxXML
            });
        case UPDATE_BLOCKS:
            return Object.assign({}, state, {
                customblocks: action.customblocks
            });
        default:
            return state;
    }
};

const updateToolbox = function (toolboxXML) {
    return {
        type: UPDATE_TOOLBOX,
        toolboxXML: toolboxXML
    };
};

const actionUpdateBlocks = function (customblocks) {
    return {
        type: UPDATE_BLOCKS,
        customblocks: customblocks
    };
};

export {
    reducer as
    default,
    initialState as toolboxInitialState,
    updateToolbox,
    actionUpdateBlocks
};
