/* eslint-disable */
// Polyfills
import "es6-object-assign/auto";
import "core-js/fn/array/includes";
import "core-js/fn/promise/finally";
import "intl"; // For Safari 9

import React from "react";
import ReactDOM from "react-dom";

// import analytics from "../lib/analytics";
import AppStateHOC from "../lib/app-state-hoc.jsx";
import BrowserModalComponent from "../components/browser-modal/browser-modal.jsx";
import supportedBrowser from "../lib/supported-browser";
import queryString from "query-string";
import { initialBlocks } from "./initialBlocks";
import styles from "./index.css";
import { Modal } from "antd";
import {
    queryLearnRecord,
    queryGetDraftDetail,
    queryGetUserinfo,
} from "../api/apiServer/apiCommon";
import eventTracking from "../utils/eventTracking";
import cookies from "js-cookie";
// Register "base" page view
// analytics.pageview("/");

const appTarget = document.createElement("div");
appTarget.className = styles.app;
document.body.appendChild(appTarget);
const query = queryString.parse(location.search);
const base_url =
    process.env.NODE_ENV === "development"
        ? "https://api.dingdangcode.cn"
        : "https://api.dingdangcode.com";
const { goodsId, taskId, stepId, subjectId, draftId, type, adapt } = query;
const token = cookies.get("token");
if (draftId) {
    sessionStorage.setItem("draftId", draftId);
}
// 清空时 移除 输出区域dom元素
const removeDom = () => {
    let aa = document.querySelectorAll("body");
    aa.innerHTML = "";
    let outputContainer = document.querySelectorAll("div.draft-list_app_2HTJt");
    if (outputContainer && outputContainer.length > 0) {
        for (let i = 0, len = outputContainer.length; i < len; i++) {
            outputContainer[i].parentNode.removeChild(outputContainer[i]);
        }
        return true;
    } else {
        return false;
    }
};
// 从C端过来的获取项目初始化数据
function fetchSubject(url) {
    queryLearnRecord({ goodsId, taskId, stepId, subjectId }).then(
        ({ data }) => {
            if (data.code === 0) {
                if (data.data.content) {
                    let _content = JSON.parse(data.data.content);
                    window.answerCodeUrl = _content ? _content.userAnswer : "";
                }

                require("./render-gui.jsx").default(appTarget);
            }
        }
    );
}
// 从试炼场的草稿列表过来的获取项目初始化数据
function fetchDraftDetail() {
    queryGetDraftDetail({ id: draftId }).then(({ data }) => {
        if (data.code === 0) {
            if (data.data.fileUrl) {
                window.answerCodeUrl = data.data.fileUrl;
            }
            console.log("获取与草稿具体值", data.data);
            sessionStorage.setItem("DRAFT_TITLE", data.data.title);
            if (type && type !== "courseware") {
                //如果不是从课件过来的，就保存id。然后是覆盖草稿，否则就新增草稿
                sessionStorage.setItem("draftId", data.data.id);
            }
            cookies.set("DRAFT_TITLE", data.data.title);
            require("./render-gui.jsx").default(appTarget);
        }
    });
}
// 获取用户信息
function fetchGetUserinfo() {
    queryGetUserinfo().then(({ data }) => {
        if (data.code === 0) {
            cookies.set("nickname", data.data.nickname);
            cookies.set("mobile", data.data.mobile);
            cookies.set("portraitUrl", data.data.portraitUrl);
            // this.props.portraitUrlEve(data.data.portraitUrl);
        }
        // else if (data.code === 35) {
        //     console.log(10000000);
        //     Modal.info({
        //         title: "下线通知",
        //         content: (
        //             <div>
        //                 账号已在另一地点登录，你的账号被迫下线。
        //                 <br />
        //                 若非本人操作，建议重新登录账号并修改密码。
        //             </div>
        //         ),
        //         okText: "重新登录",
        //         onOk() {
        //             // this.props.modalShowEve1();
        //             // logout().then(({ data }) => {});
        //             //清空所有cookie
        //             let getCookie = cookies.get();
        //             for (let p in getCookie) {
        //                 cookies.remove(`${p}`);
        //             }
        //             cookies.remove("token"); //SCRATCH_TOKEN
        //             sessionStorage.removeItem("token"); //SCRATCH_TOKEN
        //             // cookies.remove("PLAY_TOKEN");
        //             // sessionStorage.removeItem("PLAY_TOKEN");
        //             sessionStorage.removeItem("DRAFT_TITLE");
        //             sessionStorage.removeItem("draftId");
        //             sessionStorage.removeItem("sourceType");
        //             sessionStorage.removeItem("shareId");
        //             window.location.replace(
        //                 window.location.origin + window.location.pathname
        //             );
        //         },
        //     });
        // }
    });
}
if (supportedBrowser()) {
    // 动态配置积木  api请求
    // 获取 初始化文件地址
    // 获取 初始化积木块
    removeDom();
    //埋点
    eventTracking({
        event: "scratch_view",
        eventName: "浏览编辑器",
    });
    if (query.from === "ddcodeadmin" && query.sb3) {
        window.answerCodeUrl = query.sb3;
        require("./render-gui.jsx").default(appTarget);
    } else if (query.from === "eduCourseware" && query.sb3) {
        //从B端的课件中的“创作文件”过来
        window.answerCodeUrl = query.sb3;
        require("./render-gui.jsx").default(appTarget);
    } else if (query.from === "course" && query.token) {
        fetchSubject();
    } else if (
        query.from === "play" ||
        query.from === "edu" ||
        query.from === "vip"
    ) {
        // && query.token
        //从试炼场的草稿箱过来或者从B端的“我的作品”中的“草稿”过来或从C端的“个人中心”->“我的作品”中跳过来
        fetchDraftDetail();
        if (query.token) {
            cookies.set("token", query.token);
        }
    } else {
        window.customblocks_ = initialBlocks;
        window.answerCodeUrl = query.sb3;
        // "https://port-release-1255999742.file.myqcloud.com/userupload/scratch/989/1627451432471/785e664f-692f-42d6-a38f-f7f4138811f2.sb3";
        require("./render-gui.jsx").default(appTarget); //初始化一个有默认声音的sb3文件，之前默认的文件没有声音。基本属于一个空白的sb3文件
    }
    if (token) {
        fetchGetUserinfo();
    }
    // aa();
    // require needed here to avoid importing unsupported browser-crashing code
    // at the top level
} else {
    BrowserModalComponent.setAppElement(appTarget);
    const WrappedBrowserModalComponent = AppStateHOC(
        BrowserModalComponent,
        true /* localesOnly */
    );
    const handleBack = () => {};
    // eslint-disable-next-line react/jsx-no-bind
    ReactDOM.render(
        <WrappedBrowserModalComponent onBack={handleBack} />,
        appTarget
    );
}
