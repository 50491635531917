import React from "react";
import styles from "./custom-button.css";
import classname from "classnames";

const NormalBtn = (props) => {
    const { children } = props;

    return (
        <div
            {...props}
            className={
                props.className
                    ? classname(styles["custom-btn"], props.className)
                    : styles["custom-btn"]
            }
        >
            {children}
        </div>
    );
};

export default NormalBtn;
