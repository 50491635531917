import React from "react";
import { Modal, Button as AntdButton, message } from "antd";
import PropTypes from "prop-types";
import bindAll from "lodash.bindall";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import styles2 from "../components/gui/gui.css";
import SB3Downloader from "./sb3-downloader.jsx";
import { generateUUID } from "../utils/index";
import cookies from "js-cookie";
import classNames from "classnames";
import {
    setLoginModalShow,
    setpublishDrawShow,
    setCanvasUrl,
} from "../reducers/login";
import { cosUploadFile } from "../utils/cosUploadFile";

let isClick1 = true;
class DeviceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClick: 0,
        };
        bindAll(this, [
            "linkDevices",
            "dataURLtoBlob",
            "canvasSort",
            "blobToFile",
        ]);
    }

    linkDevices(className, downloadProjectCallback) {
        console.log(30000, isClick1);
        console.log(222, className, "===", this.props);
        const token = cookies.get("token") || sessionStorage.getItem("token"); //SCRATCH_TOKEN
        if (this.props.type) {
            if (token) {
                if (isClick1) {
                    //如果为true 开始执行
                    console.log(4333, this.props.vm);
                    this.props.vm.renderer.draw();
                    this.setState({ isClick: 1 }); //将isClick 变成false，将不会执行处理事件
                    isClick1 = false;

                    // window.scratch.getProjectCover((file) => {
                    //     console.log(855555555, file);
                    //     //上传截图文件
                    // });
                    console.log(3222, this.state.isClick, isClick1);

                    // 编写点击事件执行的代码
                    let stageCanvas = document.getElementById("stage-canvers");
                    // console.log(104, stageCanvas);
                    const that = this;
                    let timeNum = 4;
                    let timer = setInterval(() => {
                        if (stageCanvas) {
                            let imgUrl = stageCanvas.toDataURL("image/jpeg");
                            let blob = that.dataURLtoBlob(imgUrl);
                            let file = that.blobToFile(blob, generateUUID());
                            console.log(110, blob, file);
                            timeNum--;
                            if (timeNum === 0) {
                                clearInterval(timer);
                            }
                            if (file && blob.size > 8000) {
                                clearInterval(timer);
                                cosUploadFile({
                                    type: "user_scratch",
                                    file: {
                                        data: file,
                                        filename: "creativeCover",
                                    },
                                    upProgress: (info) => {},
                                    upSucceed: (res) => {
                                        console.log(84848484, res.url);
                                        that.props.dispatchtCanvasUrl(res.url);
                                    },
                                    upProgress: (res) => {
                                        console.log(88888893, res.url);
                                    },
                                });
                            }
                        }
                    }, 1000);
                    if (this.props.type === 3) {
                        if (
                            this.props.draftTitle === "" ||
                            !this.props.draftTitle
                        ) {
                            message.warning("请输入草稿名称");
                        } else {
                            downloadProjectCallback(this.props.type);
                        }
                    } else if (this.props.type === 5) {
                        const draftId = cookies.get("draftId");
                        console.log(
                            "获取到的draftId值",
                            sessionStorage.getItem("draftId")
                        );
                        if (!sessionStorage.getItem("draftId")) {
                            message.warning("请保存后再发布！");
                        } else {
                            this.props.drawShowEve();
                            downloadProjectCallback(this.props.type);
                        }
                    }

                    //const that = this; // 为定时器中的setState绑定this
                    setTimeout(function () {
                        console.log("进来延迟");
                        // 设置延迟事件，1秒后将执行
                        //that.setState({ isClick: 0 }); // 将isClick设置为true
                        isClick1 = true;
                    }, 1000);
                }
                console.log(
                    "this.props保存草稿获取到的title值",
                    this.props,
                    isClick1
                );
            } else {
                console.log("没登录", this.props);
                this.props.onSeeCommunity1();
            }
        } else {
            downloadProjectCallback(2); //2
        }
    }
    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
    dataURLtoBlob(dataurl) {
        let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
    canvasSort(a, b) {
        return a.style.zIndex - b.style.zIndex;
    }
    render() {
        const { type, draftTitle } = this.props;
        return (
            <SB3Downloader>
                {(className, downloadProjectCallback) => (
                    <AntdButton
                        className={classNames(
                            styles2.comBtn,
                            styles2.resetBtn,
                            {
                                [styles2.draftSave]: type == 3,
                                [styles2.draftPublish]: type == 5,
                            }
                        )}
                        onClick={() =>
                            this.linkDevices(className, downloadProjectCallback)
                        }
                    >
                        {type && type == 3 ? (
                            <span>
                                <i className="iconfont iconIcon_Save"></i>
                                &nbsp;&nbsp;保存
                            </span>
                        ) : type && type == 5 ? (
                            <span>
                                <i className="iconfont iconPractice_Icon_Release"></i>
                                &nbsp;&nbsp;发布作品
                            </span>
                        ) : (
                            <div>保存</div>
                        )}
                    </AntdButton>
                )}
            </SB3Downloader>
        );
    }
}

// export default DeviceModal;
DeviceModal.propTypes = {
    onSeeCommunity1: PropTypes.func,
    drawShowEve: PropTypes.func,
    dispatchtCanvasUrl: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    return {
        hideMenu: state.scratchGui.login.login,
        // draftTitle: state.scratchGui.login.draftTitle,
        draftId: state.scratchGui.login.draftId,
        publishCode: state.scratchGui.login.publishCode,
        vm: state.scratchGui.vm,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    drawShowEve: () => dispatch(setpublishDrawShow(true)),
    dispatchtCanvasUrl: (res) => dispatch(setCanvasUrl(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(DeviceModal);
