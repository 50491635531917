/* eslint-disable */
import classNames from "classnames";
import omit from "lodash.omit";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
    intlShape,
} from "react-intl";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import tabStyles from "react-tabs/style/react-tabs.css";
import VM from "scratch-vm-dingdangcode";
import Renderer from "scratch-render";
import { Button as AntdButton } from "antd";

import Blocks from "../../containers/blocks.jsx";
import CostumeTab from "../../containers/costume-tab.jsx";
import TargetPane from "../../containers/target-pane.jsx";
import SoundTab from "../../containers/sound-tab.jsx";
import StageWrapper from "../../containers/stage-wrapper.jsx";
import Loader from "../loader/loader.jsx";
import Box from "../box/box.jsx";
import MenuBar from "../menu-bar/menu-bar.jsx";
import CostumeLibrary from "../../containers/costume-library.jsx";
import BackdropLibrary from "../../containers/backdrop-library.jsx";
import Watermark from "../../containers/watermark.jsx";
import SaveSb3File from "../../containers/save-sb3file.jsx";

import Backpack from "../../containers/backpack.jsx";
import WebGlModal from "../../containers/webgl-modal.jsx";
import TipsLibrary from "../../containers/tips-library.jsx";
import Cards from "../../containers/cards.jsx";
import Alerts from "../../containers/alerts.jsx";
import DragLayer from "../../containers/drag-layer.jsx";
import ConnectionModal from "../../containers/connection-modal.jsx";
import TelemetryModal from "../telemetry-modal/telemetry-modal.jsx";

import layout, { STAGE_SIZE_MODES } from "../../lib/layout-constants";
import { resolveStageSize } from "../../lib/screen-utils";

import styles from "./gui.css";
import addExtensionIcon from "./icon--extensions.svg";
import codeIcon from "./icon--code.svg";
import costumesIcon from "./icon--costumes.svg";
import soundsIcon from "./icon--sounds.svg";
import iconService from "./images/icon-service@2x.png";
import iconPrompt from "./images/prompt@2x.png";
import iconTask from "./images/task@2x.png";
import classnames from "classnames";
import queryString from "query-string";
import DeviceModal from "../device-modal/index.jsx";
import SBFileUploader from "../../containers/sb-file-uploader.jsx";
import MobileKeyboard from "../../containers/mobile-virtual-keyboard.jsx";
import { setFullScreen } from "../../reducers/mode";
import {
    setSpriteJson,
    setCostumesJson,
    setBackdropsJson,
    setSoundsJson,
} from "../../reducers/login";
const messages = defineMessages({
    addExtension: {
        id: "gui.gui.addExtension",
        description: "Button to add an extension in the target pane",
        defaultMessage: "Add Extension",
    },
});

// Cache this value to only retrieve it once the first time.
// Assume that it doesn't change for a session.
let isRendererSupported = null;

const { scratchWidth } = queryString.parse(location.search);
const GUIComponent = (props) => {
    // class GUIComponent extends React.Component {
    //          constructor(props) {
    //         super(props);
    //         bindAll(this, [
    //         ]);
    //     }
    const {
        accountNavOpen,
        activeTabIndex,
        alertsVisible,
        authorId,
        authorThumbnailUrl,
        authorUsername,
        basePath,
        backdropLibraryVisible,
        backpackHost,
        backpackVisible,
        blocksTabVisible,
        cardsVisible,
        canChangeLanguage,
        canCreateNew,
        canEditTitle,
        canManageFiles,
        canRemix,
        canSave,
        canCreateCopy,
        canShare,
        canUseCloud,
        children,
        connectionModalVisible,
        costumeLibraryVisible,
        costumesTabVisible,
        enableCommunity,
        intl,
        isCreating,
        isFullScreen,
        isResetProject,
        isPlayerOnly,
        isRtl = true,
        isShared,
        loading,
        logo,
        renderLogin,
        onClickAbout,
        onClickAccountNav,
        onCloseAccountNav,
        onLogOut,
        onOpenRegistration,
        onToggleLoginOpen,
        onActivateCostumesTab,
        onActivateSoundsTab,
        onResetProject,
        onActivateTab,
        onClickLogo,
        onExtensionButtonClick,
        onProjectTelemetryEvent,
        onRequestCloseBackdropLibrary,
        onRequestCloseCostumeLibrary,
        onRequestCloseTelemetryModal,
        onSeeCommunity,
        onShare,
        onShowPrivacyPolicy,
        onTelemetryModalCancel,
        onTelemetryModalOptIn,
        onTelemetryModalOptOut,
        showComingSoon,
        soundsTabVisible,
        stageSizeMode,
        targetIsStage,
        telemetryModalVisible,
        tipsLibraryVisible,
        hideMenu,
        vm,
        turbo,
        isStarted,
        onKeyPress,
        url,
        onSetStageFull,
        evesetSpriteJson,
        evesetCostumesJson,
        evesetBackdropsJson,
        evesetSoundsJson,
        onClickFile,
        ...componentProps
    } = omit(props, "dispatch");
    if (children) {
        return <Box {...componentProps}>{children}</Box>;
    }

    // let visible = false;
    const tabClassNames = {
        tabs: styles.tabs,
        tab: classNames(tabStyles.reactTabsTab, styles.tab),
        tabList: classNames(tabStyles.reactTabsTabList, styles.tabList),
        tabPanel: classNames(tabStyles.reactTabsTabPanel, styles.tabPanel),
        tabPanelSelected: classNames(
            tabStyles.reactTabsTabPanelSelected,
            styles.isSelected
        ),
        tabSelected: classNames(
            tabStyles.reactTabsTabSelected,
            styles.isSelected
        ),
    };
    let _blocks =
        vm &&
        vm.editingTarget &&
        vm.editingTarget.blocks &&
        vm.editingTarget.blocks._blocks;

    if (isRendererSupported === null) {
        isRendererSupported = Renderer.isSupported();
    }
    const { from, onlyPlaysb3, goMobileTerminal, goodsId } = queryString.parse(
        location.search
    );
    if (onlyPlaysb3 === "1") {
        //如果只播放sb3文件
        onSetStageFull();
    }
    const handlePrompt = () => {
        window.parent.postMessage(
            { act: "ddm-onlinePrompt", msg: "show" },
            "*"
        );
    };

    const handleTask = () => {
        window.parent.postMessage({ act: "ddm-onlineTask", msg: "show" }, "*");
    };

    const handleOnlineQA = () => {
        window.parent.postMessage({ act: "ddm-onlineQA", msg: "show" }, "*");
    };

    const modalShow = useRef(null);

    if (window.scratchConfig && "handleVmInitialized" in window.scratchConfig) {
        window.scratchConfig.handleVmInitialized(vm);
    }

    const handleGreenFlagClick = (e) => {
        e.preventDefault();
        console.log("点击", modalShow.current);
        modalShow.current.style.display = "none";
        if (e.shiftKey) {
            vm.setTurboMode(!turbo); //this.props.vm.setTurboMode(!this.props.turbo)
        } else {
            if (!isStarted) {
                //this.props.isStarted
                vm.start(); // this.props.vm.start();
            }
            vm.greenFlag(); //this.props.vm.greenFlag();
        }
    };
    //增加关闭页面消息提示
    if (onlyPlaysb3 !== "1" || !goodsId) {
        window.onbeforeunload = function (e) {
            var e = window.event || e;
            e.returnValue = "确定离开当前页面吗？";
        };
    }

    // window.addEventListener("message", function (e) {
    //     // 监听 message 事件
    //     console.log(
    //         163,
    //         e.origin,
    //         e,
    //         e.data.type,
    //         e.data.type === "goMobileKey"
    //     );
    //     if (e.data.type === "goMobileKey") {
    //         console.log("进来没2");
    //         let startDom = document.getElementById("goStart");
    //         console.log("进来没1", startDom);
    //         startDom &&
    //             startDom.addEventListener("click", function (e) {
    //                 console.log("进来没");
    //                 handleGreenFlagClick(e);
    //             });
    //     }
    //     if (
    //         e.origin !== "http://localhost:3000/" ||
    //         e.origin !== "https://m.dingdangcode.cn/" ||
    //         e.origin !== "https://m.dingdangcode.com/"
    //     ) {
    //         // 验证消息来源地址
    //         return;
    //     }
    // });
    // window.addEventListener(
    //     "message",
    //     (event) => {
    //         if (event.data.type === "command1") {
    //             // console.log("监听到的事件", event);
    //         }
    //     },
    //     false
    // );
    return (
        <MediaQuery minWidth={layout.fullSizeMinWidth}>
            {(isFullSize) => {
                const stageSize = resolveStageSize(stageSizeMode, isFullSize);

                return isPlayerOnly ? (
                    <StageWrapper
                        isFullScreen={isFullScreen}
                        isRendererSupported={isRendererSupported}
                        isRtl={isRtl}
                        loading={loading}
                        stageSize={STAGE_SIZE_MODES.large}
                        vm={vm}
                    >
                        {alertsVisible ? (
                            <Alerts className={styles.alertsContainer} />
                        ) : null}
                    </StageWrapper>
                ) : onlyPlaysb3 ? (
                    <Box
                        className={styles.flexWrapper}
                        id="sb3_dom"
                        style={{
                            position: "relative",
                            background: scratchWidth
                                ? "rgb(232, 237, 241)"
                                : "hsla(0, 100%, 100%, 1)",
                        }}
                    >
                        {/* 舞台区 */}
                        <StageWrapper
                            isFullScreen={true}
                            isRendererSupported={isRendererSupported}
                            isRtl={isRtl}
                            stageSize={STAGE_SIZE_MODES.large}
                            vm={vm}
                        />
                        <SBFileUploader
                            isInitial={true}
                            onUpdateProjectTitle={PropTypes.func}
                        >
                            {(loadProject) => (
                                <button
                                    onClick={loadProject}
                                    className={classNames(styles.scratchHide)}
                                    style={{ display: "none" }}
                                ></button>
                            )}
                        </SBFileUploader>
                        {goMobileTerminal == 1 && (
                            <div
                                className={classnames(styles.modalcls)}
                                style={{
                                    height: "550px",
                                    height:
                                        document.getElementById(
                                            "stage-canvers"
                                        ) &&
                                        document.getElementById("stage-canvers")
                                            .style.height + 11,
                                }}
                                ref={modalShow}
                            >
                                <div className={classnames(styles.modalshow)}>
                                    <div className={classnames(styles.showwra)}>
                                        <div
                                            className={classnames(
                                                styles.showbg
                                            )}
                                            onClick={handleGreenFlagClick}
                                            id="goStart"
                                        ></div>
                                        <div
                                            style={{
                                                color: "#fff",
                                                margin: "0 auto",
                                            }}
                                        >
                                            点击运行
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {goMobileTerminal == 1 && (
                            <MobileKeyboard vm={vm} blocks={_blocks} />
                        )}
                    </Box>
                ) : (
                    <Box
                        className={styles.pageWrapper}
                        dir={isRtl ? "rtl" : "ltr"}
                        {...componentProps}
                    >
                        {telemetryModalVisible ? (
                            <TelemetryModal
                                onCancel={onTelemetryModalCancel}
                                onOptIn={onTelemetryModalOptIn}
                                onOptOut={onTelemetryModalOptOut}
                                onRequestClose={onRequestCloseTelemetryModal}
                                onShowPrivacyPolicy={onShowPrivacyPolicy}
                            />
                        ) : null}
                        {loading ? <Loader /> : null}
                        {isCreating ? (
                            <Loader messageId="gui.loader.creating" />
                        ) : null}
                        {isRendererSupported ? null : (
                            <WebGlModal isRtl={isRtl} />
                        )}
                        {tipsLibraryVisible ? <TipsLibrary /> : null}
                        {cardsVisible ? <Cards /> : null}
                        {alertsVisible ? (
                            <Alerts className={styles.alertsContainer} />
                        ) : null}
                        {connectionModalVisible ? (
                            <ConnectionModal vm={vm} />
                        ) : null}
                        {costumeLibraryVisible ? (
                            <CostumeLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseCostumeLibrary}
                            />
                        ) : null}
                        {backdropLibraryVisible ? (
                            <BackdropLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseBackdropLibrary}
                            />
                        ) : null}
                        <MenuBar
                            accountNavOpen={accountNavOpen}
                            authorId={authorId}
                            authorThumbnailUrl={authorThumbnailUrl}
                            authorUsername={authorUsername}
                            canChangeLanguage={canChangeLanguage}
                            canCreateCopy={canCreateCopy}
                            canCreateNew={canCreateNew}
                            canEditTitle={canEditTitle}
                            canManageFiles={canManageFiles}
                            canRemix={canRemix}
                            canSave={canSave}
                            canShare={canShare}
                            className={styles.menuBarPosition}
                            enableCommunity={enableCommunity}
                            isShared={isShared}
                            logo={logo}
                            renderLogin={renderLogin}
                            showComingSoon={showComingSoon}
                            onClickAbout={onClickAbout}
                            onClickAccountNav={onClickAccountNav}
                            onClickLogo={onClickLogo}
                            onCloseAccountNav={onCloseAccountNav}
                            onLogOut={onLogOut}
                            onOpenRegistration={onOpenRegistration}
                            onProjectTelemetryEvent={onProjectTelemetryEvent}
                            onSeeCommunity={onSeeCommunity}
                            onShare={onShare}
                            onToggleLoginOpen={onToggleLoginOpen}
                        />
                        <Box
                            className={classnames(
                                styles.bodyWrapper,
                                styles.fullHeight
                            )}
                        >
                            <Box className={styles.flexWrapper}>
                                <Box className={styles.editorWrapper}>
                                    <Tabs
                                        forceRenderTabPanel
                                        className={tabClassNames.tabs}
                                        selectedIndex={activeTabIndex}
                                        selectedTabClassName={
                                            tabClassNames.tabSelected
                                        }
                                        selectedTabPanelClassName={
                                            tabClassNames.tabPanelSelected
                                        }
                                        onSelect={onActivateTab}
                                    >
                                        <TabList
                                            className={tabClassNames.tabList}
                                        >
                                            <Tab className={tabClassNames.tab}>
                                                <img
                                                    draggable={false}
                                                    src={codeIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage="Code"
                                                    description="Button to get to the code panel"
                                                    id="gui.gui.codeTab"
                                                />
                                            </Tab>
                                            <Tab
                                                className={tabClassNames.tab}
                                                onClick={onActivateCostumesTab}
                                            >
                                                <img
                                                    draggable={false}
                                                    src={costumesIcon}
                                                />
                                                {targetIsStage ? (
                                                    <FormattedMessage
                                                        defaultMessage="Backdrops"
                                                        description="Button to get to the backdrops panel"
                                                        id="gui.gui.backdropsTab"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage="Costumes"
                                                        description="Button to get to the costumes panel"
                                                        id="gui.gui.costumesTab"
                                                    />
                                                )}
                                            </Tab>
                                            <Tab
                                                className={tabClassNames.tab}
                                                onClick={onActivateSoundsTab}
                                            >
                                                <img
                                                    draggable={false}
                                                    src={soundsIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage="Sounds"
                                                    description="Button to get to the sounds panel"
                                                    id="gui.gui.soundsTab"
                                                />
                                            </Tab>
                                            <div className={styles.btnGroup}>
                                                <div>
                                                    {from === "homework" && (
                                                        <AntdButton
                                                            className={
                                                                styles.answerBtn
                                                            }
                                                            onClick={
                                                                handleOnlineQA
                                                            }
                                                        >
                                                            <img
                                                                width={32}
                                                                height={32}
                                                                style={{
                                                                    marginRight: 6,
                                                                }}
                                                                src={
                                                                    iconService
                                                                }
                                                                alt="iconService"
                                                            />
                                                            <span>
                                                                在线答疑
                                                            </span>
                                                        </AntdButton>
                                                    )}
                                                    {from === "homework" ||
                                                    from === "practise" ? (
                                                        <>
                                                            <AntdButton
                                                                className={
                                                                    styles.comBtn
                                                                }
                                                                onClick={
                                                                    handleTask
                                                                }
                                                            >
                                                                <img
                                                                    width={32}
                                                                    height={32}
                                                                    style={{
                                                                        marginRight: 6,
                                                                    }}
                                                                    src={
                                                                        iconTask
                                                                    }
                                                                    alt="iconTask"
                                                                />
                                                                <span>
                                                                    任务
                                                                </span>
                                                            </AntdButton>
                                                            <AntdButton
                                                                className={
                                                                    styles.comBtn
                                                                }
                                                                onClick={
                                                                    handlePrompt
                                                                }
                                                            >
                                                                <img
                                                                    width={32}
                                                                    height={32}
                                                                    style={{
                                                                        marginRight: 6,
                                                                    }}
                                                                    src={
                                                                        iconPrompt
                                                                    }
                                                                    alt="iconPrompt"
                                                                />
                                                                <span>
                                                                    提示
                                                                </span>
                                                            </AntdButton>
                                                        </>
                                                    ) : null}
                                                </div>
                                                {from === "course" && (
                                                    <div>
                                                        <DeviceModal />
                                                        <AntdButton
                                                            className={classnames(
                                                                styles.comBtn,
                                                                styles.resetBtn
                                                            )}
                                                            onClick={() =>
                                                                onResetProject(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            重置
                                                        </AntdButton>
                                                        <SaveSb3File />
                                                        {/* <AntdButton
                                                        className={classnames(
                                                            styles.comBtn,
                                                            styles.resetBtn
                                                        )}
                                                        onClick={() =>
                                                            onResetProject(true)
                                                        }
                                                    >
                                                        保存
                                                    </AntdButton> */}
                                                    </div>
                                                )}
                                            </div>
                                        </TabList>

                                        <TabPanel
                                            className={tabClassNames.tabPanel}
                                        >
                                            <Box
                                                className={styles.blocksWrapper}
                                            >
                                                <Blocks
                                                    canUseCloud={canUseCloud}
                                                    grow={1}
                                                    isVisible={blocksTabVisible}
                                                    options={{
                                                        media: `${basePath}static/blocks-media/`,
                                                    }}
                                                    stageSize={stageSize}
                                                    vm={vm}
                                                />
                                            </Box>
                                            <Box
                                                className={
                                                    styles.extensionButtonContainer
                                                }
                                            >
                                                <button
                                                    className={
                                                        styles.extensionButton
                                                    }
                                                    title={intl.formatMessage(
                                                        messages.addExtension
                                                    )}
                                                    onClick={
                                                        onExtensionButtonClick
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            styles.extensionButtonIcon
                                                        }
                                                        draggable={false}
                                                        src={addExtensionIcon}
                                                    />
                                                </button>
                                            </Box>
                                            <Box className={styles.watermark}>
                                                <Watermark />
                                            </Box>
                                        </TabPanel>
                                        <TabPanel
                                            className={tabClassNames.tabPanel}
                                        >
                                            {costumesTabVisible ? (
                                                <CostumeTab vm={vm} />
                                            ) : null}
                                        </TabPanel>
                                        <TabPanel
                                            className={tabClassNames.tabPanel}
                                        >
                                            {soundsTabVisible ? (
                                                <SoundTab vm={vm} />
                                            ) : null}
                                        </TabPanel>
                                    </Tabs>
                                    {/* 书包 */}
                                    {backpackVisible ? (
                                        <Backpack host={backpackHost} />
                                    ) : null}
                                </Box>

                                <Box
                                    className={classNames(
                                        styles.stageAndTargetWrapper,
                                        styles[stageSize]
                                    )}
                                >
                                    {/* 舞台区 */}
                                    <StageWrapper
                                        isFullScreen={isFullScreen}
                                        isRendererSupported={
                                            isRendererSupported
                                        }
                                        isRtl={isRtl}
                                        stageSize={stageSize}
                                        vm={vm}
                                    />
                                    <Box className={styles.targetWrapper}>
                                        <TargetPane
                                            stageSize={stageSize}
                                            vm={vm}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <DragLayer />
                    </Box>
                );
            }}
        </MediaQuery>
    );
};

GUIComponent.propTypes = {
    accountNavOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    backdropLibraryVisible: PropTypes.bool,
    backpackHost: PropTypes.string,
    backpackVisible: PropTypes.bool,
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    cardsVisible: PropTypes.bool,
    children: PropTypes.node,
    costumeLibraryVisible: PropTypes.bool,
    costumesTabVisible: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isResetProject: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    loading: PropTypes.bool,
    logo: PropTypes.string,
    onActivateCostumesTab: PropTypes.func,
    onActivateSoundsTab: PropTypes.func,
    onActivateTab: PropTypes.func,
    onClickAbout: PropTypes.func,
    onClickAccountNav: PropTypes.func,
    onClickLogo: PropTypes.func,
    onCloseAccountNav: PropTypes.func,
    onExtensionButtonClick: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onRequestCloseBackdropLibrary: PropTypes.func,
    onRequestCloseCostumeLibrary: PropTypes.func,
    onRequestCloseTelemetryModal: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onShowPrivacyPolicy: PropTypes.func,
    onResetProject: PropTypes.func,
    onTabSelect: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    renderLogin: PropTypes.func,
    showComingSoon: PropTypes.bool,
    soundsTabVisible: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    hideMenu: PropTypes.bool,
    targetIsStage: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    tipsLibraryVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    onSetStageFull: PropTypes.func,
    turbo: PropTypes.bool.isRequired,
    isStarted: PropTypes.bool.isRequired,
    onKeyPress: PropTypes.func,
    url: PropTypes.string,
    evesetSpriteJson: PropTypes.func,
    evesetCostumesJson: PropTypes.func,
    evesetBackdropsJson: PropTypes.func,
    evesetSoundsJson: PropTypes.func,
    onClickFile: PropTypes.func,
};
GUIComponent.defaultProps = {
    backpackHost: null,
    backpackVisible: false,
    basePath: "./",
    canChangeLanguage: true,
    canCreateNew: false,
    canEditTitle: false,
    canManageFiles: true,
    canRemix: false,
    canSave: false,
    canCreateCopy: false,
    canShare: false,
    canUseCloud: false,
    enableCommunity: false,
    isCreating: false,
    isShared: false,
    loading: false,
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large,
    hideMenu: false,
};
const mapDispatchToProps = (dispatch) => ({
    onSetStageFull: () => dispatch(setFullScreen(true)),
    evesetSpriteJson: (status) => dispatch(setSpriteJson(status)),
    evesetCostumesJson: (status) => dispatch(setCostumesJson(status)),
    evesetBackdropsJson: (status) => dispatch(setBackdropsJson(status)),
    evesetSoundsJson: (status) => dispatch(setSoundsJson(status)),
    onClickFile: () => dispatch(openFileMenu()),
});
const mapStateToProps = (state) => {
    return {
        stageSizeMode: state.scratchGui.stageSize.stageSize,
        hideMenu: state.scratchGui.menus.isHideMenu,
        turbo: state.scratchGui.vmStatus.turbo,
        isStarted: state.scratchGui.vmStatus.running,
    };
};
// const mapStateToProps = (state) => {
//     return {
//         // This is the button's mode, as opposed to the actual current state
//         url: asset && asset.encodeDataURI(),
//         stageSizeMode: state.scratchGui.stageSize.stageSize,
//         hideMenu: state.scratchGui.menus.isHideMenu,
//         turbo: state.scratchGui.vmStatus.turbo,
//         isStarted: state.scratchGui.vmStatus.running,
//     };
// };

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(GUIComponent)
);
