import cookies from "js-cookie";

const LOGIN_MODAL_SHOW = "scratch-gui/mode/LOGIN_MODAL_SHOW";
const SCRTACH_TOKEN = "scratch-gui/mode/SCRTACH_TOKEN";
const PORTRAIT_URL = "scratch-gui/mode/PORTRAIT_URL";
const REQUEST_USER_INFO = "scratch-gui/mode/REQUEST_USER_INFO";
const CHANGE_USERNAME = "scratch-gui/mode/CHANGE_USERNAME";
const CHANGE_PASSWORD = "scratch-gui/mode/CHANGE_PASSWORD";
const DRAFT_TITLE = "scratch-gui/mode/DRAFT_TITLE";
const PUBLISH_DRAW_SHOW = "scratch-gui/mode/PUBLISH_DRAW_SHOW";
const DRAFT_LIST_SHOW = "scratch-gui/mode/DRAFT_LIST_SHOW";
const DRAFT_DATA_LIST = "scratch-gui/mode/DRAFT_DATA_LIST";
const DRAFT_TOTAL_DATA = "scratch-gui/mode/DRAFT_TOTAL_DATA";
const PRE_SHARE_SHOW = "scratch-gui/mode/PRE_SHARE_SHOW";
const SHARE_SHOW = "scratch-gui/mode/SHARE_SHOW";
const SHARE_TITLE = "scratch-gui/mode/SHARE_TITLE";
const SHARE_DESC = "scratch-gui/mode/SHARE_DESC";
const SHARE_ID = "scratch-gui/mode/SHARE_ID";
const PUBLISH_CODE = "scratch-gui/mode/PUBLISH_CODE";
const DRAFT_ID = "scratch-gui/mode/DRAFT_ID";
const CANVAS_URL = "scratch-gui/mode/CANVAS_URL";
const WORK_LOAD_END = "scratch-gui/mode/WORK_LOAD_END";
const SPRITE_JSON = "scratch-gui/mode/SPRITE_JSON";
const COSTOMES_JSON = "scratch-gui/mode/COSTOMES_JSON";
const BACKDROP_JSON = "scratch-gui/mode/BACKDROP_JSON";
const SOUND_JSON = "scratch-gui/mode/SOUND_JSON";

const initialState = {
    loginModalShow: false,
    token: cookies.get("token") || null, //用户认证令牌
    expire: null, //过期时间
    needChangePass: null, //是否需要更新密码 1.需要, 0.不需要
    userInfo: null, //个人所有信息
    mobile: null, //手机号
    id: null, //用户id
    username: null,
    password: null,
    nickname: null, //用户的nickname
    mobile: null, //用户手机号码
    email: null, //用户邮箱
    gender: null, //性别
    portraitUrl: null, //头像url

    draftTitle: null, //草稿名称
    publishDrawShow: false, //发布草稿时的draw显示与否
    draftListShow: false, //草稿列表modal显示与否
    draftDataList: [], //草稿list
    draftTotalData: null, //草稿总数据
    PreShareShow: false, //分享预览页面modal显示与否
    ShareShow: false, //分享页面modal显示与否
    shareTitle: "", //分享标题
    shareDesc: "", //分享时的描述
    shareId: "", //分享后的id
    publishCode: "", //上传到server的sb3文件url
    draftId: null, //保存草稿后生成的id
    canvasUrl: null, //id为stage-canvers的canvas的图片url
    isWorkLoadingEnd: true, //作品是否加载结束
    spriteJson: [], //角色json
    costumesJson: [], //造型json
    backdropsJson: [], //背景json
    soundsJson: [], //声音json
};

const reducer = function (state, action) {
    if (typeof state === "undefined") state = initialState;
    switch (action.type) {
        case LOGIN_MODAL_SHOW:
            return Object.assign({}, state, {
                loginModalShow: action.loginModalShow,
            });
        case SCRTACH_TOKEN:
            return Object.assign({}, state, {
                token: action.token,
            });
        case PORTRAIT_URL:
            return Object.assign({}, state, {
                portraitUrl: action.portraitUrl,
            });
        case REQUEST_USER_INFO:
            return Object.assign({}, state, {
                userInfo: action.userInfo,
                // hasEverEnteredEditor:
                //     state.hasEverEnteredEditor || !action.isPlayerOnly,
            });
        case CHANGE_USERNAME:
            return Object.assign({}, state, {
                username: action.username,
            });
        case CHANGE_PASSWORD:
            return Object.assign({}, state, {
                password: action.password,
            });
        case DRAFT_TITLE:
            return Object.assign({}, state, {
                draftTitle: action.draftTitle,
            });
        case PUBLISH_DRAW_SHOW:
            return Object.assign({}, state, {
                publishDrawShow: action.publishDrawShow,
            });
        case DRAFT_LIST_SHOW:
            return Object.assign({}, state, {
                draftListShow: action.draftListShow,
            });
        case DRAFT_DATA_LIST:
            return Object.assign({}, state, {
                draftDataList: action.draftDataList,
            });
        case DRAFT_TOTAL_DATA:
            return Object.assign({}, state, {
                draftTotalData: action.draftTotalData,
            });
        case PRE_SHARE_SHOW:
            return Object.assign({}, state, {
                PreShareShow: action.PreShareShow,
            });
        case SHARE_SHOW:
            return Object.assign({}, state, {
                ShareShow: action.ShareShow,
            });
        case SHARE_TITLE:
            return Object.assign({}, state, {
                shareTitle: action.shareTitle,
            });
        case SHARE_DESC:
            return Object.assign({}, state, {
                shareDesc: action.shareDesc,
            });
        case SHARE_ID:
            return Object.assign({}, state, {
                shareId: action.shareId,
            });
        case PUBLISH_CODE:
            return Object.assign({}, state, {
                publishCode: action.publishCode,
            });
        case DRAFT_ID:
            return Object.assign({}, state, {
                draftId: action.draftId,
            });
        case CANVAS_URL:
            return Object.assign({}, state, {
                canvasUrl: action.canvasUrl,
            });
        case WORK_LOAD_END:
            return Object.assign({}, state, {
                isWorkLoadingEnd: action.isWorkLoadingEnd,
            });
        case SPRITE_JSON:
            return Object.assign({}, state, {
                spriteJson: action.spriteJson,
            });
        case COSTOMES_JSON:
            return Object.assign({}, state, {
                costumesJson: action.costumesJson,
            });
        case BACKDROP_JSON:
            return Object.assign({}, state, {
                backdropsJson: action.backdropsJson,
            });
        case SOUND_JSON:
            return Object.assign({}, state, {
                soundsJson: action.soundsJson,
            });
        default:
            return state;
    }
};

const setLoginModalShow = function (loginModalShow) {
    return {
        type: LOGIN_MODAL_SHOW,
        loginModalShow: loginModalShow,
    };
};
const setPortraitUrl = function (portraitUrl) {
    return {
        type: PORTRAIT_URL,
        portraitUrl: portraitUrl,
    };
};
const setToken = function (token) {
    return {
        type: SCRTACH_TOKEN,
        token: token,
    };
};
const setUserInfo = function (userInfo) {
    return {
        type: REQUEST_USER_INFO,
        userInfo: userInfo,
    };
};
const changeUserName = function (username) {
    return {
        type: CHANGE_USERNAME,
        username: username,
    };
};
const changePassWord = function (password) {
    return {
        type: CHANGE_PASSWORD,
        password: password,
    };
};
const changeDraftTitle = function (draftTitle) {
    return {
        type: DRAFT_TITLE,
        draftTitle: draftTitle,
    };
};
const setpublishDrawShow = function (publishDrawShow) {
    return {
        type: PUBLISH_DRAW_SHOW,
        publishDrawShow: publishDrawShow,
    };
};
const setDradtListShow = function (draftListShow) {
    return {
        type: DRAFT_LIST_SHOW,
        draftListShow: draftListShow,
    };
};
const setDataList = function (draftDataList) {
    return {
        type: DRAFT_DATA_LIST,
        draftDataList: draftDataList,
    };
};
const setTotalData = function (draftTotalData) {
    return {
        type: DRAFT_TOTAL_DATA,
        draftTotalData: draftTotalData,
    };
};
const setPreShareShow = function (PreShareShow) {
    return {
        type: PRE_SHARE_SHOW,
        PreShareShow: PreShareShow,
    };
};
const setShareShow = function (ShareShow) {
    return {
        type: SHARE_SHOW,
        ShareShow: ShareShow,
    };
};
const setShareTitle = function (shareTitle) {
    return {
        type: SHARE_TITLE,
        shareTitle: shareTitle,
    };
};
const setShareDesc = function (shareDesc) {
    return {
        type: SHARE_DESC,
        shareDesc: shareDesc,
    };
};
const setShareId = function (shareId) {
    return {
        type: SHARE_ID,
        shareId: shareId,
    };
};
const setPublishCode = function (publishCode) {
    return {
        type: PUBLISH_CODE,
        publishCode: publishCode,
    };
};
const setDraftId = function (draftId) {
    return {
        type: DRAFT_ID,
        draftId: draftId,
    };
};
const setCanvasUrl = function (canvasUrl) {
    return {
        type: CANVAS_URL,
        canvasUrl: canvasUrl,
    };
};
const setIsWorkLoadingEnd = function (isWorkLoadingEnd) {
    return {
        type: WORK_LOAD_END,
        isWorkLoadingEnd: isWorkLoadingEnd,
    };
};
const setSpriteJson = function (spriteJson) {
    return {
        type: SPRITE_JSON,
        spriteJson: spriteJson,
    };
};
const setCostumesJson = function (costumesJson) {
    return {
        type: COSTOMES_JSON,
        costumesJson: costumesJson,
    };
};
const setBackdropsJson = function (backdropsJson) {
    return {
        type: BACKDROP_JSON,
        backdropsJson: backdropsJson,
    };
};
const setSoundsJson = function (soundsJson) {
    return {
        type: SOUND_JSON,
        soundsJson: soundsJson,
    };
};
export {
    reducer as default,
    initialState as loginInitialState,
    setLoginModalShow,
    setToken,
    setPortraitUrl,
    setUserInfo,
    changeUserName,
    changePassWord,
    changeDraftTitle,
    setpublishDrawShow,
    setDradtListShow,
    setDataList,
    setTotalData,
    setPreShareShow,
    setShareShow,
    setShareTitle,
    setShareDesc,
    setShareId,
    setPublishCode,
    setDraftId,
    setCanvasUrl,
    setIsWorkLoadingEnd,
    setSpriteJson,
    setCostumesJson,
    setBackdropsJson,
    setSoundsJson,
};
