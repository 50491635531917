import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
// import { message } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import styles from "./works-upload.css";
import Input from "../forms/input.jsx";
// import enterIcon from "./ic_save.svg";
import cookies from "js-cookie";
import SaveSb3File from "../../containers/save-sb3file.jsx";
import {
    setLoginModalShow,
    changeDraftTitle,
    setpublishDrawShow,
} from "../../reducers/login";
import { generateUUID } from "../../utils/index";
import { cosUploadFile } from "../../utils/cosUploadFile";

class WorksComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "valueChange",
            "getCanvas",
            "canvasSort",
            "blobToFile",
            "dataURLtoBlob",
        ]);
        this.state = {
            inputVal: "",
            ib64: "",
        };
    }
    componentWillReceiveProps(nextProps) {
        console.log("更新后的数据55555？", this.props, nextProps);
        this.setState({
            inputVal:
                nextProps.draftTitle && nextProps.draftTitle !== ""
                    ? nextProps.draftTitle
                    : sessionStorage.getItem("DRAFT_TITLE") &&
                      sessionStorage.getItem("DRAFT_TITLE") !== "" &&
                      sessionStorage.getItem("DRAFT_TITLE") !== "null"
                    ? sessionStorage.getItem("DRAFT_TITLE")
                    : "",
        });
    }
    componentWillMount() {
        console.log("更新后的数据66666？", this.props);
        this.setState({
            inputVal:
                this.props.draftTitle &&
                this.props.draftTitle !== "" &&
                this.props.draftTitle !== "null"
                    ? this.props.draftTitle
                    : sessionStorage.getItem("DRAFT_TITLE") &&
                      sessionStorage.getItem("DRAFT_TITLE") !== "" &&
                      sessionStorage.getItem("DRAFT_TITLE") !== "null"
                    ? sessionStorage.getItem("DRAFT_TITLE")
                    : "",
        });
    }
    valueChange(e) {
        console.log(33, e.target.value, this.props);
        this.setState({ inputVal: e.target.value });
        cookies.set("DRAFT_TITLE", e.target.value);
        this.props.dispatchDraftTitle(e.target.value);
        sessionStorage.setItem("DRAFT_TITLE", e.target.value);
    }
    getCanvas() {
        let allCanvas = document.getElementsByTagName("canvas");
        if (allCanvas.length > 0) {
            let resCanvas = document.createElement("canvas");
            // allCanvas.map((item) => {
            //     if(item)
            // });
            let tmpCanvas = allCanvas[0]; //allCanvas[allCanvas.length - 1];
            const width = tmpCanvas.width;
            const height = tmpCanvas.height;
            resCanvas.width = width;
            resCanvas.height = height;
            allCanvas = Array.from(allCanvas).sort(this.canvasSort);
            let ctx = resCanvas.getContext("2d");
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, resCanvas.width, resCanvas.height);
            for (let i = 0; i < allCanvas.length - 1; i++) {
                ctx.drawImage(allCanvas[i], 0, 0);
            }
            console.log(132, allCanvas, tmpCanvas, ctx, resCanvas);
            let imageData = ctx.getImageData(
                0,
                0,
                resCanvas.width,
                resCanvas.height
            );
            ctx.putImageData(imageData, 0, 0);
            let ib64 = resCanvas.toDataURL("image/png");
            console.log(243, allCanvas, tmpCanvas, height);
            this.setState({
                ib64: ib64,
            });
            let blob = this.dataURLtoBlob(ib64);
            let file = this.blobToFile(blob, generateUUID());
            // console.log(6666666, file);
            const uuid = generateUUID();
            // let file = new window.File([blob], "fm", {
            //     type: blob.type,
            //     lastModified: Date.now(),
            // });
            console.log(66667, blob, file);
            // cosUploadFile({
            //     // type: "user_scratch",
            //     file: { data: file, filename: "fm" },
            //     uuid: uuid,
            //     upSucceed: (res) => {
            //         console.log(7777777779, res);
            //     },
            //     upProgress: (res) => {
            //         console.log(8888889, res.url);
            //     },
            // });
            cosUploadFile({
                file: { data: file, filename: "fm" },
                upProgress: (info) => {},
                upSucceed: (res) => {
                    console.log(77777777798, res);
                },
                upProgress: (res) => {
                    console.log(8888889, res.url);
                },
            });
            //  setImageBase64(ib64);
        }
    }
    canvasSort(a, b) {
        return a.style.zIndex - b.style.zIndex;
    }

    //将blob转换为file
    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
    dataURLtoBlob(dataurl) {
        let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
    render() {
        const {
            // answer,
            className,
            question,
            // onChange,
            // onClick,
            // onKeyPress,
            // draftTitle,
            // onClick1,
        } = this.props;
        const { inputVal } = this.state;
        return (
            <div className={className}>
                <div className={styles.questionContainer}>
                    {question ? (
                        <div className={styles.questionLabel}>{question}</div>
                    ) : null}
                    <div className={styles.questionInput}>
                        <Input
                            autoFocus
                            // value={draftTitle ? draftTitle : inputVal}
                            value={inputVal}
                            onChange={this.valueChange}
                            // onKeyPress={this.inputKeyPress}
                            placeholder="请输入草稿名称"
                        />
                        <SaveSb3File type={3} draftTitle={inputVal} />
                        <SaveSb3File type={5} draftTitle={inputVal} />
                    </div>
                </div>
                {/* <canvas id="myCanvas"></canvas>
                <img
                    src={this.props.ib64}
                    alt=""
                    style={{ width: "100px", height: "100px" }}
                /> */}
            </div>
        );
    }
}

WorksComponent.propTypes = {
    answer: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    question: PropTypes.string,

    onSeeCommunity1: PropTypes.func,
    drawShowEve: PropTypes.func,
    dispatchDraftTitle: PropTypes.func,
    ib64: PropTypes.string,
};

// export default WorksComponent;

const mapStateToProps = (state) => {
    return {
        hideMenu: state.scratchGui.login.login,
        draftTitle: state.scratchGui.login.draftTitle,
        draftId: state.scratchGui.login.draftId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    drawShowEve: () => dispatch(setpublishDrawShow(true)),
    dispatchDraftTitle: (res) => dispatch(changeDraftTitle(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(WorksComponent);
