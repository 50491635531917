const CONNECT_ROBOT = "scratch-gui/customData/CONNECT_ROBOT";

const initialState = {
    robotinfo: null,
};

const reducer = function (state, action) {
    if (typeof state === "undefined") state = initialState;
    switch (action.type) {
        case CONNECT_ROBOT:
            return Object.assign({}, state, {
                robotinfo: action.robotinfo,
            });
        default:
            return state;
    }
};

/**
 * Action creator to close the custom procedures modal.
 * @param {?Element} mutator The new mutator, or null if the callback should not be called.
 * @returns {object} An action object with type ACTIVATE_CUSTOM_PROCEDURES.
 */
const currentConnectrobotinfo = (robotinfo) => ({
    type: CONNECT_ROBOT,
    robotinfo: robotinfo,
});

export {
    reducer as default,
    initialState as customDataInitialState,
    currentConnectrobotinfo,
};
