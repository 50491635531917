import { apiBaseUrl } from "./apiBase";
import React from "react";
import axios from "axios"; // 引入axios和定义在node_modules/axios/index.ts文件里的类型声明
import queryString from "query-string";
import { generateUUID, domainToken } from "../../utils";
import cookies from "js-cookie";
import { logout } from "../apiServer/apiCommon";
import { Modal } from "antd";
const sha1 = require("sha1");
const { api_base_url } = apiBaseUrl;

const { token } = queryString.parse(location.search);
// // const tokenScratch = cookies.get("SCRATCH_TOKEN");
// console.log("获取token", cookies.get("SCRATCH_TOKEN"));
// const defOptions = {
//     baseURL: api_base_url,
//     timeout: 15000,
//     withCredentials: false,
//     headers: {
//         "Access-Control-Allow-Origin": "*",
//         token: token || cookies.get("SCRATCH_TOKEN") || "",
//     },
// };

// 定义请求类
class HttpRequest {
    // 定义一个接口请求类，用于创建一个axios请求实例
    constructor(baseUrl = "") {
        // 这个类接收一个字符串参数，是接口请求的基本路径
        this.baseUrl = baseUrl;
    }
    // 我们实际调用接口的时候调用实例的这个方法，他返回一个AxiosPromise
    request(options) {
        const instance = axios.create(); // 这里使用axios.create方法创建一个axios实例，他是一个函数，同时这个函数包含多个属性
        // const { token } = queryString.parse(location.search);
        // const tokenScratch = cookies.get("SCRATCH_TOKEN");
        const defOptions = {
            baseURL: api_base_url,
            timeout: 15000,
            withCredentials: false,
            headers: {
                "Access-Control-Allow-Origin": "*",
                token: token || cookies.get("token") || "",
            },
        };
        options = this.mergeConfig(defOptions, options); // 合并基础路径和每个接口单独传入的配置，比如url、参数等
        this.interceptors(instance, options.url); // 调用interceptors方法使拦截器生效
        return instance(options); // 最后返回AxiosPromise
    }
    // 定义这个函数用于添加全局请求和响应拦截逻辑
    interceptors(instance) {
        // 在这里添加请求拦截
        instance.interceptors.request.use(
            (config) => {
                // 接口请求的所有配置，都在这个config对象中，他的类型是AxiosRequestConfig，你可以看到他有哪些字段
                // 如果你要修改接口请求配置，需要修改 axios.defaults 上的字段值

                // 添加token
                // config.headers.Authorization = `Bearer ${Cookies.get(
                //   process.env.REACT_APP_TOKEN as string
                // )}`;
                // 签名算法
                let _headers = [],
                    signToken = "766a6afe879b44bd9c5c7518746bd0e4",
                    timestamp = Date.now().toString(),
                    nonce = generateUUID();
                _headers.push(signToken, timestamp, nonce);
                let shaStr = sha1(_headers.sort().join(""));
                // 接口请求的所有配置，都在这个config对象中，他的类型是AxiosRequestConfig，你可以看到他有哪些字段
                // 如果你要修改接口请求配置，需要修改 axios.defaults 上的字段值
                config.headers.timestamp = timestamp;
                config.headers.nonce = nonce;
                config.headers.signature = shaStr;
                config.headers.token = config.headers.token
                    ? config.headers.token
                    : token || "";
                return config;
            },
            (error) => Promise.reject(error)
        );
        // 在这里添加响应拦截
        instance.interceptors.response.use(
            (res) => {
                const { data } = res; // res的类型是AxiosResponse<any>，包含六个字段，其中data是服务端返回的数据
                const { code, msg } = data; // 通常服务端会将响应状态码、提示信息、数据等放到返回的数据中
                // if (code !== 0) {
                //     // 这里我们在服务端将正确返回的状态码标为0
                //     console.error(msg); // 如果不是0，则打印错误信息，我们后面讲到UI组件的时候，这里可以使用消息窗提示
                // }
                if (
                    code === 36 ||
                    code === 25 ||
                    msg === "登录凭证无效或已过期"
                ) {
                    // 36登录已经过期，双重保证 25账号被踢出
                    // 需要清除cookies;
                    let getCookie = cookies.get();
                    for (let p in getCookie) {
                        cookies.remove(`${p}`);
                    }
                    domainToken("token").remove();
                    // domainToken("PLAY_TOKEN").remove();
                    // domainToken("SCRATCH_TOKEN").remove();
                    // sessionStorage.removeItem("PLAY_TOKEN");
                    sessionStorage.removeItem("token"); //SCRATCH_TOKEN
                    sessionStorage.removeItem("draftId");
                    sessionStorage.removeItem("sourceType");
                    // window.location.replace(
                    //     window.location.origin + window.location.pathname
                    // );
                    // location.href = `/login?redirectUrl=${encodeURIComponent(
                    //     Router.asPath
                    // )}`;
                } else if (code === 300 || code === 30) {
                    /* 访问页面 权限不足 */
                    location.href = "/";
                } else if (code === 35) {
                    Modal.info({
                        title: "下线通知",
                        content: (
                            <div>
                                账号已在另一地点登录，你的账号被迫下线。
                                <br />
                                若非本人操作，建议重新登录账号并修改密码。
                            </div>
                        ),
                        okText: "重新登录",
                        onOk() {
                            // this.props.modalShowEve1();
                            logout().then(({ data }) => {});
                            //清空所有cookie
                            let getCookie = cookies.get();
                            for (let p in getCookie) {
                                cookies.remove(`${p}`);
                            }
                            cookies.remove("token"); //SCRATCH_TOKEN
                            sessionStorage.removeItem("token"); //SCRATCH_TOKEN
                            // cookies.remove("PLAY_TOKEN");
                            // sessionStorage.removeItem("PLAY_TOKEN");
                            sessionStorage.removeItem("DRAFT_TITLE");
                            sessionStorage.removeItem("draftId");
                            sessionStorage.removeItem("sourceType");
                            sessionStorage.removeItem("shareId");
                            window.location.replace(
                                window.location.origin +
                                    window.location.pathname
                            );
                        },
                    });
                }
                return res; // 返回数据
            },
            (error) => {
                // 这里是遇到报错的回调
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            error.message = "错误请求";
                            // message.error('错误请求');
                            break;
                        case 401:
                            error.message = "未授权，请重新登录";
                            // message.error('未授权，请重新登录');
                            break;
                        case 403:
                            error.message = "拒绝访问";
                            // message.error('拒绝访问');
                            break;
                        case 404:
                            error.message = "请求错误,未找到该资源";
                            // message.error('请求错误,未找到该资源');
                            break;
                        case 405:
                            error.message = "请求方法未允许";
                            // message.error('请求方法未允许');
                            break;
                        case 408:
                            error.message = "请求超时";
                            // message.error('请求超时');
                            break;
                        case 500:
                            error.message = "服务器端出错";
                            // message.error('服务器端出错');
                            break;
                        case 501:
                            error.message = "网络未实现";
                            // message.error('网络未实现');
                            break;
                        case 502:
                            error.message = "网络错误";
                            // message.error('网络错误');
                            break;
                        case 503:
                            error.message = "服务不可用";
                            // message.error('服务不可用');
                            break;
                        case 504:
                            error.message = "网络超时";
                            // message.error('网络超时');
                            break;
                        case 505:
                            error.message = "http版本不支持该请求";
                            // message.error('http版本不支持该请求');
                            break;
                        default:
                            error.message = `连接错误${error.response.status}`;
                        // message.error(`连接错误${error.response.status}`);
                    }
                } else {
                    // message.error('服务器未响应');
                }
                return Promise.reject(error);
            }
        );
    }
    mergeConfig(defOpt, options) {
        // 这个方法用于合并基础路径配置和接口单独配置
        return Object.assign(defOpt, options);
    }
}

export default new HttpRequest();
