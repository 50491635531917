import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import {
    setLoginModalShow,
    setDradtListShow,
    setDataList,
    setTotalData,
    setPreShareShow,
} from "../../reducers/login";
import styles from "./works-upload.css";
import cookies from "js-cookie";
import { Dropdown } from "antd";
import { logout, queryGetDraftList } from "../../api/apiServer/apiCommon";
import headDefault from "../../assets/img/head-default@2x.jpg";

class WorksComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["loginout", "goDl", "openDraft", "openShare"]);
        // "handleChange", "handleKeyPress", "handleSubmit";
        this.state = {
            res: {
                keywords: "",
                pageNum: 1,
                pageSize: 6,
                type: [2],
            },
            token: null,
        };
    }
    // componentWillUpdate(e) {
    //     console.log("即将更新", e);
    // }
    componentWillReceiveProps(newProps) {
        if (newProps.token) {
            this.setState({
                token: newProps.token,
            });
        }
        console.log("新植3666", newProps, this.props);
    }
    goDl() {
        console.log("等等", this.props);
        this.props.onSeeCommunity1();
    }
    loginout() {
        console.log("退出");
        logout().then(({ data }) => {});
        //清空所有cookie
        let getCookie = cookies.get();
        for (let p in getCookie) {
            cookies.remove(`${p}`);
        }
        cookies.remove("token"); //SCRATCH_TOKEN
        sessionStorage.removeItem("token"); //SCRATCH_TOKEN
        // cookies.remove("PLAY_TOKEN");
        // sessionStorage.removeItem("PLAY_TOKEN");
        sessionStorage.removeItem("DRAFT_TITLE");
        sessionStorage.removeItem("draftId");
        sessionStorage.removeItem("sourceType");
        sessionStorage.removeItem("shareId");
        window.location.replace(
            window.location.origin + window.location.pathname
        );
    }
    openDraft() {
        const token3 = cookies.get("token"); //SCRATCH_TOKEN
        if (token3) {
            console.log("列表接口", this.state.res);
            this.queryEve(this.state.res);
            this.props.draftListShowEve();
        } else {
            this.props.onSeeCommunity1();
        }
    }
    openShare() {
        const token2 = cookies.get("token"); //SCRATCH_TOKEN
        if (token2) {
            this.props.PreShareShowEve();
        } else {
            this.props.onSeeCommunity1();
        }
    }
    queryEve(res) {
        queryGetDraftList(res).then(({ data }) => {
            console.log("列表", data);
            if (data.code === 0) {
                // this.setState({
                //     dataList: data.data.list,
                //     totalData: data.data
                // })
                this.props.draftDataListEve(data.data.list);
                this.props.draftTotalDataEve(data.data);
            } else {
                message.error(data.msg);
            }
        });
    }
    render() {
        const outDom = (
            <div
                className={styles.questionSubmitLoginOut}
                onClick={this.loginout}
            >
                退出
            </div>
        );
        const token1 = cookies.get("token"); //SCRATCH_TOKEN
        if (token1 && !this.state.token) {
            this.setState({
                token: token1,
            });
        }

        let userImg = null;
        // if (this.props.portraitUrl && this.props.portraitUrl !== "null") {
        //     userImg = this.props.portraitUrl;
        // } else
        if (
            cookies.get("portraitUrl") &&
            cookies.get("portraitUrl") !== "null"
        ) {
            userImg = cookies.get("portraitUrl");
        } else {
            userImg = headDefault;
        }
        return (
            <div>
                <div className={styles.questionContainer}>
                    <div className={styles.questionInput}>
                        <div
                            className={styles.questionSubmitWork}
                            onClick={this.openDraft}
                        >
                            <span>
                                <i className="zsi1 iconfont iconpractice_icon_save icon-font-size-20"></i>
                                &nbsp;&nbsp;草稿
                            </span>
                        </div>
                        <button
                            className={styles.questionShareButton}
                            onClick={this.openShare}
                        >
                            <span>
                                <i
                                    className="iconfont iconPractice_Icon_Share"
                                    style={{ marginRight: "3px" }}
                                ></i>
                                分享
                            </span>
                        </button>
                        {this.state.token && this.state.token !== "null" ? (
                            <Dropdown overlay={outDom}>
                                <img
                                    src={
                                        // cookies.get("portraitUrl") ||
                                        // headDefault
                                        this.props.portraitUrl
                                    }
                                    alt
                                    className={styles.userImgCls}
                                />
                            </Dropdown>
                        ) : (
                            <div
                                className={styles.questionSubmitLogin}
                                onClick={this.goDl}
                            >
                                登录
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

WorksComponent.propTypes = {
    onSeeCommunity1: PropTypes.func,
    draftListShowEve: PropTypes.func,
    draftDataListEve: PropTypes.func,
    draftTotalDataEve: PropTypes.func,
    PreShareShowEve: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    // console.log("右边state值", state, ownProps);
    return {
        portraitUrl: state.scratchGui.login.portraitUrl,
        token: state.scratchGui.login.token,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    draftListShowEve: () => dispatch(setDradtListShow(true)),
    draftDataListEve: (res) => dispatch(setDataList(res)),
    draftTotalDataEve: (res) => dispatch(setTotalData(res)),
    PreShareShowEve: () => dispatch(setPreShareShow(true)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(WorksComponent);
