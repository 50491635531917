import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import VM from "scratch-vm-dingdangcode";
import { defineMessages, injectIntl, intlShape } from "react-intl";

import extensionLibraryContent from "../lib/libraries/extensions/index.jsx";

import LibraryComponent from "../components/library/library.jsx";
import extensionIcon from "../components/action-menu/icon--sprite.svg";

const messages = defineMessages({
    extensionTitle: {
        defaultMessage: "Choose an Extension",
        description: "Heading for the extension library",
        id: "gui.extensionLibrary.chooseAnExtension",
    },
    extensionUrl: {
        defaultMessage: "Enter the URL of the extension",
        description: "Prompt for unoffical extension url",
        id: "gui.extensionLibrary.extensionUrl",
    },
});

class ExtensionLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, ["handleItemSelect"]);
    }
    handleItemSelect(item) {
        const id = item.extensionId;
        let url = item.extensionURL ? item.extensionURL : id;
        if (!item.disabled && !id) {
            // eslint-disable-next-line no-alert
            url = prompt(this.props.intl.formatMessage(messages.extensionUrl));
        }
        if (id && !item.disabled) {
            if (this.props.vm.extensionManager.isExtensionLoaded(url)) {
                this.props.onCategorySelected(id);
            } else {
                this.props.vm.extensionManager
                    .loadExtensionURL(url)
                    .then(() => {
                        this.props.onCategorySelected(id);
                    });
            }
        }
    }
    render() {
        const extensionLibraryThumbnailData = extensionLibraryContent.map(
            (extension) => ({
                rawURL: extension.iconURL || extensionIcon,
                ...extension,
            })
        );
        console.log(777, extensionLibraryThumbnailData);
        return (
            <LibraryComponent
                data={extensionLibraryThumbnailData}
                filterable={false}
                id="extensionLibrary"
                title={this.props.intl.formatMessage(messages.extensionTitle)}
                visible={this.props.visible}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

ExtensionLibrary.propTypes = {
    intl: intlShape.isRequired,
    onCategorySelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    visible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired, // eslint-disable-line react/no-unused-prop-types
};

export default injectIntl(ExtensionLibrary);
