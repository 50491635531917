import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
import cookies from "js-cookie";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { DownOutlined, CaretDownOutlined } from "@ant-design/icons";
import styles from "../components/works-upload/edit-type.css";

const env = process.env.NODE_ENV;
const frameUrl =
    env === "production"
        ? "https://play.dingdangcode.com/"
        : "https://play.dingdangcode.cn/"; //"https://play.dingdangcode.cn/"
class EditType extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["handleClick"]);
        this.state = {
            curKey: "sub3",
        };
    }
    handleClick(e) {
        console.log(22, e);
        this.setState({
            curKey: e.key,
        });
        if (e.key === "sub1" || e.key === "sub2" || e.key === "sub4") {
            window.location.href =
                frameUrl +
                "?editType=" +
                (e.key === "sub1" ? "0" : e.key === "sub2" ? "2" : "4");
            // winOpen(frameUrl + "?editType=" + (e.key === "sub1" ? "0" : "2"));
        }
    }
    render() {
        const menu = (
            <Menu onClick={this.handleClick} className={styles.menuWra}>
                <Menu.Item
                    icon={
                        // <i
                        //     className={`zsi1 iconfont iconPractice_Icon_Standard ${styles.editIcon}`}
                        // ></i>
                        <img
                            src="/static/practice_icon_python.svg"
                            style={{
                                width: "14px",
                                height: "14px",
                                marginRight: "7px",
                            }}
                        />
                    }
                    key="sub1"
                >
                    标准编辑
                </Menu.Item>
                <Menu.Item
                    icon={
                        // <i
                        //     className={`zsi1 iconfont iconpractice_icon_create ${styles.editIcon}`}
                        // ></i>
                        <img
                            src="/static/practice_icon_create.svg"
                            style={{
                                width: "14px",
                                height: "14px",
                                marginRight: "7px",
                            }}
                        />
                    }
                    key="sub2"
                >
                    创客编辑
                </Menu.Item>
                <Menu.Item
                    icon={
                        // <i
                        //     className={`zsi1 iconfont iconPractice_Icon_Figure ${styles.editIcon}`}
                        // ></i>
                        <img
                            src="/static/practice_icon_figure.svg"
                            style={{
                                width: "14px",
                                height: "14px",
                                marginRight: "4px",
                            }}
                        />
                    }
                    key="sub3"
                >
                    图形编辑
                </Menu.Item>
                <Menu.Item
                    icon={
                        <img
                            src="/static/practice_icon_c.svg"
                            style={{
                                width: "14px",
                                height: "14px",
                                marginRight: "7px",
                            }}
                        />
                    }
                    key="sub4"
                >
                    C++编辑
                </Menu.Item>
            </Menu>
        );
        const { curKey } = this.state;
        return (
            <Dropdown overlay={menu} className={styles.editType}>
                <span>
                    {/* <i
                        className={`zsi1 iconfont  ${styles.fontSize20}  ${
                            curKey === "sub1"
                                ? "iconPractice_Icon_Standard"
                                : curKey === "sub2"
                                ? "iconpractice_icon_create"
                                : "iconPractice_Icon_Figure"
                        }`}
                    ></i> */}
                    <img
                        src={
                            curKey === "sub1"
                                ? "/static/practice_icon_python_white.svg"
                                : curKey === "sub2"
                                ? "/static/practice_icon_create_white.svg"
                                : curKey === "sub3"
                                ? "/static/practice_icon_figure_white.svg"
                                : "/static/practice_icon_c_white.svg"
                        }
                        style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "7px",
                        }}
                        alt=""
                    />
                    <span className={styles.editText}>
                        {curKey === "sub1"
                            ? " 标准编辑"
                            : curKey === "sub2"
                            ? "创客编辑"
                            : curKey === "sub3"
                            ? "图形编辑"
                            : "C++编辑"}
                    </span>

                    <CaretDownOutlined />
                </span>
            </Dropdown>
        );
    }
}

EditType.propTypes = {
    // answer: PropTypes.string,
    // className: PropTypes.string,
    // onChange: PropTypes.func.isRequired,
    // onClick: PropTypes.func.isRequired,
    // onKeyPress: PropTypes.func.isRequired,
    // question: PropTypes.string,
    // onSeeCommunity1: PropTypes.func,
    // drawShowEve: PropTypes.func,
    // dispatchDraftTitle: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    // console.log(123, state, ownProps);
    return {
        // hideMenu: state.scratchGui.login.login,
        // draftTitle: state.scratchGui.login.draftTitle,
        // draftId: state.scratchGui.login.draftId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    // onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    // drawShowEve: () => dispatch(setpublishDrawShow(true)),
    // dispatchDraftTitle: (res) => dispatch(changeDraftTitle(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(EditType);
