import Axios from "../apiConfig/axios";
import { apiFragment } from "../apiConfig/apiBase";

/**
 * @description 保存做题记录
 * @param {integer} sectionId 参数
 * @param {integer} subjectId 参数
 * @param {integer} result 参数
 * @param {integer} homeworkId 参数
 * @param {integer} courseId 参数
 * @param {integer} childId 参数
 * @param {string} answer sb3文件地址
 * @description 新增参数
 * @param {integer} batchId 参数
 * @param {integer} clazzId 参数
 * @param {integer} packageId 参数
 * @return {object} 返回值描述
 */
export const save = (payload) => {
    try {
        return Axios.request({
            url: `/ddc-ddm/homework/save/record`,
            data: payload,
            method: "POST",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 保存做题记录
 * @param {integer} sectionId 参数
 * @param {integer} homeworkId 参数
 * @param {integer} courseId 参数
 * @param {integer} childId 参数
 * @description 新增参数
 * @param {integer} batchId 参数
 * @param {integer} clazzId 参数
 * @param {integer} packageId 参数
 * @return {object} 返回值描述
 */
export const submit = (payload) => {
    const {
        courseId,
        sectionId,
        homeworkId,
        childId,
        batchId,
        clazzId,
        packageId,
    } = payload;
    try {
        return Axios.request({
            url: `/ddc-ddm/homework/submit/${clazzId}/${batchId}/${packageId}/${courseId}/${sectionId}/${homeworkId}/${childId}`,
            data: payload,
            method: "POST",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 查询用户答案
 * @param {integer} sectionId 参数
 * @param {integer} subjectId 参数
 * @param {integer} homeworkId 参数
 * @param {integer} courseId 参数
 * @param {integer} childId 参数
 * @return {object} 返回值描述
 */
export const queryUserCode = (payload) => {
    try {
        return Axios.request({
            url: `/ddc-ddm/homework/query/record`,
            data: payload,
            method: "POST",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 查询题目详情
 * @param {integer} subjectId 参数
 * @return {object} 返回值描述
 */
export const querySubject = (payload) => {
    const { subjectId } = payload;
    try {
        return Axios.request({
            url: `/ddc-ddm/common/subject/${subjectId}`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 练习保存评测
 * @return {object} 返回值描述
 */
export const savePractise = (payload) => {
    try {
        return Axios.request({
            url: `/ddc-ddm/course/unit/result`,
            data: payload,
            method: "PUT",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 用户发布草稿
 * @param {string} cover 封面图
 * @param {string} fileUrl 文件url（scratch sb3文件专用）
 * @param {integer} id 参数
 * @param {string} title 标题
 * @param {integer} type sb3为2
 * @return {object} 返回值描述
 */
export const requestSaveDraft = (payload) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/saveDraft`,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 用户更新草稿
 * @param {string} cover 封面图
 * @param {string} fileUrl 文件url（scratch sb3文件专用）
 * @param {integer} id 参数
 * @param {string} title 标题
 * @param {integer} type sb3为2
 * @return {object} 返回值描述
 */
export const requestUpdateDraft = (payload) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/updateDraft`,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 将当前代码分享
 * @param {string} fileUrl 文件url（scratch sb3文件专用）
 * @param {integer} desc 参数
 * @param {string} title 标题
 * @param {integer} type sb3为2
 * @return {object} 返回值描述
 */
export const requestShareUserCode = (payload) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/shareUserCode`,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * @description 课程-学习记录结束
 * @param {string} classId
 * @param {string} courseId
 * @param {integer} pointId
 * @param {integer} seriesId
 * @param {integer} worksFileUrl  sb3文件url
 * @return {object} 返回值描述
 */
export const requestLearnRecordFinish = (payload) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcEdu}/course/student/chapter/finish`,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};
