import cookies from "js-cookie";
export const generateUUID = function () {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); // use high-precision timer if available
    }
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
    return uuid;
};

// 获取url的参数
export const queryString = () => {
    const _queryString = {};
    const _query = window.location.search.substr(1);
    const _vars = _query.split("&");
    _vars.forEach((v) => {
        const _pair = v.split("=");
        if (!_queryString.hasOwnProperty(_pair[0])) {
            _queryString[_pair[0]] = decodeURIComponent(_pair[1]);
        } else if (typeof _queryString[_pair[0]] === "string") {
            const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])];
            _queryString[_pair[0]] = _arr;
        } else {
            _queryString[_pair[0]].push(decodeURIComponent(_pair[1]));
        }
    });
    return _queryString;
};

export function buildGET(basePort, json) {
    let buildGet = basePort;
    let fistNumber = 0;
    for (var key in json) {
        let littlePort = "";
        if (json[key] !== null && json[key] !== "null") {
            if (fistNumber === 0) {
                littlePort = "?" + key + "=" + json[key];
                fistNumber++;
            } else {
                littlePort = "&" + key + "=" + json[key];
            }
            buildGet += littlePort;
        }
    }
    return String(buildGet);
}

class Validate {
    //身份证校验
    idCard(val) {
        return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(
            val
        );
    }

    //手机号校验
    phone(val) {
        return /^1[3456789]\d{9}$/.test(val);
    }

    //邮箱
    email(val) {
        return /^\w+@[a-z0-9]+\.[a-z]{2,4}$/.test(val);
    }

    //普通护照
    passport(val) {
        return /^((1[45]\d{7})|(G\d{8})|(P\d{7})|(S\d{7,8}))?$/.test(val);
    }

    //台胞证
    taiwanID(val) {
        return /^[a-zA-Z][0-9]{9}$/.test(val);
    }
    //港澳身份证
    hkId(val) {
        return /^([A-Z]\d{6,10}(\w1)?)$/.test(val);
    }

    //中文
    chineseWord(val) {
        return /^[\u4e00-\u9fa5]*$/.test(val);
    }

    //密码（不能是纯数字或字母）
    psdRxp(val) {
        const numberRegexp = /^\d+$/;
        const letterRegexp = /^[a-zA-Z]+$/;
        return numberRegexp.test(val) || letterRegexp.test(val);
    }
    // 纯数字
    onlyNum(val) {
        return /^\d{4}$/.test(val);
    }

    // 账号校验
    account(val) {
        return val && val.length >= 4;
    }

    // 用户名校验
    usernameRegex(val) {
        return /^([a-zA-Z0-9_\u4e00-\u9fa5]{1,8})$/.test(val);
    }

    // 验证URL
    isUrl(val) {
        return /(http|https):\/\/([\w.]+\/?)\S*/.test(val);
    }
}

export const validate = new Validate();

/**毫秒数或中国标准时间转日期 */
export function msToDate(msec) {
    let datetime = new Date(msec);
    let year = datetime.getFullYear();
    let month = datetime.getMonth();
    let date = datetime.getDate();
    let hour = datetime.getHours();
    let minute = datetime.getMinutes();
    let second = datetime.getSeconds();

    let result1 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 < 10 ? "0" + date : date) +
        " " +
        (hour + 1 < 10 ? "0" + hour : hour) +
        ":" +
        (minute + 1 < 10 ? "0" + minute : minute) +
        ":" +
        (second + 1 < 10 ? "0" + second : second);

    let result2 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 < 10 ? "0" + date : date);
    let result3 =
        year +
        "年" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "月" +
        (date + 1 < 10 ? "0" + date : date) +
        " " +
        (hour + 1 < 10 ? "0" + hour : hour) +
        ":" +
        (minute + 1 < 10 ? "0" + minute : minute);

    let result4 =
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 <= 10 ? "0" + date : date) +
        "  " +
        (hour + 1 < 10 ? "0" + hour : hour) +
        ":" +
        (minute + 1 < 10 ? "0" + minute : minute);

    let result5 =
        year +
        "/" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "/" +
        (date + 1 < 10 ? "0" + date : date) +
        " " +
        (hour + 1 < 10 ? "0" + hour : hour) +
        ":" +
        (minute + 1 < 10 ? "0" + minute : minute);

    let result = {
        hasTime: result1,
        withoutTime: result2,
        cTime: result3,
        simpleTime: result4,
        tchTime: result5,
    };

    return result;
}

/**
 * 模拟window.open()
 * @export
 * @param {url}
 * @returns
 */
export function winOpen(url) {
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("target", "_blank");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const isPro = process.env.REACT_APP_ENV === "production";
const isLocal = process.env.REACT_APP_ENV === "local";

export function domainToken(key, data, opts) {
    let _opts = opts || {};
    return {
        set: () => {
            return cookies.set(key, data, {
                domain: isPro
                    ? ".dingdangcode.com"
                    : isLocal
                    ? "localhost"
                    : ".dingdangcode.cn",
                ..._opts,
            });
        },
        remove: () => {
            return cookies.remove(key, {
                domain: isPro
                    ? ".dingdangcode.com"
                    : isLocal
                    ? "localhost"
                    : ".dingdangcode.cn",
            });
        },
    };
}
