import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
// import { connect } from "react-redux";
// import { compose } from "redux";
// import { injectIntl } from "react-intl";
// import { setLoginModalShow } from "../reducers/login";
import WorksComponent from "../components/works-upload-right/works-upload.jsx";

class Works extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleChange",
            "handleKeyPress",
            "handleSubmit",
            "goLoginModalShow",
        ]);
        // "handleChange", "handleKeyPress", "handleSubmit";
        this.state = {
            answer: "",
            question: "传过来的值",
        };
    }
    handleChange(e) {
        this.setState({ answer: e.target.value });
    }
    handleKeyPress(event) {
        if (event.key === "Enter") this.handleSubmit();
    }
    handleSubmit() {
        this.props.onQuestionAnswered(this.state.answer);
    }
    goLoginModalShow() {
        console.log(this.props);
        console.log("点击成功");
        this.props.onSeeCommunity1;
    }
    render() {
        return (
            <WorksComponent
                answer={this.state.answer}
                question={this.state.question}
                onChange={this.handleChange}
                onClick={this.handleSubmit}
                onKeyPress={this.handleKeyPress}
                goLoginModalShow={this.goLoginModalShow}
            />
        );
    }
}

Works.propTypes = {
    onQuestionAnswered: PropTypes.func, //PropTypes.func.isRequired,
    question: PropTypes.string,
    onSeeCommunity1: PropTypes.func,
};

// const mapStateToProps = (state, ownProps) => {
//     console.log(6000, state, ownProps);
//     return {
//         hideMenu: state,
//     };
// };

// const mapDispatchToProps = (dispatch) => ({
//     onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
// });

// export default compose(
//     injectIntl,
//     connect(mapStateToProps, mapDispatchToProps)
// )(Works);
export default Works;
