/* eslint-disable */
import COS from "cos-js-sdk-v5";
import { getGipher } from "../api/apiServer/apiUploadFile";

export const _getGipher = (type) => {
    return new Promise((resolve) => {
        try {
            getGipher(type).then((res) => {
                let { data } = res;
                if (data.code === 0) {
                    return resolve(data.data);
                }
            });
        } catch (e) {}
    });
};
export const cosUploadFile = function ({
    type = "user_works",
    file = {},
    uuid = "",
    upProgress = null,
    upSucceed = null,
} = {}) {
    return new Promise((resolve) => {
        try {
            _getGipher(type).then((uploadData) => {
                if (typeof upProgress !== "function" && upProgress)
                    return new Error("upProgress not function");
                if (typeof upSucceed !== "function" && upSucceed)
                    return new Error("upSucceed not function");

                let fileNameSuffix = file.filename
                    .substring(file.filename.lastIndexOf("."))
                    .toLowerCase();
                const fileName = uuid
                    ? `${uuid}${fileNameSuffix}`
                    : file.filename;
                const key = uuid
                    ? `${uploadData.rolePath}/${uuid}${fileNameSuffix}`
                    : `${uploadData.rolePath}/${file.filename}`;
                console.log(
                    32222,
                    file.filename,
                    uploadData,
                    "uuid",
                    uuid,
                    "key",
                    key,
                    fileName
                );
                cosObj(uploadData).then((cos) => {
                    // console.log(file.data);
                    putObject(cos, key, file.data, uploadData, upProgress).then(
                        () => {
                            getObjectUrl(cos, key, fileName, uploadData).then(
                                (data) => {
                                    resolve(upSucceed(data));
                                }
                            );
                        }
                    );
                });
            });
            // const nowDate = new Date().valueOf()
        } catch (error) {
            resolve(upSucceed(error));
            // console.log("cosUploadFile", error);
        }
    });
};

export const cosObj = (uploadData) => {
    return new Promise((resolve) => {
        let cos = new COS({
            getAuthorization: function (options, callback) {
                // 获取签名 必填参数
                // 方法二（适用于前端）
                const authorization = {
                    TmpSecretId: uploadData.credentials.tmpSecretId,
                    TmpSecretKey: uploadData.credentials.tmpSecretKey,
                    XCosSecurityToken: uploadData.credentials.sessionToken, // 需要提供sessionToken
                    ExpiredTime: uploadData.expiredTime,
                    ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
                };
                callback(authorization);
            },
            ProgressInterval: 10,
        });
        return resolve(cos);
    });
};

export const putObject = (cos, key, file, uploadData, upProgress) => {
    return new Promise((resolve, reject) => {
        cos.putObject(
            {
                Bucket: uploadData.bucketName, // Bucket 格式：test-1250000000
                Region: uploadData.region,
                Key: key,
                Body: file,

                onTaskReady: function (tid) {
                    // console.log("onTaskReady", tid);
                },
                onTaskStart: function (info) {
                    // console.log("onTaskStart", info);
                },
                onProgress:
                    upProgress ||
                    function (info) {
                        // console.log("onTaskStart", info);
                    },
            },
            function (err, data) {
                if (data && data.statusCode === 200) {
                    if (data && !data.hasOwnProperty("code")) {
                        data["code"] = 0;
                    }
                    resolve(data);
                } else {
                    if (err && !err.hasOwnProperty("errMsg")) {
                        err["errMsg"] = "上传失败";
                    }
                    reject(err);
                }
            }
        );
    });
};

export const getObjectUrl = (cos, key, fileName, uploadData) => {
    return new Promise(function (resolve, reject) {
        cos.getObjectUrl(
            {
                Bucket: uploadData.bucketName, // Bucket 格式：test-1250000000
                Region: uploadData.region,
                Key: key,
                Expires: 60,
                Sign: true,
            },
            function (err, data) {
                if (data && data.Url.indexOf("?") !== -1) {
                    let file = Object.create(null);
                    file["code"] = 0;
                    file["url"] = data.Url.split("?")[0].replace(
                        "cos.ap-chengdu",
                        "file"
                    );
                    file["name"] = fileName;
                    resolve(file);
                } else {
                    if (err && !err.hasOwnProperty("errMsg")) {
                        err["errMsg"] = "获取url失败";
                    }
                    reject(err);
                }
            }
        );
    });
};
