import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { message } from "antd";
import { projectTitleInitialState } from "../reducers/project-title";
import downloadBlob from "../lib/download-blob";
import uploadCodeToServer from "../lib/upload-to-server";
import { injectIntl } from "react-intl";
import {
    requestSaveDraft,
    requestShareUserCode,
    requestUpdateDraft,
} from "../api/apiServer/apiUser";
import {
    setShareTitle,
    setPublishCode,
    setDraftId,
    setPreShareShow,
    setShareShow,
    setShareId,
} from "../reducers/login";
import { cosUploadFile } from "../utils/cosUploadFile";
import { generateUUID } from "../utils/index";
import cookies from "js-cookie";
import queryString from "query-string";
/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Downloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Downloader>
 */
const sourceType = sessionStorage.getItem("sourceType");
const { adapt } = queryString.parse(location.search);
class SB3Downloader extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "downloadProject",
            "saveDraft",
            "updateDraft",
            "shareCurCode",
            "publishCodeEvent",
            "cosUploadFileEve",
        ]);
        this.state = {
            inputVal: "",
        };
    }
    // 保存草稿
    saveDraft(data) {
        if (data.code === 0) {
            console.log(
                "传过来的数据title",
                this.props.draftTitle,
                cookies.get("DRAFT_TITLE"),
                sessionStorage.getItem("DRAFT_TITLE")
            );
            this.setState({
                inputVal: sessionStorage.getItem("DRAFT_TITLE"),
            });
            const that = this;
            setTimeout(function () {
                requestSaveDraft({
                    fileUrl: data.url,
                    id: "",
                    cover: that.props.canvasUrl
                        ? that.props.canvasUrl
                        : "https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg", //"https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg", //测试：暂且是固定封面
                    title: that.state.inputVal, //cookies.get("DRAFT_TITLE") || "",
                    type: 2,
                    sourceType:
                        sourceType && sourceType === 1
                            ? 1
                            : sourceType && sourceType === 2
                            ? 2
                            : adapt === "adapt"
                            ? 2
                            : 1,
                }).then(({ data }) => {
                    if (data.code === 0) {
                        that.props.setDraftIdEve(data.data.id);
                        sessionStorage.setItem("draftId", data.data.id);
                        sessionStorage.setItem(
                            "sourceType",
                            data.data.sourceType
                        );
                        // cookies.set("draftId", data.data.id);
                        console.log("保存草稿后的返回数据", data);
                        message.success("草稿保存成功，请在 “草稿” 中查看。");
                    } else {
                        message.error(data.msg);
                    }
                });
            }, 4000);
        }
    }

    // 更新草稿
    updateDraft(data) {
        if (data.code === 0) {
            requestUpdateDraft({
                fileUrl: data.url,
                id: sessionStorage.getItem("draftId"),
                cover: this.props.canvasUrl, //"https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg", //测试：暂且是固定封面
                title:
                    this.props.draftTitle ||
                    sessionStorage.getItem("DRAFT_TITLE") ||
                    "",
                type: 2,
                sourceType:
                    sourceType && sourceType === 1
                        ? 1
                        : sourceType && sourceType === 2
                        ? 2
                        : adapt === "adapt"
                        ? 2
                        : 1,
            }).then(({ data }) => {
                if (data.code === 0) {
                    console.log("保存草稿后的返回数据", data);
                    message.success("草稿覆盖成功，请在 “草稿” 中查看。");
                } else {
                    message.error(data.msg);
                }
            });
        }
    }
    // 分享当前代码
    shareCurCode(data) {
        const { shareTitle, shareDesc } = this.props;
        console.log("分享获取到的值", shareTitle, shareDesc);
        if (data.code === 0) {
            let aa = {
                fileUrl: data.url,
                type: 2,
                desc:
                    shareDesc === "" ? "作者太懒，什么都没有留下~" : shareDesc,
                title: shareTitle === "" ? "未命名" : shareTitle, //cookies.get("DRAFT_TITLE") || "",
            };
            requestShareUserCode(aa).then(({ data }) => {
                if (data.code === 0) {
                    console.log("分享成功", data);
                    cookies.set("shareId", data.data);
                    this.props.PreShareShowEve();
                    this.props.ShareShowEve();
                    this.props.setShareIdEve(data.data);
                    // window.location.replace(
                    //     window.location.origin + window.location.pathname
                    // );
                    // console.log("保存草稿后的返回数据", data);
                    // message.success("分享成功");
                } else {
                    message.error(data.msg);
                }
            });
        }
    }
    //发布作品时的
    publishCodeEvent(data) {
        if (data.code === 0) {
            // cookies.set("publishCode", data.url);
            this.props.setPublishCodeEve(data.url);
        }
    }
    cosUploadFileEve(filename, blob, type) {
        const uuid = generateUUID();
        let file = new window.File([blob], filename, {
            type: blob.type,
            lastModified: Date.now(),
        });
        console.log("sb3文件：", blob, file);
        cosUploadFile({
            type: "user_scratch",
            file: { data: file, filename },
            uuid: uuid,
            upSucceed:
                type === 6
                    ? this.updateDraft
                    : type === 5
                    ? this.publishCodeEvent
                    : type === 4
                    ? this.shareCurCode
                    : this.saveDraft,
        });
    }
    downloadProject(toServer) {
        console.log("xxx:", toServer);
        this.props.saveProjectSb3().then((content) => {
            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
            if (toServer === 1) {
                uploadCodeToServer(
                    this.props.projectFilename,
                    content,
                    toServer
                );
            } else if (toServer === 2) {
                console.log(4222, this.props.projectFilename, content);
                uploadCodeToServer(
                    this.props.projectFilename,
                    content,
                    toServer
                );
            } else if (toServer === 3) {
                //保存草稿时
                console.log(
                    4333,
                    sessionStorage.getItem("draftId"),
                    content,
                    this.props.projectFilename
                );
                if (sessionStorage.getItem("draftId")) {
                    //当草稿id存在时要用更新草稿接口
                    this.cosUploadFileEve(
                        this.props.projectFilename,
                        content,
                        6
                    );
                } else {
                    this.cosUploadFileEve(
                        this.props.projectFilename,
                        content,
                        3
                    );
                }
            } else if (toServer === 4) {
                //预览时的
                console.log(4444, this.props.projectFilename, content);
                this.cosUploadFileEve(
                    this.props.projectFilename,
                    content,
                    toServer
                );
            } else if (toServer === 5) {
                //发布作品时
                console.log(4444, this.props.projectFilename, content);
                this.cosUploadFileEve(
                    this.props.projectFilename,
                    content,
                    toServer
                );
            } else {
                downloadBlob(this.props.projectFilename, content);
            }
        });
    }
    render() {
        const { children } = this.props;
        return children(this.props.className, this.downloadProject);
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
    setDraftIdEve: PropTypes.func,
    setShareIdEve: PropTypes.func,
    PreShareShowEve: PropTypes.func,
    ShareShowEve: PropTypes.func,
    setPublishCodeEve: PropTypes.func,
};
SB3Downloader.defaultProps = {
    className: "",
};

const mapStateToProps = (state) => {
    // console.log(287777, state.scratchGui.vm.editingTarget);
    const aa = "F-I+j;w*,QZPo]P~{xhC";
    for (let p in state.scratchGui.vm.editingTarget &&
        state.scratchGui.vm.editingTarget.blocks._blocks) {
        // console.log(
        //     291,
        //     p,
        //     state.scratchGui.vm.editingTarget.blocks._blocks[p]
        // );
        if (
            state.scratchGui.vm.editingTarget.blocks._blocks[p]["opcode"] ===
            "event_whenthisspriteclicked"
        ) {
            console.log("找到键盘事件积木");
        }
    }
    // console.log(
    //     3033333,
    //     state.scratchGui.login,
    //     state.scratchGui.login.canvasUrl
    // );
    return {
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(
            state.scratchGui.vm
        ),
        projectFilename: getProjectFilename(
            state.scratchGui.projectTitle,
            projectTitleInitialState
        ),
        draftTitle: state.scratchGui.login.draftTitle,
        shareTitle: state.scratchGui.login.shareTitle,
        shareDesc: state.scratchGui.login.shareDesc,
        toolboxXML: state.scratchGui.toolbox.toolboxXML,
        canvasUrl: state.scratchGui.login.canvasUrl,
    };
};
const mapDispatchToProps = (dispatch) => ({
    setPublishCodeEve: (res) => dispatch(setPublishCode(res)),
    setDraftIdEve: (res) => dispatch(setDraftId(res)),
    setShareIdEve: (res) => dispatch(setShareId(res)),
    PreShareShowEve: () => dispatch(setPreShareShow(false)),
    ShareShowEve: () => dispatch(setShareShow(true)),
});
// export default connect(
//     mapStateToProps,
//     () => ({}) // omit dispatch prop
// )(SB3Downloader);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SB3Downloader));
