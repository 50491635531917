import { cosUploadFile } from "../utils/cosUploadFile";
import { generateUUID } from "../utils/index";
import { save, submit } from "../api/apiServer/apiUser";
import { addLearnRecord } from "../api/apiServer/apiCommon";
import {
    requestSaveDraft,
    requestShareUserCode,
    requestUpdateDraft,
} from "../api/apiServer/apiUser";
import queryString from "query-string";
import { message } from "antd";
import cookies from "js-cookie";

const query = queryString.parse(location.search);

// content	string
// 代码/sb3文件((必传))
// goodsId	integer($int32)
// 商品id
// result	integer($int32)
// 结果-1未通过0未做1通过
// status	integer($int32)
// 状态0未学习1学习中2完成
// stepId	integer($int32)
// 步骤id(必传)
// subjectId	integer($int32)
// 题目id(若不需要评测，则不传)
// taskId

// const {
//     childId = null,
//     courseId = null,
//     homeworkId = null,
//     id = null,
//     sectionId = null,
//     batchId = null,
//     clazzId = null,
//     packageId = null,
// } = query;

// 保存作业 返回数据
const saveSucceed = (data, cb) => {
    // console.log("ccc=====", data, cb);
    if (data.code === 0) {
        message.success("操作成功");
        console.log("学科融合数据", cookies.get("disci_time"));
        addLearnRecord({
            ...query,
            content: JSON.stringify({ userAnswer: data.url, result: 1 }),
            result: 1,
            status: 2,
            prictiseValue: cookies.get("disci_time"),
        }).then(({ data }) => {
            cb && cb(data);
        });
    }

    // if (data.code === 0) {
    //     save({
    //         childId,
    //         courseId,
    //         homeworkId,
    //         batchId,
    //         clazzId,
    //         packageId,
    //         subjectId: id,
    //         sectionId,
    //         result: window.ddm_result || null,
    //         answer: data.url,
    //     }).then(({ data }) => {
    //         // 如果回调存在 则执行回调
    //         cb && cb(data);
    //     });
    // } else {
    //     window.ddm_result = null;
    //     // alert(data.msg);
    // }
};

// 保存草稿
// const saveDraft = (data) => {
//     if (data.code === 0) {
//         requestSaveDraft({
//             fileUrl: data.url,
//             id: "",
//             cover: "https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg", //测试：暂且是固定封面
//             title: cookies.get("DRAFT_TITLE") || "",
//             type: 2,
//         }).then(({ data }) => {
//             // cb && cb(data);
//             if (data.code === 0) {
//                 cookies.set("draftId", data.data.id);
//                 console.log("保存草稿后的返回数据", data);
//                 message.success("草稿保存成功");
//             } else {
//                 message.error(data.msg);
//             }
//         });
//     }
// };

// 更新草稿
// const updateDraft = (data) => {
//     if (data.code === 0) {
//         requestUpdateDraft({
//             fileUrl: data.url,
//             id: "",
//             cover: "https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg", //测试：暂且是固定封面
//             title: cookies.get("DRAFT_TITLE") || "",
//             type: 2,
//         }).then(({ data }) => {
//             // cb && cb(data);
//             if (data.code === 0) {
//                 cookies.set("draftId", data.data.id);
//                 console.log("保存草稿后的返回数据", data);
//                 message.success("草稿保存成功");
//             } else {
//                 message.error(data.msg);
//             }
//         });
//     }
// };
// 分享当前代码
// const shareCurCode = (data) => {
//     if (data.code === 0) {
//         let aa = {
//             fileUrl: data.url,
//             type: 2,
//             desc:
//                 cookies.get("shareDesc") ||
//                 (cookies.get("shareDesc") && cookies.get("shareDesc")) === ""
//                     ? "作者太懒，什么都没有留下~"
//                     : "",
//             title:
//                 cookies.get("shareTitle") ||
//                 (cookies.get("shareTitle") && cookies.get("shareTitle")) === ""
//                     ? "未命名"
//                     : "", //cookies.get("DRAFT_TITLE") || "",
//         };
//         requestShareUserCode(aa).then(({ data }) => {
//             if (data.code === 0) {
//                 console.log("分享成功", data);
//                 cookies.set("shareId", data.data);
//                 window.location.replace(
//                     window.location.origin + window.location.pathname
//                 );
//             } else {
//                 message.error(data.msg);
//             }
//         });
//     }
// };
//发布作品时的
// const publishCode = (data) => {
//     if (data.code === 0) {
//         cookies.set("publishCode", data.url);
//     }
// };
// 交作业
const upSucceed = (data) => {
    if (data.code === 0) {
        // 先保存
        saveSucceed(data, submitPro);
    }
};

// 提交项目
const submitPro = (data) => {
    if (data.code === 0) {
        submit({
            childId,
            courseId,
            homeworkId,
            sectionId,
            batchId,
            clazzId,
            packageId,
        })
            .then(({ data }) => {
                if (data.code === 0) {
                    // console.log("提交成功");
                    window.parent.postMessage(
                        { act: "ddm-submitWork", msg: "success" },
                        "*"
                    );
                } else {
                    window.parent.postMessage(
                        { act: "ddm-message", msg: data.msg },
                        "*"
                    );
                }
            })
            .catch((e) => {
                console.log(e);
            });
    } else {
        window.parent.postMessage({ act: "ddm-message", msg: data.msg }, "*");
    }
};
const uploadToServer = (filename, blob, type, draftTitle) => {
    console.log("filename, blob, type:=====", filename, blob, type);
    const uuid = generateUUID();
    let file = new window.File([blob], filename, {
        type: blob.type,
        lastModified: Date.now(),
    });
    console.log("sb3文件：", file);
    cosUploadFile({
        type: "user_scratch",
        file: { data: file, filename },
        uuid: uuid,
        upSucceed: type === 2 ? saveSucceed : upSucceed,
    });
};
export default uploadToServer;
// export default (filename, blob, type, draftTitle) => {
//     console.log("filename, blob, type:=====", filename, blob, type);
//     const uuid = generateUUID();
//     let file = new window.File([blob], filename, {
//         type: blob.type,
//         lastModified: Date.now(),
//     });
//     console.log("sb3文件：", file);
//     cosUploadFile({
//         type: "user_scratch",
//         file: { data: file, filename },
//         uuid: uuid,
//         upSucceed:
//             type === 5
//                 ? publishCode
//                 : type === 4
//                 ? shareCurCode
//                 : type === 3
//                 ? saveDraft
//                 : type === 2
//                 ? saveSucceed
//                 : upSucceed,
//     });
// };
