import { buriedTracking } from "../api/apiServer/apiCommon";
import { generateUUID } from "./index";

export const reportData = (url, data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof value !== "string") {
            // formData只能append string 或 Blob
            value = JSON.stringify(value);
        }
        formData.append(key, value);
    });
    return navigator.sendBeacon(url, formData);
};

// type Payload = {
//     event, // 事件名
//     [key], // 其他参数
// };

/**
 * @description 录播课数据埋点公共方法
 * @return new Map([[event: 事件名（唯一性）, 请求接口 ]])
 */
const actions = () => {
    return new Map([
        [{ event: "scratch_view", type: "buried" }, buriedTracking],
    ]);
};

/**
 * @description 埋点公共方法
 * @param payload 根据埋点参数
 */
export default function (payload) {
    //: Payload
    let _payload = { ...payload };
    let action = [...actions()].filter(([key, _]) => {
        return key.event === _payload.event;
    });
    action.forEach(([key, value]) => {
        // 生成唯一uuid batchCode代表一个周期
        let code = sessionStorage.getItem(key.type);
        if (
            key.batchCode === null ||
            !code ||
            code === "undefined" ||
            code === "null"
        ) {
            code = generateUUID();
            sessionStorage.setItem(key.type, code);
        }
        if (key.url && key.sendBeacon) {
            return reportData(key.url, {
                ...payload,
                batchCode: code ? code : null,
            });
        }
        return value({ ..._payload, batchCode: code ? code : null });
    });
}
