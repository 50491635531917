import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import VM from "scratch-vm-dingdangcode";

import backdropLibraryContent from "../lib/libraries/backdrops.json";
import { querySprBackCosData } from "../api/apiServer/apiCommon";
import backdropTags from "../lib/libraries/backdrop-tags";
import LibraryComponent from "../components/library/library.jsx";
import { compose } from "redux";
import { connect } from "react-redux";

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: "Choose a Backdrop",
        description: "Heading for the backdrop library",
        id: "gui.costumeLibrary.chooseABackdrop",
    },
});

class BackdropLibrary extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["handleItemSelect"]);
        this.state = {
            backdropsJson: [],
        };
    }
    componentDidMount() {
        if (this.props.backdropsJson) {
            querySprBackCosData(this.props.backdropsJson).then(({ data }) => {
                this.setState({
                    backdropsJson: data,
                });
            });
        }
    }
    handleItemSelect(item) {
        const vmBackdrop = {
            name: item.name,
            rotationCenterX: item.info[0] && item.info[0] / 2,
            rotationCenterY: item.info[1] && item.info[1] / 2,
            bitmapResolution: item.info.length > 2 ? item.info[2] : 1,
            skinId: null,
        };
        // Do not switch to stage, just add the backdrop
        this.props.vm.addBackdrop(item.md5, vmBackdrop);
    }
    render() {
        console.log("背景json", backdropLibraryContent);
        const { backdropsJson } = this.state;
        return (
            <LibraryComponent
                data={backdropsJson}
                // backdropLibraryContent
                id="backdropLibrary"
                tags={backdropTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

BackdropLibrary.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};
const mapStateToProps = (state) => {
    console.log(222222, state);
    return {
        backdropsJson: state.scratchGui.login.backdropsJson,
    };
};

const mapDispatchToProps = (dispatch) => ({
    // onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    // drawShowEve: () => dispatch(setpublishDrawShow(true)),
    // dispatchDraftTitle: (res) => dispatch(changeDraftTitle(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(BackdropLibrary);
// export default injectIntl(BackdropLibrary);
