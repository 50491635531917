import React from "react";
import {
    Modal,
    Button as AntdButton,
    message,
    Pagination,
    Tooltip,
} from "antd";
import bindAll from "lodash.bindall";
import styles2 from "../gui/gui.css";
import classnames from "classnames";
import noData from "./images/nodata.png";
import styles from "./index.css";
import { connect } from "react-redux";
import {
    getConnectionRobot,
    getDisconnectionRobot,
    getRobotListByIp,
    getRobotPage,
    getRobotStatus,
    getValidateGrantCode,
} from "../../api/apiServer/apiCommon";
import { currentConnectrobotinfo } from "../../reducers/custom-data";

function loadImg(url) {
    return new Promise((resolve, reject) => {
        try {
            let img = document.createElement("img"),
                body = document.documentElement || document.body;
            img.src = url;
            body.appendChild(img);
            img.onload = function () {
                if (img) {
                    resolve(true);
                } else {
                    reject(false);
                }
            };
            img.onerror = function () {
                resolve(false);
            };
        } catch (e) {
            reject(false);
        }
    });
}

class DeviceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            robotName: "",
            onlineCount: 0,
            robotList: [],
            robotPageData: null,
            pageNum: 1,
            canIuse: false,
            currentConnect: null,
        };
        bindAll(this, [
            "linkDevices",
            "handleCancel",
            "handleConnectionRobot",
            "handleDisconnectionRobot",
            "handleGetRobotPage",
            "onChange",
            "checkDevices",
        ]);
    }

    componentDidMount() {
        this.handleGetRobotPage();
    }

    handleGetRobotPage() {
        const { pageNum } = this.state;
        getRobotPage({ pageNum, pageSize: 6 }).then(({ data }) => {
            console.log("xxxx:", data);
            if (data.code === 0) {
                this.setState({
                    robotPageData: data.data,
                    robotList: data.data.list,
                });
                let _robotList = data.data.list;
                const currentConnect = _robotList.find(
                    (item) => item.status === 1
                );
                if (currentConnect) {
                    console.log(
                        "currentConnect:====当前连接的机器====",
                        currentConnect
                    );
                    this.props.currentConnectrobotinfo(currentConnect);
                    // 说明存在链接
                    this.setState({
                        currentConnect: currentConnect,
                        robotName: currentConnect.robotName,
                        onlineCount: currentConnect.onlineCount,
                    });
                } else {
                    this.setState({
                        currentConnect: null,
                        robotName: "",
                        onlineCount: currentConnect
                            ? currentConnect.onlineCount
                            : 0,
                    });
                }
            } else {
                message.error(data.msg);
            }
        });
    }

    linkDevices() {
        this.setState({ visible: true });
    }
    handleCancel() {
        this.setState({ visible: false });
    }

    handleConnectionRobot(item, robotIndex) {
        getConnectionRobot(item.id).then(({ data }) => {
            if (data.code === 0) {
                message.success("恭喜你，连接成功！");
                this.handleGetRobotPage();
            } else {
                message.error("很抱歉，连接失败！");
            }
        });
    }

    handleDisconnectionRobot(item, robotIndex) {
        getDisconnectionRobot(item.id).then(({ data }) => {
            if (data.code === 0) {
                message.success("你已断开连接！");
                this.handleGetRobotPage();
            } else {
                message.error("很抱歉，断开连接失败！");
            }
        });
    }

    onChange(page) {
        this.setState({ pageNum: page }, () => {
            this.handleGetRobotPage();
        });
    }
    checkDevices() {
        const { currentConnect } = this.state;
        if (!currentConnect) {
            message.warning("您未连接设备，请确认是否已经连接~");
            return;
        }
        let timer = null;
        let safety = null;
        function openerIframe() {
            timer && clearTimeout(timer);
            if (!safety) {
                let openUrl = currentConnect.streamUrl, //弹出窗口的url
                    iWidth = 800, //弹出窗口的宽度;
                    iHeight = 600, //弹出窗口的高度;
                    iTop = (window.screen.availHeight - 30 - iHeight) / 2, //获得窗口的垂直位置;
                    iLeft = (window.screen.availWidth - 10 - iWidth) / 2; //获得窗口的水平位置;
                let opts = `height=${iHeight},width=${iWidth},top=${iTop},left=${iLeft},toolbar=yes, menubar=no, scrollbars=no, resizable=yes,location=yes, status=no`;
                safety = window.open(openUrl, "safety", opts);
            }
            timer = setTimeout(() => {
                _loadImg();
            }, 2000);
        }
        function _loadImg() {
            loadImg(currentConnect.streamUrl)
                .then((res) => {
                    if (res) {
                        timer && clearTimeout(timer);
                        message.success("设备检测通过！");
                    } else {
                        openerIframe();
                    }
                })
                .catch((e) => {
                    openerIframe();
                });
        }
        _loadImg();
    }

    render() {
        const {
            visible,
            robotName,
            onlineCount,
            robotList,
            pageNum,
            robotPageData,
            canIuse,
        } = this.state;
        return (
            <React.Fragment>
                <AntdButton
                    className={classnames(styles2.comBtn, styles2.resetBtn)}
                    onClick={this.linkDevices}
                >
                    {robotName ? robotName : "连接设备"}
                </AntdButton>
                <Modal
                    width="852px"
                    visible={visible}
                    onCancel={this.handleCancel}
                    okText="确定"
                    cancelText="取消"
                    footer={false}
                    title={
                        <div>
                            <span>配置设备</span>
                            {canIuse ? (
                                `可正常使用”${robotName}“设备`
                            ) : (
                                <Tooltip
                                    title={
                                        <div>
                                            <span>
                                                如果您是首次连接机器人，需进行“安全校验”，请前往
                                            </span>
                                            <AntdButton
                                                type="link"
                                                onClick={this.checkDevices}
                                            >
                                                安全校验
                                            </AntdButton>
                                        </div>
                                    }
                                >
                                    <AntdButton
                                        className={classnames(
                                            styles2.comBtn,
                                            styles2.resetBtn
                                        )}
                                        onClick={this.checkDevices}
                                    >
                                        检测设备通信
                                    </AntdButton>
                                </Tooltip>
                            )}
                        </div>
                    }
                >
                    <div className={styles["bbbbbox"]}>
                        <div className={styles["current-connect-title-box"]}>
                            <div className={styles["current-connect-title"]}>
                                当前连接：{robotName ? robotName : "未连接"}
                            </div>
                            <div className={styles["current-connect-num"]}>
                                总连接人数：{onlineCount}
                            </div>
                        </div>
                        <div className={styles["robot-content-box"]}>
                            <ul>
                                {robotList.map((item, index) => (
                                    <li
                                        className={styles["robot-item"]}
                                        key={item.id}
                                    >
                                        <div className={styles["img-box"]}>
                                            <img src={item.imageUrl} />
                                            {item.status === 1 && (
                                                <div
                                                    className={
                                                        styles["mark-connect"]
                                                    }
                                                >
                                                    <i></i>当前连接
                                                </div>
                                            )}

                                            <div
                                                className={
                                                    styles["mark-masking"]
                                                }
                                            >
                                                {item.status === 0 && (
                                                    <div
                                                        className={
                                                            styles[
                                                                "mark-connect-btn"
                                                            ]
                                                        }
                                                        onClick={() =>
                                                            this.handleConnectionRobot(
                                                                item,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        连接
                                                    </div>
                                                )}
                                                {item.status === 1 && (
                                                    <div
                                                        className={
                                                            styles[
                                                                "mark-off-btn"
                                                            ]
                                                        }
                                                        onClick={() =>
                                                            this.handleDisconnectionRobot(
                                                                item,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        断开连接
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                styles["robot-detail-box"]
                                            }
                                        >
                                            <div
                                                className={styles["robot-name"]}
                                            >
                                                {item.robotName}
                                            </div>
                                            <div>{item.macAddr}</div>
                                            <div>
                                                已连接：{item.onlineCount}人
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {!robotList ||
                            (robotList.length <= 0 && (
                                <div className={styles["empty-state"]}>
                                    <div>
                                        <img src={noData} alt="nodata" />
                                        <div>暂无设备</div>
                                    </div>
                                </div>
                            ))}
                        {robotPageData && robotPageData.totalCount > 0 && (
                            <Pagination
                                current={pageNum}
                                onChange={this.onChange}
                                total={robotPageData.totalCount}
                            />
                        )}
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
    currentConnectrobotinfo: (info) => dispatch(currentConnectrobotinfo(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceModal);
