/* eslint-disable */
import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { setProjectTitle } from "../reducers/project-title";

import log from "../lib/log";
import sharedMessages from "../lib/shared-messages";

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload,
} from "../reducers/project-state";

import { openLoadingProject, closeLoadingProject } from "../reducers/modals";
import { closeFileMenu } from "../reducers/menus";
// import queryString from "query-string";

/**
 * SBFileUploader component passes a file input, load handler and props to its child.
 * It expects this child to be a function with the signature
 *     function (renderFileInput, handleLoadProject) {}
 * The component can then be used to attach project loading functionality
 * to any other component:
 *
 * <SBFileUploader>{(className, renderFileInput, handleLoadProject) => (
 *     <MyCoolComponent
 *         className={className}
 *         onClick={handleLoadProject}
 *     >
 *         {renderFileInput()}
 *     </MyCoolComponent>
 * )}</SBFileUploader>
 */

const messages = defineMessages({
    loadError: {
        id: "gui.projectLoader.loadError",
        defaultMessage: "The project file that was selected failed to load.",
        description:
            "An error that displays when a local project file fails to load.",
    },
});

class SBFileUploader extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "getProjectTitleFromFilename",
            "renderFileInput",
            "setFileInput",
            "handleChange",
            "handleClick",
            "onload",
            "resetFileInput",
            "loadSb3File",
            "renderImgInput",
            "handleFile", //图片转为线上地址
        ]);
    }

    loadSb3File() {
        var _this = this;
        // 作品所在存放地址
        var sb3Path = window.answerCodeUrl;
        //  ||"http://port-test-1255999742.file.myqcloud.com/userupload/scratch/0/1cba0aaa-6bc0-4be2-8bf8-b1beb7ede4ca.sb3";

        /**
         * 必须使用 $(window).on("load",function(){});
         * 否则页面在未加载完的情况下，有些组件会来不及加载，影响二次文件保存
         */
        let reader = new FileReader();
        let request = new XMLHttpRequest();
        request.open("GET", sb3Path, true);
        request.responseType = "blob";
        request.onload = function () {
            if (request.status == 404) {
                // alert("未找到sb3类型的资源文件");
                // location.href='/scratch';
            }
            let blobs = request.response;
            reader.readAsArrayBuffer(blobs);
            reader.onload = () =>
                _this.props.vm
                    .loadProject(reader.result)
                    .then(() => {
                        // 默认项目加载完成
                        window.parent.postMessage(
                            { act: "ddm-onready", msg: "show" },
                            "*"
                        );
                        _this.props.onLoadingFinished(_this.props.loadingState);
                    })
                    .catch((error) => {
                        log.warn(error);
                    });
        };
        request.send();
    }

    componentWillMount() {
        this.reader = new FileReader();
        this.reader.onload = this.onload;
        this.resetFileInput();
    }

    // 初始化sb3文件 必须放在这里
    componentDidMount() {
        var _this = this;
        // const { from } = queryString.parse(location.search);
        // console.log("isInitial", this.props);
        if (this.props.isInitial) {
            // 设置1s延时
            if (window.answerCodeUrl) {
                setTimeout(() => {
                    _this.loadSb3File();
                }, 1000);
            } else {
                setTimeout(() => {
                    // 默认项目加载完成
                    window.parent.postMessage(
                        { act: "ddm-onready", msg: "show" },
                        "*"
                    );
                }, 1000);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.isLoadingUpload &&
            !prevProps.isLoadingUpload &&
            this.fileToUpload &&
            this.reader
        ) {
            this.reader.readAsArrayBuffer(this.fileToUpload);
        }
    }
    componentWillUnmount() {
        this.reader = null;
        this.resetFileInput();
    }
    resetFileInput() {
        this.fileToUpload = null;
        if (this.fileInput) {
            this.fileInput.value = null;
        }
    }
    getProjectTitleFromFilename(fileInputFilename) {
        if (!fileInputFilename) return "";
        // only parse title with valid scratch project extensions
        // (.sb, .sb2, and .sb3)
        const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
        if (!matches) return "";
        return matches[1].substring(0, 100); // truncate project title to max 100 chars
    }
    // called when user has finished selecting a file to upload
    handleChange(e) {
        const {
            intl,
            isShowingWithoutId,
            loadingState,
            projectChanged,
            userOwnsProject,
        } = this.props;
        console.log(171, projectChanged, loadingState, userOwnsProject);
        const thisFileInput = e.target;
        if (thisFileInput.files) {
            // Don't attempt to load if no file was selected
            this.fileToUpload = thisFileInput.files[0];

            // If user owns the project, or user has changed the project,
            // we must confirm with the user that they really intend to replace it.
            // (If they don't own the project and haven't changed it, no need to confirm.)
            let uploadAllowed = true;
            if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
                console.log(18999111);
                uploadAllowed = confirm(
                    // eslint-disable-line no-alert
                    intl.formatMessage(sharedMessages.replaceProjectWarning)
                );
            }
            if (uploadAllowed) {
                console.log(189990000);
                sessionStorage.removeItem("draftId"); //“从电脑中上传”新sb3文件后，draftId清除
                sessionStorage.removeItem("sourceType");
                // sessionStorage.removeItem("DRAFT_TITLE");
                this.props.requestProjectUpload(loadingState);
            } else {
                console.log(189992222);
                this.props.closeFileMenu();
            }
        }
    }
    // called when file upload raw data is available in the reader
    onload() {
        if (this.reader) {
            this.props.onLoadingStarted();
            const filename = this.fileToUpload && this.fileToUpload.name;
            this.props.vm
                .loadProject(this.reader.result)
                .then(() => {
                    this.props.onLoadingFinished(this.props.loadingState, true);
                    // Reset the file input after project is loaded
                    // This is necessary in case the user wants to reload a project
                    if (filename) {
                        const uploadedProjectTitle =
                            this.getProjectTitleFromFilename(filename);
                        this.props.onReceivedProjectTitle(uploadedProjectTitle);
                    }
                    this.resetFileInput();
                    //获取封面
                    // let myCanvas = document.getElementById("stage-canvers");
                    // let imgDom = document.getElementById("img-dom");
                    // imgDom.src = myCanvas.toDataURL("image/png");
                    // imgDom.style.width = "100px";
                    // imgDom.style.height = "100px";
                    // console.log(76666666, myCanvas, imgDom);
                    // if (imgDom) {
                    //     let hidden = document.getElementById("file-hidden");
                    //     console.log(298, hidden);
                    //     hidden && hidden.click();
                    // }
                })
                .catch((error) => {
                    log.warn(error);
                    alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
                    this.props.onLoadingFinished(
                        this.props.loadingState,
                        false
                    );
                    // Reset the file input after project is loaded
                    // This is necessary in case the user wants to reload a project
                    this.resetFileInput();
                });
        }
    }
    handleClick() {
        // open filesystem browsing window
        console.log(28222);
        this.fileInput.click();
    }
    setFileInput(input) {
        this.fileInput = input;
    }
    //input file change事件
    handleFile(e) {
        let file = null;
        file = e.target.files[0];
        setOutputType(false);
        if (!file) {
            return;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;

        let fileLast = file.name.split(".")[1];
        if (
            fileLast !== "bmp" &&
            fileLast !== "jpg" &&
            fileLast !== "jpeg" &&
            fileLast !== "png"
        ) {
            message.warning("请选择正确的图片格式:png、jpg、jpeg、bmp。");
            return;
        }
        if (!isLt2M) {
            message.warning("上传图片大小不能超过 500Kb!");
            return;
        }
        console.log("通过！");
        setUploadFile(file);
        setUpLoadType(false);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            // setImageBase64(e.target.result);
            console.log("拿到图片utl地址");
            // setImageUrl(e.target.result);
        };
    }
    renderFileInput() {
        console.log(28111);
        return (
            <input
                accept=".sb,.sb2,.sb3"
                ref={this.setFileInput}
                style={{ display: "none" }}
                type="file"
                onChange={this.handleChange}
            />
        );
    }
    //canvas转为图片
    renderImgInput() {
        return (
            <input
                hidden
                type="file"
                accept="image/*"
                onChange={this.handleFile}
                style={{ display: "none" }}
                id="file-hidden"
            />
        );
    }
    render() {
        //获取封面
        // setTimeout(function () {
        //     let myCanvas = document.getElementById("stage-canvers");
        //     let imgDom = document.getElementById("img-dom");
        //     imgDom.src = myCanvas.toDataURL("image/png");
        //     imgDom.style.width = "100px";
        //     imgDom.style.height = "100px";
        //     console.log(76666666, myCanvas, imgDom);
        //     if (imgDom) {
        //         let hidden = document.getElementById("file-hidden");
        //         console.log(298, hidden);
        //         hidden && hidden.click();
        //     }
        // }, 2000);
        return this.props.children(
            this.props.className,
            // this.renderImgInput,
            this.renderFileInput,
            this.handleClick
        );
    }
}

SBFileUploader.propTypes = {
    canSave: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    children: PropTypes.func,
    className: PropTypes.string,
    closeFileMenu: PropTypes.func,
    intl: intlShape.isRequired,
    isLoadingUpload: PropTypes.bool,
    isShowingWithoutId: PropTypes.bool,
    loadingState: PropTypes.oneOf(LoadingStates),
    onLoadingFinished: PropTypes.func,
    onLoadingStarted: PropTypes.func,
    projectChanged: PropTypes.bool,
    requestProjectUpload: PropTypes.func,
    onReceivedProjectTitle: PropTypes.func,
    userOwnsProject: PropTypes.bool,
    isInitial: PropTypes.bool,
    vm: PropTypes.shape({
        loadProject: PropTypes.func,
    }),
};
SBFileUploader.defaultProps = {
    className: "",
    isInitial: false,
};
const mapStateToProps = (state) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        isLoadingUpload: getIsLoadingUpload(loadingState),
        isShowingWithoutId: getIsShowingWithoutId(loadingState),
        loadingState: loadingState,
        projectChanged: state.scratchGui.projectChanged,
        vm: state.scratchGui.vm,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    closeFileMenu: () => dispatch(closeFileMenu()),
    onLoadingFinished: (loadingState, success) => {
        dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
        dispatch(closeLoadingProject());
        dispatch(closeFileMenu());
    },
    requestProjectUpload: (loadingState) =>
        dispatch(requestProjectUpload(loadingState)),
    onLoadingStarted: () => dispatch(openLoadingProject()),
    onReceivedProjectTitle: (title) => dispatch(setProjectTitle(title)),
});

// Allow incoming props to override redux-provided props. Used to mock in tests.
const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, stateProps, dispatchProps, ownProps);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SBFileUploader));
