import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import bindAll from "lodash.bindall";
import {
    setPreShareShow,
    setShareTitle,
    setShareDesc,
} from "../reducers/login";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { Modal, Input, Button } from "antd";
import styles from "../components/works-upload-right/pre-share-dialog.css";
import { msToDate } from "../utils/index";
import headDefault from "../assets/img/head-default@2x.jpg";
import cookies from "js-cookie";
import playImg from "../assets/img/play.png";
import SB3Downloader from "./sb3-downloader.jsx";

const { TextArea } = Input;
const env = process.env.REACT_APP_ENV;
const frameUrl =
    env === "production"
        ? "https://play.dingdangcode.com/"
        : env === "development"
        ? "https://play.dingdangcode.cn/"
        : "http://localhost:8097/";
const appTarget = document.createElement("div");
appTarget.className = styles.app;
document.body.appendChild(appTarget);
const portraitUrl = cookies.get("portraitUrl");
const nickname = cookies.get("nickname");

class PreShareDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "valuesChange",
            "valuesChange1",
            "shareEve",
            "linkDevices",
        ]);
        this.state = {
            worksName: "",
            worksDesc: "",
        };
    }
    componentDidMount() {
        // this.queryEve(this.state.res);
    }
    valuesChange(e) {
        this.setState({
            worksName: e.target.value,
        });

        this.props.ShareTitleEve(e.target.value);
    }
    valuesChange1(e) {
        this.setState({
            worksDesc: e.target.value,
        });
        this.props.ShareDescEve(e.target.value);
    }
    shareEve(res) {
        console.log("分享名称", this.state.worksName);
        this.props.ShareTitleEve(this.state.worksName);
        // queryShareUserCode(res).then(({ data }) => {
        //     console.log("列表", data);
        //     if (data.code === 0) {
        //         this.props.PreShareShowEve();
        //     } else {
        //         message.error(data.msg);
        //     }
        // });
    }
    linkDevices(className, downloadProjectCallback) {
        // cookies.set("shareTitle", this.state.worksName);
        // cookies.set("shareDesc", this.state.worksDesc);
        console.log(222, this.props);
        downloadProjectCallback(4);
    }
    render() {
        const { PreShareShow, PreShareShowEve } = this.props;
        const { worksName, worksDesc } = this.state;
        return (
            <Modal
                title="预览效果"
                className={classNames(styles.preModalWra)}
                width={728}
                visible={PreShareShow}
                onOk={this.shareEve}
                onCancel={PreShareShowEve}
                // cancelText="取消"
                // okText="确定"
                footer={
                    <div style={{ textAlign: "right" }}>
                        <Button
                            onClick={PreShareShowEve}
                            style={{ marginRight: 16, height: 40, width: 104 }}
                        >
                            取消
                        </Button>
                        <SB3Downloader>
                            {(className, downloadProjectCallback) => (
                                <Button
                                    onClick={() =>
                                        this.linkDevices(
                                            className,
                                            downloadProjectCallback
                                        )
                                    }
                                    style={{
                                        height: 40,
                                        width: 104,
                                        background: "#3a7dff",
                                        color: "#fff",
                                    }}
                                >
                                    确定
                                </Button>
                            )}
                        </SB3Downloader>
                    </div>
                }
            >
                <div className={classNames(styles.preWra)}>
                    <div className={classNames(styles.preLeft)}>
                        <div className={classNames(styles.preCon)}>
                            <div className={classNames(styles.leftTop)}>
                                <img
                                    src="https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg"
                                    alt
                                />
                                <div className={classNames(styles.leftTopBg)}>
                                    <img src={playImg} alt />
                                </div>
                            </div>
                            <div className={classNames(styles.leftBottom)}>
                                <div
                                    className={classNames(styles.bottomHeader)}
                                >
                                    <img src={portraitUrl || headDefault} alt />
                                    <div
                                        className={classNames(styles.headerCon)}
                                    >
                                        <div
                                            className={classNames(
                                                styles.headerName
                                            )}
                                        >
                                            {nickname}
                                        </div>
                                        <div
                                            className={classNames(
                                                styles.headerTime
                                            )}
                                        >
                                            {
                                                msToDate(new Date().getTime())
                                                    .hasTime
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={classNames(styles.bottomDesc)}>
                                    <div
                                        className={classNames(styles.descName)}
                                    >
                                        {worksName === ""
                                            ? "未命名"
                                            : worksName}
                                    </div>
                                    <div
                                        className={classNames(styles.descTitle)}
                                    >
                                        作品描述
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.descDetail
                                        )}
                                    >
                                        {worksDesc === ""
                                            ? "作者太懒，什么都没有留下~"
                                            : worksDesc}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.preRight)}>
                        <div className={classNames(styles.rightOne)}>
                            <div className={classNames(styles.rightLabel)}>
                                作品名称
                            </div>
                            <Input
                                // prefix={<UserOutlined />}
                                placeholder="请输入您的作品名称"
                                value={worksName}
                                onChange={this.valuesChange}
                                style={{ height: 40, lineHeight: "30px" }}
                            />
                        </div>
                        <div className={classNames(styles.rightOne)}>
                            <div className={classNames(styles.rightLabel)}>
                                作品描述
                            </div>
                            <TextArea
                                rows={4}
                                placeholder="请输入您的作品描述"
                                value={worksDesc}
                                onChange={this.valuesChange1}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

PreShareDialog.propTypes = {
    PreShareShowEve: PropTypes.func,
    ShareTitleEve: PropTypes.func,
    ShareDescEve: PropTypes.func,

    // onQuestionAnswered: PropTypes.func.isRequired,
    // question: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    // console.log(6056, state.scratchGui.login, ownProps);
    return {
        PreShareShow: state.scratchGui.login.PreShareShow,
    };
};

const mapDispatchToProps = (dispatch) => ({
    PreShareShowEve: () => dispatch(setPreShareShow(false)),
    ShareTitleEve: (res) => dispatch(setShareTitle(res)),
    ShareDescEve: (res) => dispatch(setShareDesc(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(PreShareDialog);
// export default LoginModal;
