import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import VM from "scratch-vm-dingdangcode";

import costumeLibraryContent from "../lib/libraries/costumes.json";
import spriteTags from "../lib/libraries/sprite-tags";
import LibraryComponent from "../components/library/library.jsx";
import { querySprBackCosData } from "../api/apiServer/apiCommon";
import { compose } from "redux";
import { connect } from "react-redux";

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: "Choose a Costume",
        description: "Heading for the costume library",
        id: "gui.costumeLibrary.chooseACostume",
    },
});

class CostumeLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, ["handleItemSelected"]);
        this.state = {
            costumesJson: [],
        };
    }
    componentDidMount() {
        if (this.props.costumesJson) {
            querySprBackCosData(this.props.costumesJson).then(({ data }) => {
                this.setState({
                    costumesJson: data,
                });
            });
        }
    }
    handleItemSelected(item) {
        const split = item.md5.split(".");
        const type = split.length > 1 ? split[1] : null;
        const rotationCenterX =
            type === "svg" ? item.info[0] : item.info[0] / 2;
        const rotationCenterY =
            type === "svg" ? item.info[1] : item.info[1] / 2;
        const vmCostume = {
            name: item.name,
            rotationCenterX,
            rotationCenterY,
            bitmapResolution: item.info.length > 2 ? item.info[2] : 1,
            skinId: null,
        };
        this.props.vm.addCostumeFromLibrary(item.md5, vmCostume);
    }
    render() {
        console.log(999900, costumeLibraryContent);
        const { costumesJson } = this.state;
        return (
            <LibraryComponent
                data={costumesJson}
                // data={costumeLibraryContent}
                id="costumeLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelected}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

CostumeLibrary.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};
const mapStateToProps = (state) => {
    console.log(222222, state);
    return {
        costumesJson: state.scratchGui.login.costumesJson,
    };
};

const mapDispatchToProps = (dispatch) => ({
    // onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    // drawShowEve: () => dispatch(setpublishDrawShow(true)),
    // dispatchDraftTitle: (res) => dispatch(changeDraftTitle(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(CostumeLibrary);
// export default injectIntl(CostumeLibrary);
