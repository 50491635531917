import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import bindAll from "lodash.bindall";
import {
    setDradtListShow,
    setPreShareShow,
    setShareShow,
} from "../reducers/login";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { Modal, Form, Input, message, Pagination, Radio, Button } from "antd";
import styles from "../components/works-upload-right/share-dialog.css";
import {
    queryGetDraftList,
    queryDeleteDraft,
    queryShareUserCode,
    queryShareUserCodeBySms,
} from "../api/apiServer/apiCommon";
import { msToDate, winOpen } from "../utils/index";
import cookies from "js-cookie";
import playImg from "../assets/img/play.png";
import logoicon from "../assets/img/logoicon.png";
import copy from "copy-to-clipboard";
import { validatePhone } from "../utils/validate";
import QRCode from "qrcode";
// import { CopyToClipboard } from "react-copy-to-clipboard";

const { TextArea } = Input;
const env = process.env.REACT_APP_ENV;
const frameUrl =
    env === "production"
        ? "https://play.dingdangcode.com/"
        : env === "development"
        ? "https://play.dingdangcode.cn/"
        : "http://localhost:8097/";
const appTarget = document.createElement("div");
appTarget.className = styles.app;
document.body.appendChild(appTarget);

class PreShareDialog extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["coptyEve", "sendEve", "valuesChange", "createQRcode"]); //, "handleCopy"
        this.state = {
            phoneNum: cookies.get("mobile"),
            shareMobileUrl: "",
        };
    }
    componentWillReceiveProps(nextProps) {
        console.log(
            "虎丘到的id之1",
            cookies.get("shareId"),
            this.props.shareId
        );
        const env = process.env.NODE_ENV;
        if (env == "production") {
            this.setState({
                shareMobileUrl: `https://m.dingdangcode.com/work-hall/detail?type=scratch&worksId=${cookies.get(
                    "shareId"
                )}&sto=mobile&sf=playground`, //this.props.shareId
            });
            // this.qrCodeUrl = `https://m.dingdangcode.com/work-hall/detail?type=play&worksId=${this.shareId}&sto=mobile&sf=playground`;
            // this.shareSMSUrl = `https://m.dingdangcode.com/work-hall/detail?type=play&worksId=${this.shareId}&sto=sms&sf=playground`;
            // this.shareMobileUrl = `https://m.dingdangcode.com/work-hall/detail?type=play&worksId=${this.props.shareId}&sto=mobile&sf=playground`;
        } else {
            //m.dingdangcode.com/work-hall/detail?type=play&worksId=2631&sto=mobile&sf=playground
            this.setState({
                shareMobileUrl: `https://m.dingdangcode.cn/work-hall/detail?type=scratch&worksId=${cookies.get(
                    "shareId"
                )}&sto=mobile&sf=playground`, //this.props.shareId
            });
            // this.qrCodeUrl = `http://m.dingdangcode.cn/work-hall/detail?type=play&worksId=${this.shareId}&sto=mobile&sf=playground`;
            // this.shareSMSUrl = `http://m.dingdangcode.cn/work-hall/detail?type=play&worksId=${this.shareId}&sto=sms&sf=playground`;
            // this.shareMobileUrl = `http://m.dingdangcode.cn/work-hall/detail?type=play&worksId=${this.shareId}&sto=mobile&sf=playground`;
        }
    }
    componentDidUpdate() {
        this.createQRcode();
    }
    valuesChange(e) {
        this.setState({
            phoneNum: e.target.value,
        });
    }
    //二维码分享
    createQRcode() {
        let canvas = document.getElementById("canvas");
        if (canvas) {
            QRCode.toCanvas(
                canvas,
                this.state.shareMobileUrl,
                function (error) {
                    if (error) console.error(error);
                    console.log("success!");
                }
            );
        }
    }
    coptyEve(e) {
        // cookies.remove("shareId");
        // cookies.remove("publishCode");
        console.log("保存", e.target);
        copy(this.state.shareMobileUrl);
    }
    sendEve() {
        // cookies.remove("shareId");
        // cookies.remove("publishCode");
        let data = {
            phoneNumber: this.state.phoneNum,
            keywords: `${this.props.shareTitle}，点击链接查看：${this.state.shareMobileUrl}`,
        };
        if (!validatePhone(this.state.phoneNum)) {
            message.warning("请输入正确的手机号码！");
            return;
        }
        queryShareUserCodeBySms(data).then(({ data }) => {
            console.log("列表", data);
            if (data.code === 0) {
                this.props.ShareShowEve();
                message.success("发送成功");
            } else {
                message.error(data.msg);
            }
        });
    }
    //生成二维码
    // createQRcode() {
    //     let canvas = document.getElementById("canvas");
    //     QRCode.toCanvas(canvas, this.state.shareMobileUrl, function (error) {
    //         if (error) console.error(error);
    //         console.log("success!");
    //     });
    // }
    render() {
        const { ShareShow, ShareShowEve, shareEve } = this.props;
        const { phoneNum, shareMobileUrl } = this.state;
        return (
            <Modal
                className={styles.shareWra}
                width={448}
                visible={ShareShow}
                onOk={shareEve}
                onCancel={() => {
                    this.props.ShareShowEve();
                    cookies.remove("shareId");
                    cookies.remove("publishCode");
                }}
                footer={null}
            >
                <div className={classNames(styles.preWra)}>
                    <div className={classNames(styles.shareTitle)}>
                        <div className={classNames(styles.shareTitleLine)}>
                            ------------------------
                        </div>
                        <div className={classNames(styles.shareTitleText)}>
                            QQ/微信分享
                        </div>
                        <div className={classNames(styles.shareTitleLine)}>
                            ------------------------
                        </div>
                    </div>
                    <div className={classNames(styles.shareImg)}>
                        <div className={classNames(styles.shareImgInner)}>
                            <canvas id="canvas"></canvas>
                            {/* 待生成canvas  class="qrCode-canvas"*/}
                            <img src={logoicon} alt="" />
                        </div>
                    </div>
                    <div className={classNames(styles.shareTitle)}>
                        <div className={classNames(styles.shareTitleLine)}>
                            ----------------------------
                        </div>
                        <div className={classNames(styles.shareTitleText)}>
                            链接分享
                        </div>
                        <div className={classNames(styles.shareTitleLine)}>
                            ----------------------------
                        </div>
                    </div>
                    <div className={classNames(styles.shareDetail)}>
                        <div className={classNames(styles.detailLeft)}>
                            <TextArea
                                readOnly
                                value={shareMobileUrl}
                                style={{ minHeight: "40px", resize: "none" }}
                            />
                        </div>
                        <Button
                            type="primary"
                            onClick={this.coptyEve}
                            style={{ height: "40px" }}
                        >
                            复制链接
                        </Button>
                    </div>
                    <div className={classNames(styles.shareTitle)}>
                        <div className={classNames(styles.shareTitleLine)}>
                            ----------------------------
                        </div>
                        <div className={classNames(styles.shareTitleText)}>
                            短信分享
                        </div>
                        <div className={classNames(styles.shareTitleLine)}>
                            ----------------------------
                        </div>
                    </div>
                    <div className={classNames(styles.shareDetail)}>
                        <div className={classNames(styles.detailLeft)}>
                            <Input
                                placeholder="请输入手机号，链接将通过短信发送。"
                                value={phoneNum}
                                onChange={this.valuesChange}
                                style={{ height: 40, lineHeight: "30px" }}
                            />
                        </div>
                        <Button
                            type="primary"
                            onClick={this.sendEve}
                            style={{ height: "40px" }}
                        >
                            发送短信
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

PreShareDialog.propTypes = {
    ShareShowEve: PropTypes.func,
    // onQuestionAnswered: PropTypes.func.isRequired,
    // question: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    return {
        ShareShow: state.scratchGui.login.ShareShow,
        shareTitle: state.scratchGui.login.shareTitle,
        shareId: state.scratchGui.login.shareId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    ShareShowEve: () => dispatch(setShareShow(false)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(PreShareDialog);
// export default LoginModal;
