import Axios from "../apiConfig/axios";
import { apiFragment } from "../apiConfig/apiBase";
import { buildGET } from "../../utils/index";

//获取机器人列表
export const getRobotListByIp = () => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/getRobotListByIp?IP=125.69.90.238`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

export const getConnectionRobot = (id) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/connectionRobot/${id}`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

export const getDisconnectionRobot = (id) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/disconnectionRobot/${id}`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

export const getValidateGrantCode = (data) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/validateGrantCode?id=${data.id}&grantCode=${data.grantCode}`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

export const getRobotPage = (data) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/getRobotPage?pageNum=${data.pageNum}&pageSize=${data.pageSize}&ip=125.69.90.238`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

export const getRobotStatus = (id) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/checkRobotStatus/${id}`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

export const addLearnRecord = (payload) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcCustomer}/shop/add/goods/learn/record`,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

export const queryLearnRecord = (payload) => {
    try {
        let url = buildGET(
            `${apiFragment.ddcCustomer}/shop/query/goods/learn/record`,
            payload
        );
        return Axios.request({
            url,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 登录
 * @param {number} loginWay thirdWay 1账号、 2手机、3邮箱、4QQ、 5微信公众号(1：账号+密码,2、3：占位,4：QQ授权码,5：公众号授权码)
 * @param {string} code
 * @param {string} password
 * @param {string} username
 * @return {Object} 返回值描述
 */
export const login = (payload) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcPort}/play/login`,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 退出登录
 * @return {Object} 返回值描述
 */

export const logout = () => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/logout`);
        return Axios.request({
            url,
            method: "POST",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 上传作品
 * @return {Object} 返回值描述
 */

export const postWork = (payload) => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/postWork`);
        return Axios.request({
            url,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 用户获取草稿列表
 * @return {Object} 返回值描述
 */
export const queryGetDraftList = (payload) => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/getDraftList`, payload);
        return Axios.request({
            url,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 用户删除草稿
 * @return {Object} 返回值描述
 */
export const queryDeleteDraft = (payload) => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/deleteDraft`, payload);
        return Axios.request({
            url,
            method: "DELETE",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 用户获取草稿详情
 * @return {Object} 返回值描述
 */
export const queryGetDraftDetail = (payload) => {
    try {
        let url = buildGET(
            `${apiFragment.ddcPort}/play/getDraftDetail`,
            payload
        );
        return Axios.request({
            url,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 获取用户信息
 * @return {Object} 返回值描述
 */
export const queryGetUserinfo = () => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/userinfo`);
        return Axios.request({
            url,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 将当前代码分享
 * @return {Object} 返回值描述
 */
export const queryShareUserCode = (payload) => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/shareUserCode`);
        return Axios.request({
            url,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 短信分享
 * @return {Object} 返回值描述
 */
export const queryShareUserCodeBySms = (payload) => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/shareUserCodeBySms`);
        return Axios.request({
            url,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 埋点
 * @return {Object} 返回值描述
 */
export const buriedTracking = (payload) => {
    try {
        let url = buildGET(`${apiFragment.ddcBase}/log/buried/front`); //${apiFragment.ddcHadoop}/buried/buried
        return Axios.request({
            url,
            method: "POST",
            data: payload,
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 图形化编辑器配置信息
 * @return {Object} 返回值描述
 */
export const queryEditorConfig = () => {
    try {
        let url = buildGET(`${apiFragment.ddcPort}/play/block/editorConfig`);
        return Axios.request({
            url,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};

/**
 * 角色，造型，背景json数据
 * @return {Object} 返回值描述
 */
export const querySprBackCosData = (data) => {
    try {
        // let url = buildGET(`${apiFragment.ddcPort}/play/block/editorConfig`);
        let url = buildGET(`${data}`);
        return Axios.request({
            url,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};
