import Axios from "../apiConfig/axios";
import { apiFragment } from "../apiConfig/apiBase";

/**
 * @description 获取上传密钥
 * @param {*} key
头像:user_portrai
海龟图片文件:user_turtle
3D gcode文件:user_3d
反馈:user_feedback
作品:user_works
创客:user_maker
素材:user_sucai
题目海龟图片:problem_turtl

 * @returns
 */

export const getGipher = (key) => {
    try {
        return Axios.request({
            url: `${apiFragment.ddcBase}/file/getGipher/${key}`,
            method: "GET",
        });
    } catch (e) {
        throw e; // 抛出异常
    }
};
