const OPEN_MENU = "scratch-gui/menus/OPEN_MENU";
const CLOSE_MENU = "scratch-gui/menus/CLOSE_MENU";

const MENU_ACCOUNT = "accountMenu";
const MENU_FILE = "fileMenu";
const MENU_EDIT = "editMenu";
const MENU_LANGUAGE = "languageMenu";
const MENU_LOGIN = "loginMenu";

const IS_HIDE_MENU = "scratch-gui/menus/IS_HIDE_MENU";

const initialState = {
    [MENU_ACCOUNT]: false,
    [MENU_FILE]: false,
    [MENU_EDIT]: false,
    [MENU_LANGUAGE]: false,
    [MENU_LOGIN]: false,
    isHideMenu: false,
};

const reducer = function (state, action) {
    if (typeof state === "undefined") state = initialState;
    switch (action.type) {
        case OPEN_MENU:
            return Object.assign({}, state, {
                [action.menu]: true,
            });
        case CLOSE_MENU:
            return Object.assign({}, state, {
                [action.menu]: false,
            });
        case IS_HIDE_MENU:
            return Object.assign({}, state, {
                isHideMenu: action.isHideMenu,
            });
        default:
            return state;
    }
};
const openMenu = (menu) => ({
    type: OPEN_MENU,
    menu: menu,
});
const closeMenu = (menu) => ({
    type: CLOSE_MENU,
    menu: menu,
});

const watchHideMenu = (status) => ({
    type: IS_HIDE_MENU,
    isHideMenu: status,
});

const openAccountMenu = () => openMenu(MENU_ACCOUNT);
const closeAccountMenu = () => closeMenu(MENU_ACCOUNT);
const accountMenuOpen = (state) => state.scratchGui.menus[MENU_ACCOUNT];
const openFileMenu = () => openMenu(MENU_FILE);
const closeFileMenu = () => closeMenu(MENU_FILE);
const fileMenuOpen = (state) => state.scratchGui.menus[MENU_FILE];
const openEditMenu = () => openMenu(MENU_EDIT);
const closeEditMenu = () => closeMenu(MENU_EDIT);
const editMenuOpen = (state) => state.scratchGui.menus[MENU_EDIT];
const openLanguageMenu = () => openMenu(MENU_LANGUAGE);
const closeLanguageMenu = () => closeMenu(MENU_LANGUAGE);
const languageMenuOpen = (state) => state.scratchGui.menus[MENU_LANGUAGE];
const openLoginMenu = () => openMenu(MENU_LOGIN);
const closeLoginMenu = () => closeMenu(MENU_LOGIN);
const loginMenuOpen = (state) => state.scratchGui.menus[MENU_LOGIN];

export {
    reducer as default,
    initialState as menuInitialState,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    watchHideMenu,
};
