import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import bindAll from "lodash.bindall";
// import queryString from "query-string";
import { queryString } from "../utils/index";
import {
    setDradtListShow,
    setDataList,
    setTotalData,
    changeDraftTitle,
    setDraftId,
} from "../reducers/login";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { Modal, Form, Input, Button, message, Pagination, Radio } from "antd";
import styles from "../components/works-upload-right/draft-list.css";
import {
    queryGetDraftList,
    queryDeleteDraft,
} from "../api/apiServer/apiCommon";
import { msToDate, winOpen } from "../utils/index";
import nodataImg from "../assets/img/nodata.png";
import VM from "scratch-vm-dingdangcode";
import cookies from "js-cookie";

// const query = queryString.parse(location.search);

const { Search } = Input;
const env = process.env.NODE_ENV; //process.env.REACT_APP_ENV;
// const frameUrl =
//     env === "production"
//         ? "https://play.dingdangcode.com/"
//         : env === "development"
//         ? "https://play.dingdangcode.cn/"
//         : "http://localhost:8097/";
const frameUrl =
    env === "production"
        ? "https://play.dingdangcode.com/"
        : "https://play.dingdangcode.cn/";
class DraftList extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "pageOnChange",
            "onSearch",
            "delDraft",
            "del",
            "deleteCourse",
            "radioChange",
            "cancelEve",
            "goPlay",
            "goScractDetail",
        ]);
        // "handleChange", "handleKeyPress", "handleSubmit";
        this.state = {
            isModalVisible: true,
            dataList: [],
            totalData: {},
            res: {
                keywords: "",
                pageNum: 1,
                pageSize: 6,
                type: [2],
            },
            delId: "",
            deleteVisible: false,
            options: [
                { label: "Scratch", value: "Scratch" },
                { label: "Python", value: "Python" },
            ],
            radioValue: "Scratch",
        };
    }
    componentDidMount() {
        // this.queryEve(this.state.res);
    }
    queryEve(res) {
        queryGetDraftList(res).then(({ data }) => {
            console.log("列表", data);
            if (data.code === 0) {
                // this.setState({
                //     dataList: data.data.list,
                //     totalData: data.data,
                // });
                this.props.draftDataListEve(data.data.list);
                this.props.draftTotalDataEve(data.data);
            } else {
                message.error(data.msg);
            }
        });
    }
    pageOnChange(value) {
        console.log(222, this.state.res);
        this.queryEve({ ...this.state.res, pageNum: value });
    }
    onSearch(value) {
        console.log(111);
        this.setState({
            res: {
                ...this.state.res,
                keywords: value,
            },
        });
        this.queryEve({ ...this.state.res, keywords: value, pageNum: 1 });
    }
    delDraft(id) {
        console.log("id:::");
        this.setState({
            delId: id,
            deleteVisible: true,
        });
        this.props.draftListShowEve1();
    }
    del(id) {
        console.log("删除", id);
    }
    cancelEve() {
        this.setState({
            deleteVisible: false,
        });
    }
    radioChange(e) {
        this.setState({
            radioValue: e.target.value,
            res: {
                ...this.state.res,
                type: e.target.value === "Scratch" ? 2 : [0, 1],
                pageNum: 1,
            },
        });
        if (e.target.value === "Scratch") {
            this.queryEve({
                ...this.state.res,
                type: [2],
            });
        } else {
            this.queryEve({
                keywords: "",
                pageNum: 1,
                pageSize: 6,
                type: [0, 1],
            });
        }
    }
    deleteCourse() {
        console.log("this", this);
        queryDeleteDraft(this.state.delId).then(({ data }) => {
            console.log("列表", data);
            if (data.code === 0) {
                this.queryEve(this.state.res);
                this.setState({
                    deleteVisible: false,
                });
                this.props.draftListShowEve1();
                message.success("删除草稿成功");
            } else {
                message.error(data.msg);
            }
        });
    }
    // 清空时 移除 输出区域dom元素
    removeDom() {
        let aa = document.querySelectorAll("body");
        aa.innerHTML = "";
        let outputContainer = document.querySelectorAll(
            "div.draft-list_app_2HTJt"
        );
        console.log("获取到的值", outputContainer);
        if (outputContainer && outputContainer.length > 0) {
            for (let i = 0, len = outputContainer.length; i < len; i++) {
                outputContainer[i].parentNode.removeChild(outputContainer[i]);
            }
            return true;
        } else {
            return false;
        }
    }
    //去试炼场
    goPlay(id) {
        console.log("环境变量", process);
        // winOpen(frameUrl + "?id=" + id);
        winOpen(frameUrl + "?draftId=" + id + "&draftType=0");
    }
    goScractDetail(item) {
        if (item.fileUrl) {
            window.answerCodeUrl = item.fileUrl;
        }
        // let { redirectUrl } = queryString();
        // let str = window.location.href;
        // let items = str.split("&adapt=adapt");
        // //会得到一个数组，数组中包括利用o分割后的多个字符串（不包括o）
        // let newStr = items.join(""); //数组转成字符串,元素是通过指定的分隔符进行分隔的。此时以空串分割：即直接连接
        // console.log(1855555 + newStr);
        // history.pushState("", "", newStr);
        console.log(
            186666,
            item,
            window.answerCodeUrl,
            queryString(),
            window.location.href
        );
        this.props.draftListShowEve();
        this.props.draftTitleEve(item.title);
        this.props.draftIdEve(item.id);
        sessionStorage.setItem("draftId", item.id);
        sessionStorage.setItem("sourceType", item.sourceType);
        cookies.set("DRAFT_TITLE", item.title);
        cookies.set("draftId", item.id);
        // const url =
        //     "https://port-release-1255999742.file.myqcloud.com/teach/ddm/book/编程思维课/第一课/节点6-随堂练1.sb3";
        fetch(item.fileUrl, {
            method: "GET",
        })
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onload = () =>
                    this.props.vm.loadProject(reader.result).then(() => {
                        // 重置sb3文件
                        console.log("这是啥", reader.result);
                        // this.props.onResetProject(false);
                    });
                reader.readAsArrayBuffer(blob);
            })
            .catch((error) => {
                console.log(`远程加载文件错误！${error}`);
            });
        //重新渲染之前删除之前的类名为app的节点
        // document.body.innerHTML = "";
        //重新渲染scratch页面
        // const appTarget = document.createElement("div");
        // appTarget.className = styles.app;
        // appTarget.id = "listId";
        // document.body.appendChild(appTarget);
        // require("../playground/render-gui.jsx").default(appTarget);
    }
    render() {
        const { deleteVisible, options, radioValue } = this.state;
        const { draftDataList, draftTotalData } = this.props;
        return (
            <div>
                <Modal
                    title="草稿"
                    width={852}
                    visible={this.props.draftListShow}
                    // onOk={this.props.draftListShowEve}
                    onCancel={this.props.draftListShowEve}
                    footer={null}
                >
                    <div className={classNames(styles.topWra)}>
                        <Radio.Group
                            options={options}
                            onChange={this.radioChange}
                            value={radioValue}
                            optionType="button"
                            style={{ height: 40 }}
                        />
                        <Search
                            placeholder="请输入草稿名称搜索"
                            onSearch={this.onSearch}
                            style={{ width: 200, height: 40 }}
                        />
                    </div>
                    {draftDataList.length <= 0 && (
                        <div className={classNames(styles.emptyState)}>
                            <img src={nodataImg} alt />
                            <div>暂无草稿</div>
                        </div>
                    )}
                    {radioValue === "Scratch" ? (
                        <div className={classNames(styles.listMenuScr)}>
                            {draftDataList.length > 0 &&
                                draftDataList.map((item, index) => (
                                    <div
                                        className={classNames(
                                            styles.listMenuLiScr
                                        )}
                                        key={index}
                                    >
                                        <img
                                            src={item.cover}
                                            onClick={() =>
                                                this.goScractDetail(item)
                                            }
                                            // src="https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg"
                                        />
                                        <div
                                            className={classNames(
                                                styles.liScrRight
                                            )}
                                        >
                                            <div
                                                className={classNames(
                                                    styles.rightTop
                                                )}
                                            >
                                                {item.title}
                                            </div>
                                            <div
                                                className={classNames(
                                                    styles.rightLeft
                                                )}
                                            >
                                                保存于
                                                {
                                                    msToDate(item.createTime)
                                                        .hasTime
                                                }
                                            </div>
                                            <div
                                                className={classNames(
                                                    styles.rightBottom
                                                )}
                                            >
                                                <div
                                                    className={classNames(
                                                        styles.rightRight
                                                    )}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        this.delDraft(item);
                                                    }}
                                                >
                                                    删除
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div className={classNames(styles.listMenu)}>
                            {draftDataList.length > 0 &&
                                draftDataList.map((item, index) => (
                                    <div
                                        className={classNames(
                                            styles.listMenuLi
                                        )}
                                        key={index}
                                    >
                                        <div
                                            className={classNames(styles.liTop)}
                                            onClick={() => this.goPlay(item.id)}
                                        >
                                            <div
                                                className={classNames(
                                                    styles.topTitle
                                                )}
                                            >
                                                {item.title}
                                            </div>
                                            <div
                                                className={classNames(
                                                    styles.topDesc
                                                )}
                                            >
                                                {item.codeList &&
                                                    item.codeList[0]
                                                        .fileContent}
                                            </div>
                                        </div>
                                        <div
                                            className={classNames(
                                                styles.liBottom
                                            )}
                                        >
                                            <div
                                                className={classNames(
                                                    styles.bottomLeft
                                                )}
                                                onClick={() => this.del(item)}
                                            >
                                                保存于
                                                {
                                                    msToDate(item.createTime)
                                                        .hasTime
                                                }
                                            </div>
                                            <div
                                                className={classNames(
                                                    styles.bottomRight
                                                )}
                                                onClick={() =>
                                                    this.delDraft(item)
                                                }
                                            >
                                                删除
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                    <div className={classNames(styles.pageCls)}>
                        <Pagination
                            current={draftTotalData && draftTotalData.currPage}
                            onChange={this.pageOnChange}
                            pageSize={draftTotalData && draftTotalData.pageSize}
                            total={draftTotalData && draftTotalData.totalCount}
                            hideOnSinglePage={true}
                        />
                    </div>
                </Modal>
                <Modal
                    title="删除草稿"
                    visible={deleteVisible}
                    onOk={this.deleteCourse}
                    onCancel={this.cancelEve}
                    cancelText="取消"
                    okText="确定"
                    // getContainer={false}
                    width={470}
                >
                    <p>删除后不可恢复，确定要删除该草稿么？</p>
                </Modal>
            </div>
        );
    }
}

DraftList.propTypes = {
    draftListShowEve: PropTypes.func,
    draftDataListEve: PropTypes.func,
    draftTotalDataEve: PropTypes.func,
    draftListShowEve1: PropTypes.func,
    draftTitleEve: PropTypes.func,
    draftIdEve: PropTypes.func,
    // vm: PropTypes.instanceOf(VM).isRequired,
    vm: PropTypes.shape({
        loadProject: PropTypes.func,
    }),
    // onQuestionAnswered: PropTypes.func.isRequired,
    // question: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    // console.log(6056, state.scratchGui.login.draftListShow);
    return {
        draftListShow: state.scratchGui.login.draftListShow,
        draftDataList: state.scratchGui.login.draftDataList,
        draftTotalData: state.scratchGui.login.draftTotalData,
        // draftTitle: state.scratchGui.login.draftTitle,
        // draftId: state.scratchGui.login.draftId,
        vm: state.scratchGui.vm,
    };
};

const mapDispatchToProps = (dispatch) => ({
    draftListShowEve: () => dispatch(setDradtListShow(false)),
    draftListShowEve1: () => dispatch(setDradtListShow(true)),
    draftDataListEve: (res) => dispatch(setDataList(res)),
    draftTitleEve: (res) => dispatch(changeDraftTitle(res)),
    draftIdEve: (res) => dispatch(setDraftId(res)),
    draftTotalDataEve: (res) => dispatch(setTotalData(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(DraftList);
// export default LoginModal;
