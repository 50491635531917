import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import Box from "../box/box.jsx";
import styles from "./blocks.css";

const BlocksComponent = (props) => {
    const { containerRef, dragOver, ...componentProps } = props;
    return (
        <div>
            <div>99999999</div>
            <Box
                className={classNames(styles.blocks, {
                    [styles.dragOver]: dragOver,
                })}
                {...componentProps}
                componentRef={containerRef}
            />
        </div>
    );
};
BlocksComponent.propTypes = {
    containerRef: PropTypes.func,
    dragOver: PropTypes.bool,
};
export default BlocksComponent;
