import { defineMessages } from "react-intl";

// 自定义tag字段, 需要结合reducer/locales 调整
export default defineMessages({
    all: {
        defaultMessage: "All",
        description: "Tag for filtering a library for everything",
        id: "gui.libraryTags.all",
    },
    animals: {
        defaultMessage: "Animals",
        description: "Tag for filtering a library for animals",
        id: "gui.libraryTags.animals",
    },
    dance: {
        defaultMessage: "Dance",
        description: "Tag for filtering a library for dance",
        id: "gui.libraryTags.dance",
    },
    effects: {
        defaultMessage: "Effects",
        description: "Tag for filtering a library for effects",
        id: "gui.libraryTags.effects",
    },
    fantasy: {
        defaultMessage: "Fantasy",
        description: "Tag for filtering a library for fantasy",
        id: "gui.libraryTags.fantasy",
    },
    fashion: {
        defaultMessage: "Fashion",
        description: "Tag for filtering a library for fashion",
        id: "gui.libraryTags.fashion",
    },
    food: {
        defaultMessage: "Food",
        description: "Tag for filtering a library for food",
        id: "gui.libraryTags.food",
    },
    indoors: {
        defaultMessage: "Indoors",
        description: "Tag for filtering a library for indoors",
        id: "gui.libraryTags.indoors",
    },
    loops: {
        defaultMessage: "Loops",
        description: "Tag for filtering a library for loops",
        id: "gui.libraryTags.loops",
    },
    music: {
        defaultMessage: "Music",
        description: "Tag for filtering a library for music",
        id: "gui.libraryTags.music",
    },
    notes: {
        defaultMessage: "Notes",
        description: "Tag for filtering a library for notes",
        id: "gui.libraryTags.notes",
    },
    outdoors: {
        defaultMessage: "Outdoors",
        description: "Tag for filtering a library for outdoors",
        id: "gui.libraryTags.outdoors",
    },
    patterns: {
        defaultMessage: "Patterns",
        description: "Tag for filtering a library for patterns",
        id: "gui.libraryTags.patterns",
    },
    people: {
        defaultMessage: "People",
        description: "Tag for filtering a library for people",
        id: "gui.libraryTags.people",
    },
    percussion: {
        defaultMessage: "Percussion",
        description: "Tag for filtering a library for percussion",
        id: "gui.libraryTags.percussion",
    },
    space: {
        defaultMessage: "Space",
        description: "Tag for filtering a library for space",
        id: "gui.libraryTags.space",
    },
    sports: {
        defaultMessage: "Sports",
        description: "Tag for filtering a library for sports",
        id: "gui.libraryTags.sports",
    },
    underwater: {
        defaultMessage: "Underwater",
        description: "Tag for filtering a library for underwater",
        id: "gui.libraryTags.underwater",
    },
    voice: {
        defaultMessage: "Voice",
        description: "Tag for filtering a library for voice",
        id: "gui.libraryTags.voice",
    },
    wacky: {
        defaultMessage: "Wacky",
        description: "Tag for filtering a library for wacky",
        id: "gui.libraryTags.wacky",
    },
    animation: {
        defaultMessage: "Animation",
        description: "Tag for filtering a library for animation",
        id: "gui.libraryTags.animation",
    },
    art: {
        defaultMessage: "Art",
        description: "Tag for filtering a library for art",
        id: "gui.libraryTags.art",
    },
    games: {
        defaultMessage: "Games",
        description: "Tag for filtering a library for games",
        id: "gui.libraryTags.games",
    },
    stories: {
        defaultMessage: "Stories",
        description: "Tag for filtering a library for stories",
        id: "gui.libraryTags.stories",
    },
    letters: {
        defaultMessage: "Letters",
        description: "Tag for filtering a library for letters",
        id: "gui.libraryTags.letters",
    },
    dingdangcode: {
        defaultMessage: "叮当码", //"dingdangcode2",
        description: "Tag for filtering a library for dingdangcode",
        id: "gui.libraryTags.dingdangcode",
    },
});
