var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".custom-button_custom-btn_2vaUt {\n    width: 178px;\n    height: 90px;\n    background-image: url(" + escape(require("./image/btn-small-normal@2x.png")) + ");\n    background-position: center center;\n    background-size: cover;\n    background-repeat: no-repeat;\n    overflow: hidden;\n    text-align: center;\n    line-height: 90px;\n    font-size: 28px;\n    font-weight: 500;\n    color: rgba(255, 255, 255, 1);\n    cursor: pointer;\n    display: inline-block;\n}\n.custom-button_custom-btn_2vaUt:hover {\n    background-image: url(" + escape(require("./image/btn-small-hover@2x.png")) + ");\n}\n.custom-button_custom-btn_2vaUt ~ .custom-button_custom-btn_2vaUt {\n    margin-left: 40px;\n}\n", ""]);

// exports
exports.locals = {
	"custom-btn": "custom-button_custom-btn_2vaUt",
	"customBtn": "custom-button_custom-btn_2vaUt"
};