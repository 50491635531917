/**  */
import React from "react";
import { Modal } from "antd";
import styles from "./custom-modal.css";

const CustomModal = (props) => {
    const { width = "9.62rem", style, ratio = 1.3588, styleBody = {} } = props;
    return (
        <Modal
            className={styles.customModal}
            footer={null}
            centered
            style={
                style
                    ? { height: `calc(${width}/${ratio})`, ...style }
                    : { height: `calc(${width}/${ratio})` }
            }
            closeIcon={<i className={styles.customCloseIcon}></i>}
            {...props}
        >
            <div className={styles.customBody} style={{ ...styleBody }}>
                {modalChildren(props.children)}
            </div>
        </Modal>
    );
};

/**
 * @description modal分层设计，遍历dom，寻找指定dom，并添加属性
 * @param {*} children
 * @returns
 */
function modalChildren(children) {
    let newChild = null;
    if (Array.isArray(children)) {
        // key===back时为dom添加一个classname
        newChild = children.map((item) =>
            item.key === "back"
                ? React.cloneElement(item, { className: "custom-item-back" })
                : item
        );
    } else if (children && children.key === "back") {
        newChild = React.cloneElement(children, {
            className: "custom-item-back",
        });
    } else {
        newChild = children;
    }
    return newChild;
}

export default CustomModal;
