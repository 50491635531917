import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
import WorksComponent from "../components/works-upload/works-upload.jsx";
// import cookies from "js-cookie";

class Works extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleChange",
            "handleKeyPress",
            "handleSubmit",
            "handleSubmit1",
        ]);
        this.state = {
            answer: "",
        };
    }
    handleChange(e) {
        this.setState({ answer: e.target.value });
    }
    handleKeyPress(event) {
        if (event.key === "Enter") this.handleSubmit();
    }
    handleSubmit() {
        //this.props.onQuestionAnswered(this.state.answer); //父级监听子级的事件
    }
    handleSubmit1() {
        console.log(222);
    }
    render() {
        return (
            <WorksComponent
                answer={this.state.answer}
                question={this.props.question}
                onChange={this.handleChange}
                onClick={this.handleSubmit}
                onClick1={this.handleSubmit1}
                onKeyPress={this.handleKeyPress}
                ib64={this.props.ib64}
            />
        );
    }
}

Works.propTypes = {
    onQuestionAnswered: PropTypes.func.isRequired,
    question: PropTypes.string,
    ib64: PropTypes.string,
};

export default Works;
