import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
import {
    setLoginModalShow,
    setUserInfo,
    changeUserName,
    changePassWord,
    setpublishDrawShow,
} from "../reducers/login";
import { requestLearnRecordFinish } from "../api/apiServer/apiUser";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import {
    Modal,
    Form,
    Input,
    Button,
    message,
    Drawer,
    Radio,
    Switch,
} from "antd";
// import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { postWork } from "../api/apiServer/apiCommon"; //login,
import cookies from "js-cookie";
import queryString from "query-string";

const { seriesId, courseId, classId, pointId } = queryString.parse(
    location.search
);
const { TextArea } = Input;
const sourceType = sessionStorage.getItem("sourceType");
class DrawModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "onFinish",
            "onClose",
            "valuesChange",
            "publishEve",
            "valuesChange1",
            "onChange1",
            "handleChange",
        ]);
        // "handleChange", "handleKeyPress", "handleSubmit";
        this.state = {
            isModalVisible: true,
            worksName: "33",
            worksDesc: "",
            value1: 1,
        };
    }
    componentWillReceiveProps(newProps) {
        console.log("发布作品新植", newProps, this.props);
        this.setState({
            worksName: newProps.draftTitle,
        });
    }
    valuesChange(value) {
        console.log("切换", value);
        if (value.username) {
            console.log("用户明换成那个", value.username);
            // this.props.userNameEve(value.username);
            //dispatch(usernameAction(value.username));
        }
    }
    valuesChange1(value) {}
    onFinish(values) {
        console.log("完成", values);
        const that = this;
        setTimeout(function () {
            const aa = {
                cover: that.props.canvasUrl
                    ? that.props.canvasUrl
                    : "https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg", // "https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg", //测试：暂是固定的封面
                description: "",
                draftId: sessionStorage.getItem("draftId"), //this.props.draftId, //cookies.get("draftId"),
                title: "",
            };
            let res = {
                ...aa,
                title: values.title,
                description: values.description,
                fileUrl: that.props.publishCode, //需测试： cookies.get("publishCode"),
                sourceType: values.sourceType,
                publishCode: values.publishCode ? 1 : 0,
            };
            console.log("res", res);
            postWork(res).then(({ data }) => {
                if (data.code === 0) {
                    console.log("登录成功后的数据", data);
                    message.success("发布成功，可在创作空间中查看【立即查看】");
                    that.props.drawShowEve();
                    if (classId) {
                        requestLearnRecordFinish({
                            classId: classId,
                            courseId: courseId,
                            pointId: pointId,
                            seriesId: seriesId,
                            worksId: data.data,
                            worksFileUrl: that.props.publishCode,
                        }).then(({ data }) => {
                            cookies.remove("pointId");
                            //课程结束
                            if (data.code === 0) {
                                console.log("课程结束", data);
                            }
                        });
                    }
                } else {
                    message.error(data.msg);
                }
            });
        }, 1000);

        // dispatch(loginSubmit(values));
    }
    onFinishFailed(values) {
        console.log("失败", values);
    }
    onClose() {
        this.props.drawShowEve();
    }
    publishEve() {
        console.log("确定");
        this.onFinish;
    }
    onChange1(e) {
        console.log("radio checked", e.target.value);
        this.setState({
            value1: e.target.value,
        });
    }
    handleChange(checked) {}
    render() {
        /* 使用课程表单 */
        const { adapt } = queryString.parse(location.search);

        return (
            <Drawer
                title="发布作品"
                width={396}
                onClose={this.onClose}
                visible={this.props.drawShow}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={this.props.drawShowEve}
                            style={{ marginRight: 8 }}
                        >
                            取消
                        </Button>
                        <Button onClick={this.onFinish} type="primary">
                            确定
                        </Button>
                    </div>
                }
            >
                <Form
                    // hideRequiredMark={true}
                    // form={courseForm}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    className="form-items"
                    layout="horizontal"
                    size={"small"}
                    labelAlign="left"
                    // initialValues={data}
                    initialValues={{
                        publishCode: true,
                        title: "",
                        description: "",
                        sourceType:
                            sourceType && sourceType === 1
                                ? 1
                                : sourceType && sourceType === 2
                                ? 2
                                : adapt === "adapt"
                                ? 2
                                : 1,
                        cover: this.props.canvasUrl
                            ? this.props.canvasUrl
                            : "https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg",
                    }}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        label="作品名称"
                        className="form-item"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "请输入作品名称",
                            },
                        ]}
                    >
                        <Input
                            placeholder="请输入作品名称"
                            maxLength={10}
                            value={this.state.worksName}
                            // this.state.worksName
                            onChange={this.valuesChange}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="cover"
                        label="封面预览"
                        // validateStatus={titleServerJudge.status}
                        // help={titleServerJudge.help}
                    >
                        <img
                            // src={item.coverUrl}
                            src={
                                this.props.canvasUrl
                                    ? this.props.canvasUrl
                                    : "https://port-release-1255999742.file.myqcloud.com/static/works/pygameBgc.jpg"
                            }
                            style={{
                                width: "224px",
                                height: "138px",
                                borderRadius: "12px",
                            }}
                            alt=""
                        />
                    </Form.Item>
                    <Form.Item
                        label="作品说明"
                        className="form-item"
                        name="description"
                        rules={[
                            {
                                required: false,
                                message: "必选项需要填写作品名称！",
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="介绍下你的作品吧"
                            cols={8}
                            value={this.state.worksDesc}
                            onChange={this.valuesChange1}
                            rules={[
                                {
                                    required: false,
                                    message: "必选项需要填写作品简介！",
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name="sourceType"
                        label="作品来源"
                        // validateStatus={titleServerJudge.status}
                        // help={titleServerJudge.help}
                    >
                        <Radio.Group
                            onChange={this.onChange1}
                            value={this.state.value1}
                        >
                            <Radio
                                value={1}
                                disabled={adapt === "adapt" ? true : false}
                            >
                                原创
                            </Radio>
                            <Radio
                                value={2}
                                disabled={adapt === "adapt" ? false : true}
                            >
                                改编
                            </Radio>
                            <Radio
                                value={3}
                                disabled={adapt === "adapt" ? true : false}
                            >
                                转载
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="publishCode"
                        valuePropName="checked"
                        label="允许改编"
                        style={{ marginBottom: 0 }}
                    >
                        <Switch onChange={this.handleChange} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                height: 32,
                                width: "64px",
                                position: "fixed",
                                bottom: "10px",
                                right: "10px",
                                zIndex: 9,
                            }}
                        >
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        );
    }
}

DrawModal.propTypes = {
    modalShowEve: PropTypes.func,
    userNameEve: PropTypes.func,
    passWordEve: PropTypes.func,
    userInfoEve: PropTypes.func,
    // onQuestionAnswered: PropTypes.func.isRequired,
    // question: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        modalShow: state.scratchGui.login.loginModalShow,
        userName: state.scratchGui.login.username,
        password: state.scratchGui.login.password,
        userInfo: state.scratchGui.login.userInfo,
        drawShow: state.scratchGui.login.publishDrawShow,
        publishCode: state.scratchGui.login.publishCode,
        draftId: state.scratchGui.login.draftId,
        draftTitle: state.scratchGui.login.draftTitle,
        canvasUrl: state.scratchGui.login.canvasUrl,
    };
};

const mapDispatchToProps = (dispatch) => ({
    modalShowEve: () => dispatch(setLoginModalShow(false)),
    userNameEve: (name) => dispatch(changeUserName(name)),
    passWordEve: (psw) => dispatch(changePassWord(psw)),
    userInfoEve: (info) => dispatch(setUserInfo(info)),
    drawShowEve: () => dispatch(setpublishDrawShow(false)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(DrawModal);
// export default LoginModal;
