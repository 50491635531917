import PropTypes from "prop-types";
import React from "react";
import bindAll from "lodash.bindall";
import {
    setLoginModalShow,
    setUserInfo,
    changeUserName,
    changePassWord,
    setPortraitUrl,
    setToken,
} from "../reducers/login";
import { connect } from "react-redux";
import { compose } from "redux";
import { domainToken } from "../utils";
import { injectIntl } from "react-intl";
import { Modal, Form, Input, Button, message } from "antd";
// import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login, queryGetUserinfo, logout } from "../api/apiServer/apiCommon";
import cookies from "js-cookie";

class LoginModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["onFinish"]);
        // "handleChange", "handleKeyPress", "handleSubmit";
        this.state = {
            isModalVisible: true,
            loading: false,
        };
    }
    componentWillReceiveProps(newProps) {
        console.log("新植", newProps, this.props);
    }
    valuesChange(value) {
        if (value.username) {
            console.log("用户明换成那个", value.username);
            // this.props.userNameEve(value.username);
            //dispatch(usernameAction(value.username));
        }
    }
    onFinish(values) {
        console.log("完成", values);
        this.setState({
            loading: true,
        });
        login(values).then(({ data }) => {
            if (data.code === 0) {
                this.setState({
                    loading: false,
                });
                console.log("登录成功后的数据", data);
                sessionStorage.setItem("token", data.data.token); //SCRATCH_TOKEN
                // sessionStorage.setItem("PLAY_TOKEN", data.data.token);
                // cookies.set("PLAY_TOKEN", data.data.token);
                cookies.set("token", data.data.token); //SCRATCH_TOKEN
                domainToken("token", data.data.token).set();
                this.props.tokenEve(data.data.token);
                cookies.remove("DRAFT_TITLE");
                cookies.remove("draftId");
                cookies.remove("shareId");
                cookies.remove("publishCode");
                cookies.remove("shareTitle");
                cookies.remove("shareDesc");
                sessionStorage.removeItem("draftId");
                sessionStorage.removeItem("sourceType");
                sessionStorage.removeItem("DRAFT_TITLE");
                this.props.modalShowEve;
                //刷新页面
                // window.location.replace(
                //     window.location.origin + window.location.pathname
                // );
                setTimeout(() => {
                    queryGetUserinfo().then(({ data }) => {
                        if (data.code === 0) {
                            // domainToken("nickname", data.data.token).set();
                            // domainToken("mobile", data.data.token).set();
                            // domainToken("portraitUrl", data.data.token).set();
                            cookies.set("nickname", data.data.nickname);
                            cookies.set("mobile", data.data.mobile);
                            cookies.set("portraitUrl", data.data.portraitUrl);
                            this.props.portraitUrlEve(data.data.portraitUrl);
                            this.props.modalShowEve();
                        }
                    });
                }, 100);
            } else {
                message.error(data.msg);
            }
        });
        // dispatch(loginSubmit(values));
    }
    onFinishFailed(values) {
        console.log("失败", values);
    }
    render() {
        const { loading } = this.state;
        return (
            <Modal
                title="登录"
                visible={
                    // {this.state.isModalVisible}
                    this.props.modalShow
                }
                onOk={this.props.modalShowEve}
                onCancel={this.props.modalShowEve}
                footer={null}
            >
                <Form
                    name="normal_login"
                    className="login-form"
                    // initialValues={{
                    //     username: this.props.username,
                    //     password: this.props.password,
                    // }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    onValuesChange={this.valuesChange}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: "请输入6-19位账号",
                                min: 6,
                                max: 19,
                            },
                        ]}
                    >
                        <Input
                            // prefix={<UserOutlined />}
                            placeholder="请输入您的账号/手机号/邮箱"
                            style={{ height: 40, lineHeight: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "请输入6-16位密码",
                                min: 6,
                                max: 16,
                            },
                        ]}
                    >
                        <Input
                            // prefix={<LockOutlined />}
                            type="password"
                            placeholder="请输入您的密码"
                            style={{
                                height: 40,
                                lineHeight: "30px",
                                boxShadow: "0 0 0px 1000px white inset",
                            }}
                        />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ height: 40, width: "100%" }}
                            loading={loading}
                        >
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

LoginModal.propTypes = {
    modalShowEve: PropTypes.func,
    modalShowEve1: PropTypes.func,
    userNameEve: PropTypes.func,
    passWordEve: PropTypes.func,
    userInfoEve: PropTypes.func,
    portraitUrlEve: PropTypes.func,
    tokenEve: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    // console.log(6056, state.scratchGui.login, ownProps);
    return {
        modalShow: state.scratchGui.login.loginModalShow,
        userName: state.scratchGui.login.username,
        password: state.scratchGui.login.password,
        userInfo: state.scratchGui.login.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => ({
    modalShowEve: () => dispatch(setLoginModalShow(false)),
    modalShowEve1: () => dispatch(setLoginModalShow(true)),
    userNameEve: (name) => dispatch(changeUserName(name)),
    passWordEve: (psw) => dispatch(changePassWord(psw)),
    userInfoEve: (info) => dispatch(setUserInfo(info)),
    portraitUrlEve: (info) => dispatch(setPortraitUrl(info)),
    tokenEve: (info) => dispatch(setToken(info)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(LoginModal);
// export default LoginModal;
