import ScratchBlocks from "scratch-blocks-dingdangcode";
import { Base64 } from "js-base64";
import queryString from "query-string";
import { defaultBlocks } from "../playground/initialBlocks";
const { auth } = queryString.parse(location.search);

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const customBlocksFunc = function () {
    let blocks = {};
    switch (auth) {
        case "2":
            for (let p in window.customblocks_) {
                // 用于编写评测代码
                console.log("motion1");
                blocks[p] = Base64.decode(window.customblocks_[p]);
            }
            break;
        case "1":
            for (let p in window.customblocks_) {
                // 用于录屏
                console.log("motion2");
                blocks[p] = Base64.decode(window.customblocks_[p]).replace(
                    /<block type="motion_dd_broadcast" id="motion_dd_broadcast">(([\s\S])*?)<\/block>/,
                    ""
                );
            }
            break;
        default:
            for (let p in defaultBlocks) {
                // 用于录屏
                blocks[p] = Base64.decode(defaultBlocks[p]);
            }
            break;
    }
    return blocks;
};
/* eslint-disable no-unused-vars */
const motion = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        "MOTION_STAGE_SELECTED",
        "Stage selected: no motion blocks"
    );
    // console.log("运动生成页面", customBlocksFunc().motionblock);
    // return `
    // <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
    //     ${eval(customBlocksFunc().motionblock)}
    // </category>
    // `;
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${eval(customBlocksFunc().motionblock)}
    </category>
    `;
};

const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, (c) => {
        switch (c) {
            case "<":
                return "&lt;";
            case ">":
                return "&gt;";
            case "&":
                return "&amp;";
            case "'":
                return "&apos;";
            case '"':
                return "&quot;";
        }
    });
};

const looks = function (
    isInitialSetup,
    isStage,
    targetId,
    costumeName,
    backdropName
) {
    const hello = ScratchBlocks.ScratchMsgs.translate("LOOKS_HELLO", "Hello!");
    const hmm = ScratchBlocks.ScratchMsgs.translate("LOOKS_HMM", "Hmm...");
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${eval(customBlocksFunc().looksblock)}
    </category>
    `;
};

const sound = function (isInitialSetup, isStage, targetId, soundName) {
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
    ${eval(customBlocksFunc().soundblock)}
    </category>
    `;
};

const events = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        ${eval(customBlocksFunc().eventsblock)}
    </category>
    `;
};

const control = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        ${eval(customBlocksFunc().controlblock)}
    </category>
    `;
};

const sensing = function (isInitialSetup, isStage) {
    const name = ScratchBlocks.ScratchMsgs.translate(
        "SENSING_ASK_TEXT",
        "What's your name?"
    );
    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${eval(customBlocksFunc().sensingblock)}
    </category>
    `;
};

// const operators = function (isInitialSetup) {
//     const apple = ScratchBlocks.ScratchMsgs.translate(
//         "OPERATORS_JOIN_APPLE",
//         "apple"
//     );
//     const banana = ScratchBlocks.ScratchMsgs.translate(
//         "OPERATORS_JOIN_BANANA",
//         "banana"
//     );
//     const letter = ScratchBlocks.ScratchMsgs.translate(
//         "OPERATORS_LETTEROF_APPLE",
//         "a"
//     );
//     console.log("运算2：", eval(customBlocksFunc().operatorsblock));
//     return `
//     <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
//         ${eval(customBlocksFunc().operatorsblock)}
//     </category>
//     `;
// };

const operators = function (isInitialSetup) {
    const apple = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_APPLE",
        "apple"
    );
    const banana = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_BANANA",
        "banana"
    );
    const letter = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_LETTEROF_APPLE",
        "a"
    );
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        ${eval(customBlocksFunc().operatorsblock)}
    </category>
    `;
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};
/* eslint-enable no-unused-vars */

const xmlOpen = '<xml style="display: none">';
const xmlClose = "</xml>";

/**
 * @param {!boolean} isInitialSetup - Whether the toolbox is for initial setup. If the mode is "initial setup",
 * blocks with localized default parameters (e.g. ask and wait) should not be loaded. (LLK/scratch-gui#5445)
 * @param {?boolean} isStage - Whether the toolbox is for a stage-type target. This is always set to true
 * when isInitialSetup is true.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension / category.
 * @param {?string} costumeName - The name of the default selected costume dropdown.
 * @param {?string} backdropName - The name of the default selected backdrop dropdown.
 * @param {?string} soundName -  The name of the default selected sound dropdown.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
const makeToolboxXML = function ({
    isInitialSetup,
    isStage = true,
    targetId,
    categoriesXML = [],
    costumeName = "",
    backdropName = "",
    soundName = "",
    customBlocks = {},
}) {
    isStage = isInitialSetup || isStage;
    const gap = [categorySeparator];
    costumeName = xmlEscape(costumeName);
    backdropName = xmlEscape(backdropName);
    soundName = xmlEscape(soundName);
    categoriesXML = categoriesXML.slice();
    const moveCategory = (categoryId) => {
        const index = categoriesXML.findIndex(
            (categoryInfo) => categoryInfo.id === categoryId
        );
        if (index >= 0) {
            // remove the category from categoriesXML and return its XML
            const [categoryInfo] = categoriesXML.splice(index, 1);
            return categoryInfo.xml;
        }
        // return `undefined`
    };
    const motionXML = customBlocksFunc().motionblock
        ? moveCategory("motion") || motion(isInitialSetup, isStage, targetId)
        : null;
    const looksXML = customBlocksFunc().looksblock
        ? moveCategory("looks") ||
          looks(isInitialSetup, isStage, targetId, costumeName, backdropName)
        : null;
    const soundXML = customBlocksFunc().soundblock
        ? moveCategory("sound") ||
          sound(isInitialSetup, isStage, targetId, soundName)
        : null;
    const eventsXML = customBlocksFunc().eventsblock
        ? moveCategory("event") || events(isInitialSetup, isStage, targetId)
        : null;
    const controlXML = customBlocksFunc().controlblock
        ? moveCategory("control") || control(isInitialSetup, isStage, targetId)
        : null;
    const sensingXML = customBlocksFunc().sensingblock
        ? moveCategory("sensing") || sensing(isInitialSetup, isStage, targetId)
        : null;
    const operatorsXML = customBlocksFunc().operatorsblock
        ? moveCategory("operators") ||
          operators(isInitialSetup, isStage, targetId)
        : null;
    const variablesXML = !auth
        ? moveCategory("data") || variables(isInitialSetup, isStage, targetId)
        : null;
    const myBlocksXML = !auth
        ? moveCategory("procedures") ||
          myBlocks(isInitialSetup, isStage, targetId)
        : null;

    const everything = [
        xmlOpen,
        motionXML,
        gap,
        looksXML,
        gap,
        soundXML,
        gap,
        eventsXML,
        gap,
        controlXML,
        gap,
        sensingXML,
        gap,
        operatorsXML,
        gap,
        variablesXML,
        gap,
        myBlocksXML,
    ];

    for (const extensionCategory of categoriesXML) {
        everything.push(gap, extensionCategory.xml);
    }

    everything.push(xmlClose);
    return everything.join("\n");
};

export default makeToolboxXML;
