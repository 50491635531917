import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape, defineMessages } from "react-intl";
import VM from "scratch-vm-dingdangcode";

import spriteLibraryContent from "../lib/libraries/sprites.json";
import randomizeSpritePosition from "../lib/randomize-sprite-position";
import spriteTags from "../lib/libraries/sprite-tags";
import { querySprBackCosData } from "../api/apiServer/apiCommon";
import { compose } from "redux";
import { connect } from "react-redux";

import LibraryComponent from "../components/library/library.jsx";

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: "Choose a Sprite",
        description: "Heading for the sprite library",
        id: "gui.spriteLibrary.chooseASprite",
    },
});

class SpriteLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, ["handleItemSelect"]);
        this.state = {
            spriteJson: [],
        };
    }
    handleItemSelect(item) {
        // Randomize position of library sprite
        randomizeSpritePosition(item);
        this.props.vm.addSprite(JSON.stringify(item.json)).then(() => {
            this.props.onActivateBlocksTab();
        });
    }
    componentDidMount() {
        if (this.props.spriteJson) {
            querySprBackCosData(this.props.spriteJson).then(({ data }) => {
                console.log("列表666666", data);
                this.setState({
                    spriteJson: data,
                });
            });
        }
    }
    render() {
        console.log("角色JSON：", spriteLibraryContent); //在此文件添加角色(上传角色素材):先在scrtach导出sprit3格式的文件。将svg格式上传至this.assetHost地址，前端填json文件里面的内容
        const { spriteJson } = this.state;
        return (
            <LibraryComponent
                data={spriteJson}
                // data={spriteLibraryContent}
                id="spriteLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

SpriteLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateBlocksTab: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
};
const mapStateToProps = (state, ownProps) => {
    console.log(222222, state);
    return {
        hideMenu: state.scratchGui.login.login,
        draftTitle: state.scratchGui.login.draftTitle,
        draftId: state.scratchGui.login.draftId,
        spriteJson: state.scratchGui.login.spriteJson,
    };
};

const mapDispatchToProps = (dispatch) => ({
    // onSeeCommunity1: () => dispatch(setLoginModalShow(true)),
    // drawShowEve: () => dispatch(setpublishDrawShow(true)),
    // dispatchDraftTitle: (res) => dispatch(changeDraftTitle(res)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(SpriteLibrary);

// export default injectIntl(SpriteLibrary);
