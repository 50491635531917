import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import VM from "scratch-vm-dingdangcode";
import { connect } from "react-redux";
import queryString from "query-string";
import { message } from "antd";
import { Base64 } from "js-base64";
import cookies from "js-cookie";

import ControlsComponent from "../components/controls/controls.jsx";

function reUrl(info, generateId) {
    const url = `${info.inIp
        .replace("https://", "wss://")
        .replace(":8085", ":8087")}/ws?id=${generateId}`;
    console.log("url:===========", url);
    return url;
}
class Controls extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleGreenFlagClick",
            "handleStopAllClick",
            "moveStepsHandler",
            "boardTurnLeftHandler",
            "boardTurnRightHandler",
            "createWebSocket",
            "initEventHandle",
            "reconnect",
            "getRobotId",
            "disciTimeHandler", //测试：学科融合
        ]);
        this.state = {
            motionsParams: null,
            ws: null,
            lockReconnect: false,
            generateId: 0,
        };
    }

    componentDidMount() {
        let that = this;
        console.log("vm", this.props.vm);
        this.props.vm.boardMoveSteps(this.moveStepsHandler);
        this.props.vm.boardTurnLeft(this.boardTurnLeftHandler);
        this.props.vm.boardTurnRight(this.boardTurnRightHandler);
        //this.props.vm.boardDisciTime(this.disciTimeHandler); //测试：学科融合  的完成时间  测试先取消
        // 监听浏览器关闭
        window.onbeforeunload = function () {
            that.state.ws && that.state.ws.close();
        };
    }

    handleGreenFlagClick(e) {
        console.log("机器人信息：", this.props.robotinfo);
        // if (this.props.robotinfo && this.props.robotinfo.inIp) {
        //     this.getRobotId().then((res) => {
        //         console.log(res);
        //         this.setState({ generateId: res }, () => {
        //             this.createWebSocket();
        //         });
        //     });
        // }
        const query = queryString.parse(location.search);
        // 记录绿旗数量，用于新手任务评测（特殊情况）
        const flags = document.querySelectorAll(
            "g[data-category=events][data-shapes=hat]"
        );
        // console.log(
        //     document.querySelectorAll("g[data-id=event_whenflagclicked]")
        // );
        window.sessionStorage.setItem("greenFlagNum", flags ? flags.length : 0);
        if (
            flags.length <= 3 &&
            decodeURIComponent(query.unitName) === "新手任务"
        ) {
            window.parent.postMessage(
                { act: "ddm-message", msg: "请拖动绿旗到代码区" },
                "*"
            );
            return;
        }
        e.preventDefault();
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
        } else {
            if (!this.props.isStarted) {
                this.props.vm.start();
            }
            this.props.vm.greenFlag();
        }
    }
    handleStopAllClick(e) {
        e.preventDefault();
        this.props.vm.stopAll();
    }

    // 移动积木块事件监听
    moveStepsHandler(_props) {
        this.setState({ motionsParams: _props }, () => {
            console.log("继续执行12", this.state.ws);
            // this.state.ws && this.sendMessage();
            this.getRobotId().then((res) => {
                console.log(111, res);
                this.setState({ generateId: res }, () => {
                    this.createWebSocket();
                });
            });
        });
        console.log("108", JSON.stringify(_props));
    }
    // 测试：“学科融合”积木块事件监听
    disciTimeHandler(_props) {
        this.setState({ motionsParams: _props }, () => {
            console.log("继续执行11", this.state.ws);
            // this.getRobotId().then((res) => {
            //     console.log(res);
            //     this.setState({ generateId: res }, () => {
            //         this.createWebSocket();
            //     });
            // });
        });
        console.log("学科融合", JSON.stringify(_props), _props);
        cookies.set("disci_time", _props.steps);
        cookies.set("subJectType", "3");
    }
    // 向左积木块事件监听
    boardTurnLeftHandler(_props) {
        this.setState({ motionsParams: _props }, () => {
            console.log("继续执行");
            // this.state.ws && this.sendMessage();
            this.getRobotId().then((res) => {
                console.log(res);
                this.setState({ generateId: res }, () => {
                    this.createWebSocket();
                });
            });
        });
        console.log(JSON.stringify(_props));
    }

    // 向右积木块事件监听
    boardTurnRightHandler(_props) {
        this.setState({ motionsParams: _props }, () => {
            console.log("继续执行");
            // this.state.ws && this.sendMessage();
            this.getRobotId().then((res) => {
                console.log(res);
                this.setState({ generateId: res }, () => {
                    this.createWebSocket();
                });
            });
        });
        console.log(JSON.stringify(_props));
    }

    getRobotId() {
        return new Promise((resolve, reject) => {
            const { robotinfo } = this.props;
            const { motionsParams } = this.state;
            let url = robotinfo.inIp.replace(":8085", ":8087");
            const json = JSON.stringify({
                code: Base64.encode(JSON.stringify(motionsParams)),
            });
            fetch(url + "/robot", {
                body: json,
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then((res) => {
                    console.log(res);
                    if (res.code === 0) {
                        resolve(res.data.id);
                    } else {
                        reject(res.msg);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    // 创建webSocket
    createWebSocket() {
        let self = this;
        const { robotinfo } = self.props;
        const { generateId } = self.state;
        try {
            if ("WebSocket" in window) {
                let ws = new WebSocket(reUrl(robotinfo, generateId));
                console.log(ws);
                self.setState({ ws }, () => {
                    self.initEventHandle();
                });
            }
        } catch (e) {
            self.reconnect();
        }
    }
    // 监听websocket通信
    sendMessage() {
        const { ws, motionsParams } = this.state;
        if (motionsParams && typeof motionsParams === "object") {
            console.log("eeeeee=========", ws);
            ws.send && ws.send(JSON.stringify({ code: motionsParams }));
        }
    }

    initEventHandle() {
        let that = this;
        const { ws, motionsParams } = that.state;
        console.log("motionsParams=======:", motionsParams);
        ws.onopen = function () {
            that.sendMessage();
        };
        ws.onclose = function () {
            ws.close();
        };
        ws.onmessage = function (event) {
            // 获取消息
            console.log(event);
        };
        ws.onerror = function () {
            console.log("连接失败！！！");
            that.reconnect();
        };
    }

    // 尝试重新连接
    reconnect() {
        let that = this;
        const { robotinfo } = that.props;
        const { lockReconnect } = that.state;
        let timer = null;
        if (lockReconnect) return;
        that.setState({ lockReconnect: true });
        timer = setTimeout(function () {
            // 没连接上会一直重连，设置延迟避免请求过多
            console.info("尝试重连..." + new Date());
            that.createWebSocket();
            that.setState({ lockReconnect: false });
            clearTimeout(timer);
        }, 2000);
    }

    render() {
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            projectRunning,
            turbo,
            ...props
        } = this.props;
        return (
            <ControlsComponent
                {...props}
                active={projectRunning}
                turbo={turbo}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    robotinfo: PropTypes.any,
    vm: PropTypes.instanceOf(VM),
};

const mapStateToProps = (state) => {
    return {
        isStarted: state.scratchGui.vmStatus.running,
        projectRunning: state.scratchGui.vmStatus.running,
        turbo: state.scratchGui.vmStatus.turbo,
        robotinfo: state.scratchGui.customData.robotinfo,
        vm: state.scratchGui.vm,
    };
};
// no-op function to prevent dispatch prop being passed to component
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
