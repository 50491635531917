/* eslint-disable */
import { addLocaleData } from "react-intl";

import { localeData } from "scratch-l10n";
import editorMessages from "scratch-l10n/locales/editor-msgs";
import { isRtl } from "scratch-l10n";
import { customZhCn } from "../utils/custom_zh-cn";

// console.log(JSON.stringify(editorMessages["zh-cn"]));

addLocaleData(localeData);

const UPDATE_LOCALES = "scratch-gui/locales/UPDATE_LOCALES";
const SELECT_LOCALE = "scratch-gui/locales/SELECT_LOCALE";

/* 初始化 设置中文 */
const initialState = {
    isRtl: false, // 设置舞台区位置
    locale: "zh-cn",
    messagesByLocale: editorMessages,
    messages: { ...editorMessages["zh-cn"], ...customZhCn }, //扩展角色分类
};
const reducer = function (state, action) {
    if (typeof state === "undefined") state = initialState;
    switch (action.type) {
        case SELECT_LOCALE:
            return Object.assign({}, state, {
                isRtl: isRtl(action.locale),
                locale: action.locale,
                messagesByLocale: state.messagesByLocale,
                messages: state.messagesByLocale[action.locale],
            });
        case UPDATE_LOCALES:
            return Object.assign({}, state, {
                isRtl: state.isRtl,
                locale: state.locale,
                messagesByLocale: action.messagesByLocale,
                messages: action.messagesByLocale[state.locale],
            });
        default:
            return state;
    }
};

const selectLocale = function (locale) {
    return {
        type: SELECT_LOCALE,
        locale: locale,
    };
};

const setLocales = function (localesMessages) {
    return {
        type: UPDATE_LOCALES,
        messagesByLocale: localesMessages,
    };
};
const initLocale = function (currentState, locale) {
    if (currentState.messagesByLocale.hasOwnProperty(locale)) {
        return Object.assign({}, currentState, {
            isRtl: isRtl(locale),
            locale: locale,
            messagesByLocale: currentState.messagesByLocale,
            messages: currentState.messagesByLocale[locale],
        });
    }
    // don't change locale if it's not in the current messages
    return currentState;
};
export {
    reducer as default,
    initialState as localesInitialState,
    initLocale,
    selectLocale,
    setLocales,
};
