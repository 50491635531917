// api基础域名
export const apiBaseUrl = {
    api_base_url:
        process.env.NODE_ENV === "development"
            ? "https://api.dingdangcode.cn"
            : "https://api.dingdangcode.com",
};

// api微服务区分标识
export const apiFragment = {
    ddcEdu: "ddc-edu3",
    ddcPort: "ddc-port",
    ddcBase: "ddc-base",
    ddcCustomer: "ddc-customer",
    ddcHadoop: "ddc-hadoop",
};
