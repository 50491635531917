import bindAll from "lodash.bindall";
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import VM from "scratch-vm-dingdangcode";
import Box from "../components/box/box.jsx";
import greenFlag from "../components/green-flag/icon--green-flag.svg";

class GreenFlagOverlay extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["handleClick"]);
    }

    handleClick() {
        console.log("开始");
        this.props.vm.start();
        this.props.vm.greenFlag();
    }

    render() {
        return (
            <Box className={this.props.wrapperClass} onClick={this.handleClick}>
                <div className={this.props.className}>
                    <img draggable={false} src={greenFlag} />
                </div>
            </Box>
        );
    }
}

GreenFlagOverlay.propTypes = {
    className: PropTypes.string,
    vm: PropTypes.instanceOf(VM),
    wrapperClass: PropTypes.string,
};

const mapStateToProps = (state) => ({
    vm: state.scratchGui.vm,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GreenFlagOverlay);
