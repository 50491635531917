import messages from "./tag-messages.js";
export default [
    { tag: "animals", intlLabel: messages.animals },
    { tag: "effects", intlLabel: messages.effects },
    { tag: "loops", intlLabel: messages.loops },
    { tag: "notes", intlLabel: messages.notes },
    { tag: "percussion", intlLabel: messages.percussion },
    { tag: "space", intlLabel: messages.space },
    { tag: "sports", intlLabel: messages.sports },
    { tag: "voice", intlLabel: messages.voice },
    { tag: "wacky", intlLabel: messages.wacky },
    { tag: "dingdangcode", intlLabel: messages.dingdangcode },
];
