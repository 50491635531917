/* eslint-disable */
const ACTIVATE_TAB = "scratch-gui/navigation/ACTIVATE_TAB";
const RESET_PROJECT = "scratch-gui/navigation/RESET_PROJECT";

// Constants use numbers to make it easier to work with react-tabs
const BLOCKS_TAB_INDEX = 0;
const COSTUMES_TAB_INDEX = 1;
const SOUNDS_TAB_INDEX = 2;

const initialState = {
    activeTabIndex: BLOCKS_TAB_INDEX,
    isResetProject: false,
};

const reducer = function (state, action) {
    if (typeof state === "undefined") state = initialState;
    switch (action.type) {
        case ACTIVATE_TAB:
            return Object.assign({}, state, {
                activeTabIndex: action.activeTabIndex,
            });
        case RESET_PROJECT:
            return Object.assign({}, state, {
                isResetProject: !!action.isResetProject,
            });
        default:
            return state;
    }
};

const activateTab = function (tab) {
    return {
        type: ACTIVATE_TAB,
        activeTabIndex: tab,
    };
};

const resetProject = function (isResetProject) {
    return {
        type: RESET_PROJECT,
        isResetProject,
    };
};

export {
    reducer as default,
    initialState as editorTabInitialState,
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX,
    resetProject,
};
